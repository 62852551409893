import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

function ThreeStarsDisplay({ stars }) {
    if (!stars) return null;

    const getStarIcons = (count) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {[...Array(count)].map((_, i) => <StarIcon key={i} style={{ color: 'white' }} />)}
        </div>
    );

    return (
        <Grid container spacing={0} justifyContent="center">
            {stars.map((star, index) => (
                <Grid item key={index} xs={12} sm={4}>
                    <Box variant="outlined" sx={{ paddingX: 2, paddingY: 1.5, marginY: .7, marginX: 1, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginY: 'auto' }}>
                                <Typography sx={{ color: 'grey.500' }}>{index == 0 ? '1st' : index == 1 ? '2nd' : index == 2 ? '3rd' : ''}</Typography>
                                <StarIcon style={{ marginTop: 1, color: 'gold' }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box>
                                    <Typography sx={{ color: 'white', marginLeft: 3.5, fontSize: '20px', fontWeight: 450 }}>
                                        {star.playerName}<Typography component="span" sx={{ color: 'grey.500' }}>
                                        &#8239;&#8239;&#8239;- {star.performanceScore.toFixed(1)}
                                        </Typography>
                                    </Typography>
                                </Box>
                                {star.position != 'goalie' && (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: 6, marginTop: .7 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {star.goals}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                G
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {star.assists}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                A
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {star.pim}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                PIM
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {parseFloat(star.off).toFixed(0)}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                OFF
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {parseFloat(star.def).toFixed(0)}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                DEF
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {parseFloat(star.team).toFixed(0)}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                TEA
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                {parseInt(star.saves) > 0 && (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: 6, marginTop: .7 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {star.sa}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                SA
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {star.ga}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                GA
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {star.saves}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                SV
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', fontWeight: 500 }}>
                                                {star.savepct}
                                            </Typography>
                                            <Typography sx={{ color: 'grey.500', fontWeight: 500 }}>
                                                SV%
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            ))
            }
        </Grid >
    );
}

export default ThreeStarsDisplay;
