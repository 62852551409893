import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = ({ title, children, isExpandedBool }) => {
    const [isExpanded, setIsExpanded] = useState(isExpandedBool);
    const contentRef = useRef(null);

    const toggleAccordion = () => {
        setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
        setIsExpanded(isExpandedBool);
    }, [isExpandedBool]);

    return (
        <Box
            sx={{
                backgroundColor: '#1f1f1f',
                borderRadius: '8px',
                padding: 2,
                marginBottom: 2,
                overflow: 'hidden',
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    paddingBottom: 1,
                }}
                onClick={toggleAccordion}
            >
                <Typography variant="h6" color="white">
                    {title}
                </Typography>
                <ExpandMoreIcon
                    sx={{
                        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease-in-out',
                        color: 'white',
                    }}
                />
            </Box>

            {/* Content Section */}
            <Box
                ref={contentRef}
                sx={{
                    maxHeight: isExpanded ? `${contentRef.current?.scrollHeight + 200}px` : '0px',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease-in-out',
                }}
            >
                <Box sx={{ marginTop: 2 }}>{children}</Box>
            </Box>
        </Box>
    );
};

export default CustomAccordion;
