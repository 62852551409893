import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider, Link } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
import { FaDiscord } from 'react-icons/fa';
import GameCarousel from '../GameCarousel';
import { CircularProgress } from '@mui/material';

function NGHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [gameData, setGameData] = useState();
    const [gamesLoading, setGamesLoading] = useState(true);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.title = `Chel stats | NGHL`;
    }, []);

    useEffect(() => {
        const fetchGameData = async () => {
            setGamesLoading(true)
            try {
                const response = await fetch(`/api/gamedata/NGHL`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                setGameData(data)
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
            } finally {
                setGamesLoading(false)

            }
        };

        fetchGameData();
    }, []);



    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/NGHL_Logo.png`).default;

    return (
        <Container>
            <Box
                sx={{
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {gamesLoading ? (
                    <CircularProgress color="primary" />
                ) : gameData ? (
                    <GameCarousel gamesData={gameData} league={'NGHL'} />
                ) : (
                    <Typography color="white">No games available</Typography>
                )}
            </Box>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="h6" color="white" fontWeight="800" gutterBottom>
                        Welcome to the NGHL!
                    </Typography>
                </Box>
                <Typography variant="subtitle1" color="white" fontWeight="300" gutterBottom>
                    Join our Discord server!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    href="https://discord.gg/cDgqc3nJD3"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        marginTop: 1,
                        backgroundColor: '#5865F2', // Discord's branding color
                        '&:hover': {
                            backgroundColor: '#4e5cc8' // Slightly darker shade for hover effect
                        }
                    }}
                >
                    <FaDiscord style={{ marginRight: '10px', width: '25px', height: '25px' }} />
                    Join Discord
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: .2, marginTop: 1 }}>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for NGHL!
                </Typography>
                <Button variant="contained" color="primary" href="/NGHL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/NGHL/standings-playoffs" sx={{ marginLeft: windowWidth < 600 ? 1 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 0 : windowWidth < 900 ? 0 : 0 }}>
                    Standings Playoffs
                </Button>
                <Button variant="contained" color="primary" href="/NGHL/skater-leaderboard"sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/NGHL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/skater-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Skater Leaderboard Playoffs
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/goalie-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 0 : 0, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 1 : 1 }}>
                    Goalie Leaderboard Playoffs
                </Button> 
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: 2, marginTop: 2 }}>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph sx={{ marginLeft: 2 }}>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'belle-haven-bucks'} text={'Belle Haven Bucks'} league={'NGHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'the-crimson-armada'} text={'The Crimson Armada'} league={'NGHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'saint-john-frost'} text={'Saint John Frost'} league={'NGHL'} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'tokyo-electric-hounds'} text={'Tokyo Electric Hounds'} league={'NGHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'vancouver-grizzlies'} text={'Vancouver Grizzlies'} league={'NGHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'welland-rivermen'} text={'Welland Rivermen'} league={'NGHL'} />
                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default NGHL_Home;