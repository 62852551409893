import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Paper,
    Stack,
    Typography,
    Divider,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Card
} from '@mui/material';

function PlayerRow({ player, stat, category }) {
    return (
        <Grid container alignItems="center" sx={{ width: '100%', paddingY: 0.5 }}>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', flexGrow: 1, color: 'white' }}>
                    {player.Username}
                </Typography>
            </Grid>
            <Grid item xs={3} sx={{ width: 'fit-content', textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>{category}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ width: 'fit-content', textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>{stat}</Typography>
            </Grid>
        </Grid>
    );
}

function ClubLeaders({ clubData }) {
    const { topScorers, topPlaymakers, topDefenders } = clubData.topPlayers;

    const categories = [
        { label: 'Shooting', label2: 'Top Shooters', key: 'SHT', stat: 'Goals', players: topScorers },
        { label: 'Passing', label2: 'Top Passers', key: 'PSS', stat: 'Assists', players: topPlaymakers },
        { label: 'Handeye', label2: 'Top Handeye', key: 'HND', stat: 'Dekes', players: topPlaymakers },
        { label: 'Defending', label2: 'Top Defenders', key: 'DEF', stat: 'Interceptions', players: topDefenders },
        { label: 'Checking', label2: 'Top Hitters', key: 'PHS', stat: 'Hits', players: topDefenders },
    ];

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    const handleCategoryChange = (event) => {
        const newCategory = categories.find(category => category.label === event.target.value);
        setSelectedCategory(newCategory);
    };

    const StatHeader = ({ stat }) => (
        <Grid container spacing={1} paddingY={1}>
            <Grid item xs={6}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>Player</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>OVR</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>{stat}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <Box spacing={2} sx={{ width: '100%', marginX: 'auto', marginTop: 4 }}>
            {/* Dropdown for category selection */}
            <div style={{ display: 'flex', flexDirection:'row', margin: '10px', marginBottom: 0, alignItems: 'center', justifyContent: 'space-between'}}>

                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>Team leaders</Typography>

                <FormControl variant="standard" size="medium" sx={{'& .MuiSelect-icon': { color: 'white' } }}>

                    <Select
                        labelId="category-label"
                        label="Category" // This will associate the label with the Select
                        value={selectedCategory.label}
                        onChange={handleCategoryChange}
                        sx={{
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0)',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: 'white' }, // Outline color when not focused
                                '&:hover fieldset': { borderColor: 'white' }, // Outline color on hover
                                '&.Mui-focused fieldset': { borderColor: 'white' }, // Outline color when focused
                            },
                            '& .MuiInputLabel-root': { color: 'gray' },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'gray' }
                        }}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category.key} value={category.label}>
                                {category.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {/* Display selected category */}
            <Card
                sx={{
                    backgroundColor: '#171717',
                    padding: 2,
                    boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)',
                    borderRadius: 5,
                    marginY: 1,
                }}
            >
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', color: 'white' }}>
                    {selectedCategory.label2}
                </Typography>
                <Divider light />
                <StatHeader stat={selectedCategory.stat} />
                {selectedCategory.players
                    .filter(
                        (player) =>
                            player.overallRating &&
                            player.overallRating['Category Ratings'] &&
                            player.overallRating['Category Ratings'][selectedCategory.key] !== undefined
                    )
                    .sort(
                        (a, b) =>
                            b.overallRating['Category Ratings'][selectedCategory.key] -
                            a.overallRating['Category Ratings'][selectedCategory.key]
                    ) // Sort by selected category rating descending
                    .map((player) => (
                        <PlayerRow
                            key={player.Username}
                            player={player}
                            stat={player[selectedCategory.stat]}
                            category={Math.round(player.overallRating['Category Ratings'][selectedCategory.key])}
                        />
                    ))}
            </Card>
        </Box>
    );
}

export default ClubLeaders;
