// src/components/PlayerStatsLeaderboard.js

import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    TablePagination,
    TextField,
    Button,
    TableSortLabel,
    Select,
    MenuItem,
    FormControl,
    InputAdornment,
    IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowUpward, ArrowDownward, Search } from '@mui/icons-material';
import axios from 'axios'; // Ensure axios is installed: npm install axios
import { Edit } from '@mui/icons-material';
import { PropagateLoader } from 'react-spinners';
import ClearIcon from '@mui/icons-material/Clear';
import { useLoading } from '../Contexts/LoadingContext';

// Column definitions for Player Stats

const formatNumber = (num) => {
    if (typeof num !== 'number') return num;
    return num.toLocaleString();
};

const positionMapping = {
    rightWing: 'RW',
    leftWing: 'LW',
    center: 'C',
    defense: 'D',
    goalie: 'G',
};

const formatPosition = (position) => positionMapping[position] || position;

const playerStatsColumns = [
    { id: 'rank', label: 'Rank', sortable: true },
    { id: 'username', label: 'Player', sortable: true },
    { id: 'position', label: 'Pos', sortable: true },
    { id: 'overall', label: 'OVR', sortable: true },
    { id: 'winPercentage', label: 'W%', sortable: true },
];

const goalieStatsColumns = [
    { id: 'rank', label: 'Rank', sortable: true },
    { id: 'username', label: 'Player', sortable: true },
    { id: 'position', label: 'Pos', sortable: true },
    { id: 'overall', label: 'OVR', sortable: true },
    { id: 'winPercentage', label: 'W%', sortable: true },
];

// Sort + Assign rank based on (overall DESC, then winPercentage DESC)
const sortAndAssignRank = (playerStats) => {
    // Clone to avoid mutating the original array
    const sorted = [...playerStats];

    // 1. Sort
    sorted.sort((a, b) => {
        const aOverall = a.overall ?? 0;
        const bOverall = b.overall ?? 0;

        // Compare overall first
        if (bOverall !== aOverall) {
            return bOverall - aOverall; // higher overall first
        }

        // If overall is the same, compare winPercentage
        const aWin = a.winPercentage ?? 0;
        const bWin = b.winPercentage ?? 0;
        return bWin - aWin; // higher win% first
    });

    // 2. Assign ranks (ties get the same rank)
    let lastOverall = null;
    let lastWinPct = null;
    let lastRank = 0;

    for (let i = 0; i < sorted.length; i++) {
        const p = sorted[i];
        if (!p) continue; // safety check

        if (p.overall === lastOverall && p.winPercentage === lastWinPct) {
            // Same (overall, winPct) as previous => same rank
            sorted[i].rank = lastRank;
        } else {
            // New rank
            sorted[i].rank = i + 1;
            lastOverall = p.overall;
            lastWinPct = p.winPercentage;
            lastRank = i + 1;
        }
    }

    return sorted;
};



function PlayerStatsLeaderboard() {
    const navigate = useNavigate();

    // State

    const [isSorting, setIsSorting] = useState(false);

    const [playerStats, setPlayerStats] = useState([]);
    const [originalStats, setOriginalStats] = useState([]);
    const [sortConfig, setSortConfig] = useState({ field: 'overall', direction: 'desc' });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPageNumber, setCurrentPageNumber] = useState(1); // For backend pagination
    const [totalPages, setTotalPages] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [searchUsername, setSearchUsername] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const { loading, setLoading } = useLoading();
    const [searchError, setSearchError] = useState(null);
    const [position, setSelectedPosition] = useState('Forwards')
    const [mode, setSelectedMode] = useState('6s')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // Fetch Player Stats
    useEffect(() => {
        const fetchPlayerStats = async () => {
            if (isSorting) return; // Skip fetching default stats during sorting

            setLoading(true);
            try {
                const response = await axios.get('/api/player-stats-rankings', {
                    params: {
                        page: currentPageNumber,
                        limit: rowsPerPage,
                        sortField: 'overall', // Default sort field
                        sortOrder: 'desc',    // Default sort order
                        position: position,
                        type: mode
                    },
                });

                // 1) Determine the skip (how many players were before this page).
                const skip = (currentPageNumber - 1) * rowsPerPage;

                // 2) Sort the data by (overall DESC, then Win % DESC).
                //    Clone the array to avoid mutating response.data.data directly.
                const sortedData = [...response.data.data].sort((a, b) => {
                    const aOverall = a.overall ?? 0;
                    const bOverall = b.overall ?? 0;

                    // Compare overall first (descending)
                    if (bOverall !== aOverall) {
                        return bOverall - aOverall;
                    }

                    // If overall is the same, compare Win % (descending).
                    // Adjust the key if you store "Win %" differently in your data.
                    const aWin = a["winPercentage"] ?? 0;
                    const bWin = b["winPercentage"] ?? 0;
                    return bWin - aWin;
                });

                // 3) Assign rank, offset by skip so page 2 continues ranks from page 1.
                const rankedData = sortedData.map((player, index) => {
                    return {
                        ...player,
                        rank: skip + index + 1, // rank continues across pages
                    };
                });

                setPlayerStats(rankedData);

                setOriginalStats(rankedData);
                setTotalPages(response.data.pagination.totalPages);
                setTotalEntries(response.data.pagination.totalEntries);
            } catch (err) {
                console.error('Error fetching player stats:', err);
                setError('Failed to fetch player stats.');
            } finally {
                setLoading(false);
            }
        };

        fetchPlayerStats();
    }, [currentPageNumber, rowsPerPage, position, isSorting, mode]);

    // Handle Pagination
    const handleChangePage = (event, newPage) => {
        setCurrentPageNumber(newPage + 1); // Backend is 1-based
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        setRowsPerPage(newLimit);
        setCurrentPageNumber(1);
        setPage(0);
    };

    // Handle Row Click
    const handleGoToPlayer = (username, team) => {
        navigate(`/players/?username=${encodeURIComponent(username)}&console=common-gen5&teamname=${encodeURIComponent(team)}`);
    };

    const tableRef = useRef(null);

    const handleSearch = async () => {
        setIsSorting(false)
        if (!searchUsername.trim()) {
            setSearchError('Please enter a username to search.');
            return;
        }

        setSearchError(null);

        try {
            // Fetch search result metadata (like rank) from the backend
            const response = await axios.get('/api/player-stats-rankings/search', {
                params: { username: searchUsername.trim(), position, type: mode },
            });

            const { rank } = response.data.data;

            // Calculate the page where the player appears
            const playerPage = Math.floor((rank - 1) / rowsPerPage); // Zero-based pagination

            // Set the current page to where the player appears
            setPage(playerPage);
            setCurrentPageNumber(playerPage + 1); // Backend is 1-based
            setSearchResult(response.data.data)
            // Optionally scroll to the table
        } catch (err) {
            console.error('Error during search:', err);
            setSearchError('Player not found.');
        } finally {
            tableRef.current?.scrollIntoView({ behavior: 'smooth' });

        }
    };

    useEffect(() => {
        if (!searchResult?.player) return;

        // Check if the searched player's username exists in the current playerStats
        const playerExists = playerStats.some(
            (player) => player.latestEntry.username === searchResult.player.username
        );

        if (!playerExists) return;

        const timer = setTimeout(() => {
            tableRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100); // Add a small delay to ensure rendering is complete before scrolling.

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, [searchResult, playerStats]);

    const hasDataChanged = useMemo(() => {
        return JSON.stringify(playerStats) !== JSON.stringify(originalStats);
    }, [playerStats, originalStats]);

    const getStatsColumns = (position) => {
        if (position === 'Goalie') return goalieStatsColumns;
        return playerStatsColumns;
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><PropagateLoader color="#3498db" /></div>
        )
    }

    return (
        <Box sx={{ p: windowWidth < 600 ? 1 : windowWidth < 900 ? 8 : 8 }}>

            {/* Search Bar */}


            <Box sx={{ display: 'flex', ml: 1, mb: 2, mt: 4 }}>
                <FormControl variant="standard" size="medium" sx={{ '& .MuiSelect-icon': { color: 'white' } }}>

                    <Select
                        labelId="category-label"
                        label="Filter by Position"
                        value={position}
                        onChange={(e) => setSelectedPosition(e.target.value)}
                        sx={{
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0)',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: 'white' }, // Outline color when not focused
                                '&:hover fieldset': { borderColor: 'white' }, // Outline color on hover
                                '&.Mui-focused fieldset': { borderColor: 'white' }, // Outline color when focused
                            },
                            '& .MuiInputLabel-root': { color: 'gray' },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'gray' }
                        }}
                    >
                        <MenuItem key={"Forwards"} value={"Forwards"}>Forwards</MenuItem>
                        <MenuItem key={"Defense"} value={"Defense"}>Defense</MenuItem>
                        <MenuItem key={"Goalie"} value={"Goalie"}>Goalies</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="standard" size="medium" sx={{ marginLeft: 2, '& .MuiSelect-icon': { color: 'white' } }}>

                    <Select
                        labelId="category-label"
                        label="Filter by Mode"
                        value={mode}
                        onChange={(e) => setSelectedMode(e.target.value)}
                        sx={{
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0)',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: 'white' }, // Outline color when not focused
                                '&:hover fieldset': { borderColor: 'white' }, // Outline color on hover
                                '&.Mui-focused fieldset': { borderColor: 'white' }, // Outline color when focused
                            },
                            '& .MuiInputLabel-root': { color: 'gray' },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'gray' }
                        }}
                    >
                        <MenuItem key={"3s"} value={"3s"}>3s</MenuItem>
                        <MenuItem key={"6s"} value={"6s"}>6s</MenuItem>
                    </Select>
                </FormControl>

            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                    label="Search Username"
                    variant="outlined"
                    size="small"
                    value={searchUsername}
                    onChange={(e) => setSearchUsername(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                    sx={{
                        mr: 1, flex: 1, color: 'white', input: {
                            color: 'white',
                            '&:-webkit-autofill': {
                                WebkitTextFillColor: 'white !important',
                                transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
                                WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                            }
                        },
                        label: { color: error ? 'red' : 'gray' }, // Change label color to red if error exists
                        '& .MuiOutlinedInput-root': {
                            paddingRight: 0,
                            '& fieldset': { borderColor: error ? 'red' : 'white' }, // Red border on error
                            '&:hover fieldset': { borderColor: error ? 'red' : 'white' },
                            '&.Mui-focused fieldset': { borderColor: error ? 'red' : 'white' },
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: error ? 'red' : 'white' }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setSearchUsername('')} sx={{ color: 'white' }}>
                                    {searchUsername ? <ClearIcon /> : null}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    startIcon={<Search />}
                >
                    Search
                </Button>
            </Box>

            {searchError && (
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    <Typography variant="body1" color="error">
                        {searchError}
                    </Typography>
                </Box>
            )}

            {/* Table */}
            <TableContainer component={Paper} sx={{
                overflow: 'auto', backgroundColor: '#121212',
                '&::-webkit-scrollbar': { width: '0px' },
                /* Track */
                '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 5px #121212', borderRadius: '10px' },
                /* Handle */
                '&::-webkit-scrollbar-thumb': { background: '#1976d2', borderRadius: '10px' },
                /* Handle on hover */
                '&::-webkit-scrollbar-thumb:hover': { background: '#303030' }
            }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ borderBottom: 'none', color: '#474747' }}>
                            {getStatsColumns(position).map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={sortConfig.field === column.id ? sortConfig.direction : false}
                                    sx={{
                                        borderBottom: 'none',
                                        padding: '6px',
                                        fontSize: '13px',
                                        color: '#6B6B6B',
                                        '&:hover': { color: 'white' },
                                        position: column.id === 'username' ? 'sticky' : undefined,
                                        left: column.id === 'username' ? 0 : undefined,
                                        zIndex: column.id === 'username' ? 999 : undefined,
                                        whiteSpace: 'nowrap'
                                    }}
                                >

                                    {column.label}

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody ref={tableRef}>
                        {playerStats.length > 0 ? (
                            playerStats.map((player, index) => {
                                const rank = player.rank;
                                const type = player.playerType;
                                player = player.latestEntry || player;

                                const isSearchedPlayer = player.username === searchResult?.username;
                                const defaultFontSize = searchResult ? '12px' : '14px'; // Larger font size when no player is searched
                                const searchedFontSize = '16px'; // Font size for the searched player
                                return (
                                    <TableRow
                                        key={player.username}
                                        hover
                                        data-rank={rank}
                                        onClick={() => handleGoToPlayer(player.username, player.team)}
                                        sx={{
                                            cursor: 'pointer',
                                            borderBottom: 'none',
                                        }}
                                    >
                                        {getStatsColumns(position).map((column) => {
                                            let value;
                                            if (column.id === 'rank') {
                                                value = rank;
                                            } else if (column.id === 'playerType') {
                                                value = type;
                                            } else {
                                                value = player[column.id];
                                            }

                                            const displayValue =
                                                column.id === 'position'
                                                    ? formatPosition(value)
                                                    : column.formatter
                                                        ? column.formatter(value)
                                                        : value;

                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    sx={{
                                                        paddingLeft: '8px',
                                                        borderBottom: 'none',
                                                        position: column.id === 'username' ? 'sticky' : 'inherit',
                                                        left: column.id === 'username' ? 0 : 'inherit',
                                                        zIndex: column.id === 'username' ? 998 : 'inherit',
                                                        color: 'white',
                                                        backgroundColor: '#121212',
                                                        fontWeight: isSearchedPlayer ? 'bold' : 'normal',
                                                        fontSize: isSearchedPlayer ? searchedFontSize : defaultFontSize, // Adjust font size dynamically
                                                    }}
                                                >
                                                    {displayValue}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={getStatsColumns(position).length} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>

                </Table>
            </TableContainer>


            {/* Pagination */}
            <TablePagination
                component="div"
                count={totalEntries}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[15, 30, 50]}
                labelRowsPerPage="Rows per page"
                sx={{
                    color: 'white',

                    '& .MuiTablePagination-selectIcon': {
                        color: 'white', // Dropdown arrow color
                    },
                    '& .MuiTablePagination-select': {
                        color: 'white', // Selected text color
                    },
                    '& .MuiTablePagination-selectLabel': {
                        color: 'white', // "Rows per page" label color
                    },
                    '& .MuiMenuItem-root': {
                        color: 'black', // Dropdown items text color
                    },
                }}
                SelectProps={{
                    sx: {
                        '& .MuiSelect-icon': {
                            color: 'white', // Dropdown arrow color
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white', // Border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white', // Border color on hover
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white', // Border color when focused
                        },
                        '& .MuiSelect-select': {
                            color: 'white', // Selected text color
                        },
                    },
                    IconComponent: (props) => <ArrowDownward {...props} />, // Custom dropdown arrow icon
                }}
            />
        </Box>
    );
}

export default PlayerStatsLeaderboard;
