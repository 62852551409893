import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import SharedProgressBar from './SharedProgressBar';
function GameStats({ gameData }) {
    if (!gameData) return null;



    return (
        <Box sx={{ marginTop: 8 }}>
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1_deserved_score}
                team2Stats={gameData.game_stats.team2_deserved_score}
                label="Deserve to Win %"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats['team1_Avg_RP']}
                team2Stats={gameData.game_stats['team2_Avg_RP']}
                label="Avg RP"
                sx={{marginBottom: 0}}
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.skshots}
                team2Stats={gameData.game_stats.team2.skshots}
                label="Shots on Goal"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.skfow}
                team2Stats={gameData.game_stats.team2.skfow}
                label="Faceoff Wins"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.skpossession}
                team2Stats={gameData.game_stats.team2.skpossession}
                label="Possession"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1_toa}
                team2Stats={gameData.game_stats.team2_toa}
                label="Time on Attack"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.skpasspct}
                team2Stats={gameData.game_stats.team2.skpasspct}
                label="Pass %"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.skhits}
                team2Stats={gameData.game_stats.team2.skhits}
                label="Hits"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.skinterceptions}
                team2Stats={gameData.game_stats.team2.skinterceptions}
                label="Interceptions"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.sktakeaways}
                team2Stats={gameData.game_stats.team2.sktakeaways}
                label="Takeaways"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.skpim}
                team2Stats={gameData.game_stats.team2.skpim}
                label="PIM"
                sx={{marginBottom: 0}}
            />
        </Box>
    );
}

export default GameStats;
