import SearchAndDropdownCombined from "./SearchAndDropdownCombined";
import { Container, Button } from '@mui/material';

function EASHLSearch() {
    return (
        <Container>
            <SearchAndDropdownCombined />
        </Container>
    )
}

export default EASHLSearch;