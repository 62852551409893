import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Table, TableBody, TableCell, TableContainer, TableRow, Select, Paper, Typography, MenuItem, FormControl, InputLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AdvancedStats({ rawGameStats, teamID1, teamID2, clubData1, clubData2 }) {
    const [selectedTeam, setSelectedTeam] = useState(teamID1); // Default to teamID1
    const [gameStats, setGameStats] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [replacementLevel, setReplacementLevel] = useState();

    const [imageUrls1, setImageUrls1] = useState([]);
    const [currentImage1, setCurrentImage1] = useState(imageUrls1[0]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [combinedClubsData, setCombinedClubsData] = useState(null);

    // Filter players based on the selected team
    const teamPlayers = selectedTeam !== null && gameStats ? Object.values(gameStats.players[selectedTeam]) : [];
    // Select the player based on the selected player ID
    const currentPlayer = teamPlayers.find(player => player.playername === selectedPlayer);

    useEffect(() => {
        if (clubData1 && clubData2) {
            setCombinedClubsData({
                [teamID1]: clubData1,
                [teamID2]: clubData2
            });
        }
    }, [clubData1, clubData2, teamID1, teamID2]);

    useEffect(() => {
        if (rawGameStats) {
            const updatedStats = addNewStatsToPlayers(rawGameStats);
            setGameStats(updatedStats);
            if (updatedStats && !selectedPlayer) {
                setDefaultPlayer(updatedStats.players[selectedTeam || teamID1]);
            }
        }
    }, [rawGameStats, teamID1, setGameStats, gameStats]);

    useEffect(() => {
        if (combinedClubsData && selectedTeam) {
            const crestID = combinedClubsData[selectedTeam].teamData.clubInfo.customKit.crestAssetId;
            let imageUrls = [];
            if (crestID) {
                imageUrls = [
                    combinedClubsData[selectedTeam].teamData.clubInfo.customKit.useBaseAsset === "1" ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png` : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
                    'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
                ];
            } else {
                imageUrls = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
            }

            setImageUrls1(imageUrls);
            setCurrentImage1(imageUrls[0]);
        }
    }, [combinedClubsData, selectedTeam]);

    const handleTeamChange = (event) => {
        const newTeam = event.target.value;
        setSelectedTeam(newTeam);
        setSelectedPlayer(null); // Reset player selection when switching teams

        if (gameStats && gameStats.players[newTeam]) {
            setDefaultPlayer(gameStats.players[newTeam]);
        }
    };

    const handlePlayerChange = (event) => {
        console.log(event.target.value)
        setSelectedPlayer(event.target.value);
    };

    // Function to find the player with the highest stat (using WAR for skaters, GWAR for goalies)
    const setDefaultPlayer = (players) => {
        if (!players || selectedPlayer) return; // Don't override if a player is already selected

        let highestStatPlayer = null;
        let highestStat = 0;

        Object.values(players).forEach(player => {
            const stat = player.position === 'goalie' ? player.GWAR : player.WAR;
            if (stat > highestStat) {
                highestStat = stat;
                highestStatPlayer = player;
            }
        });

        if (highestStatPlayer) {
            setSelectedPlayer(highestStatPlayer.playername);
        }
    };

    const calculateXGA = (player, leagueAverages) => {
        const { glshots, glbrkshots, glpenshots } = player;
        const { avgSavePCT, avgPenShotSavePCT, avgbrkwaySavePCT } = leagueAverages;

        const regularShots = parseInt(glshots) - (parseInt(glbrkshots) + parseInt(glpenshots));

        // Expected goals against by shot type
        const xGARegular = regularShots * (1 - (avgSavePCT / 100));
        const xGABreakaway = parseInt(glbrkshots) * (1 - (avgbrkwaySavePCT / 100));
        const xGAPenalty = parseInt(glpenshots) * (1 - (avgPenShotSavePCT / 100));

        return xGARegular + xGABreakaway + xGAPenalty;
    };

    function addNewStatsToPlayers(gameStats) {
        const updatedGameStats = { ...gameStats }; // Create a copy to avoid mutating the original object
        const bothClubsData = combinedClubsData; // Create a copy to avoid mutating the original object

        let Goals = 0
        let Assists = 0
        let Shots = 0
        let PassPct = 0
        let ShotOnNetPct = 0
        let PIM = 0
        let PenaltiesDrawn = 0
        let BlockedShots = 0
        let Interceptions = 0
        let Hits = 0
        let Giveaways = 0
        let Takeaways = 0
        let Possession = 0
        let playerCount = 0
        let goalieCount = 0
        let savePCT = 0
        let saves = 0
        let penSavePCT = 0
        let brkwaySavePCT = 0
        for (const teamID in bothClubsData) {
            const players = bothClubsData[teamID].memberData;
            for (const playerID in players) {
                const player = players[playerID];

                if (['C', 'LW', 'RW', 'D'].includes(player.Position)) {
                    playerCount += 1
                    Goals += parseFloat(player['Goals per game'])
                    Assists += parseFloat(player['Assists per game'])
                    Shots += parseFloat(player['Shots per game'])

                    Possession += parseFloat(player['Possession per min'])
                    PassPct += parseFloat(player['Pass %'])
                    ShotOnNetPct += parseFloat(player['Shots on net %'])
                    PIM += parseFloat(player['PIMS per game'])
                    PenaltiesDrawn += parseFloat(player['Penalties drawn per game'])
                    BlockedShots += parseFloat(player['Blocked shots per game'])
                    Interceptions += parseFloat(player['Interceptions per game'])
                    Hits += parseFloat(player['Hits per game'])
                    Giveaways += parseFloat(player['Giveaways per game'])
                    Takeaways += parseFloat(player['Takeaways per game'])
                }
                if (parseInt(player['Goalie games played']) > 0) {
                    goalieCount += 1
                    savePCT += parseFloat(player['Save %'])
                    penSavePCT += parseFloat(player['PS %'])
                    brkwaySavePCT += (parseFloat(player['Breakaway save %']) * 100)
                    saves += (parseFloat(player['Saves per game']))

                }
            }
        }

        const avgGoals = Goals / playerCount
        const avgAssists = Assists / playerCount
        const avgShots = Shots / playerCount
        const avgShotPCT = Goals / Shots
        const avgPassPct = PassPct / playerCount
        const avgShotOnNetPct = ShotOnNetPct / playerCount
        const avgShotAttempts = avgShots / (avgShotOnNetPct / 100)
        const avgPIM = PIM / playerCount
        const avgPenaltiesDrawn = PenaltiesDrawn / playerCount
        const avgBS = BlockedShots / playerCount
        const avgInts = Interceptions / playerCount
        const avgHits = Hits / playerCount
        const avgGives = Giveaways / playerCount
        const avgTakes = Takeaways / playerCount
        const avgPossession = Possession / playerCount
        const avgSavePCT = savePCT / goalieCount
        const avgPenShotSavePCT = penSavePCT / goalieCount
        const avgbrkwaySavePCT = brkwaySavePCT / goalieCount
        const avgSaves = saves / goalieCount

        // Iterate through each team's players
        for (const teamID in updatedGameStats.players) {
            const players = updatedGameStats.players[teamID];

            for (const playerID in players) {
                const player = players[playerID];

                if (['center', 'leftWing', 'rightWing', 'defenseMen'].includes(player.position)) {

                    const replacementLevel = {
                        goals: avgGoals,
                        assists: avgAssists, // Average goals per game from all players
                        shots: avgShots, // Average shots per game from all players
                        shotAttempts: avgShotAttempts,
                        shotPct: avgGoals / avgShots, // Average shooting percentage
                        passPct: avgPassPct, // Average passing percentage
                        shotOnNetPct: avgShotOnNetPct, // Average shots on net percentage
                        pim: avgPIM, // Average penalty minutes
                        penaltiesDrawn: avgPenaltiesDrawn, // Average penalties drawn
                        bs: avgBS, // Average penalties drawn
                        ints: avgInts,// Average penalties drawn
                        hits: avgHits, // Average penalties drawn
                        takeaways: avgTakes,// Average penalties drawn
                        giveaways: avgGives, // Average penalties drawn
                        penShotPct: avgPenShotSavePCT,
                        brkwaySavePct: avgbrkwaySavePCT,
                        savePct: avgSavePCT
                    };

                    setReplacementLevel(replacementLevel)

                    player.GAR = (player.skshots > 0 && replacementLevel.shots > 0)
                        ? ((parseInt(player.skgoals)) - (replacementLevel.goals))
                        : 0;

                    const maxPossibleWAR = 1; // Increase based on empirical data or game context

                    const ratingImpact = (((parseFloat(player.ratingOffense) + (parseFloat(player.ratingDefense)) + parseFloat(player.ratingTeamplay)) / 3) / 195) // Further increase impact of ratings
                    const GARImpact = Math.max(player.GAR * .12, 0)
                    const passingImpact = Math.max((parseFloat(player.skpasspct) - replacementLevel.passPct) / 160, 0)
                    const shotPCTImpact = Math.max((parseFloat(player.skshotpct) - (replacementLevel.shotPct * 100)) / 450, 0);
                    const shotAttemptsImpact = Math.max((parseFloat(player.skshotattempts) - replacementLevel.shotAttempts) / 300, 0); // Scale appropriately
                    const shotsOnImpact = Math.max((parseFloat(player.skshotonnetpct) - replacementLevel.shotOnNetPct) / 650, 0)
                    const assistImpact = Math.max((parseFloat(player.skassists) - replacementLevel.assists) / 7.55, 0)
                    const BSImpact = Math.max((parseFloat(player.skbs) - replacementLevel.bs) / 13, 0)
                    const intsImpact = Math.max((parseFloat(player.skinterceptions) - replacementLevel.ints) / 32, 0)
                    const hitsImpact = Math.max((parseFloat(player.skhits) - replacementLevel.hits) / 65, 0)
                    const takesImpact = Math.max((parseFloat(player.sktakeaways) - replacementLevel.takeaways) / 32, 0)
                    const fightingPIM = Math.floor(player.skpim / 5) * 5; // Approximate fighting PIMs

                    const nonFightingPIMWAR = player.skpim - fightingPIM;

                    const pimsImpact = Math.max((nonFightingPIMWAR - replacementLevel.pim) / 30, 0)
                    const givesImpact = ((parseFloat(player.skgiveaways) - replacementLevel.giveaways) * parseInt(player.opponentScore)) / 300
                    let playerWAR = (
                        // Main Impact: Ratings and GAR
                        ratingImpact
                        + GARImpact // Quadruple GAR impact

                        // Secondary Impact: Other stats with percentage considerations
                        + passingImpact // Scale percentage stats by 100 for a 1% impact per 1% difference
                        + shotPCTImpact // Shooting percentage, scaled to have more impact than passing
                        + shotsOnImpact // Shot on net percentage, scaled like passing
                        + shotAttemptsImpact

                        // Tertiary Impact: Singular stats
                        + assistImpact // Assists have less impact, scaled down
                        + BSImpact // Blocks
                        + intsImpact // Interceptions
                        + hitsImpact // Hits
                        + takesImpact // Takeaways

                        // Negative Impact: Penalties and Giveaways
                        - pimsImpact // Penalties
                        - givesImpact // Giveaways
                    );

                    player.playerWAR = Math.max(0, playerWAR);
                    let WARPercentage = (player.playerWAR / maxPossibleWAR) * 100;
                    player.WAR = Math.min(100, WARPercentage);

                    player.xG = (parseInt(player.skshots) * avgShotPCT);
                    player.xGPerPossessionSec = player.xG / parseFloat(player.skpossession);
                    player.GAE = (parseFloat(player.skgoals) - player.xG);
                    player.scoringChancesPerMinute = (parseFloat(player.skgoals) + parseFloat(player.skassists) + parseFloat(player.skshotattempts)) / parseFloat(player.toi);
                    const possessionEfficiencyWeight = {
                        scoringImpact: 6, // Weight for goals and assists
                        passingEfficiency: .08, // Weight for pass completion
                        shootingEfficiency: .1  // Weight for shot attempts
                    };

                    player.possessionEfficiency = (
                        // Scoring Impact
                        ((parseFloat(player.skgoals) + parseFloat(player.skassists)) / (parseFloat(player.skpossession) / 60)) * possessionEfficiencyWeight.scoringImpact +

                        // Passing Efficiency
                        (parseFloat(player.skpasses) / (parseFloat(player.skpossession) / 60)) * possessionEfficiencyWeight.passingEfficiency +

                        // Shooting Efficiency
                        (parseFloat(player.skshots) / (parseFloat(player.skpossession) / 60)) * possessionEfficiencyWeight.shootingEfficiency
                    ) / (possessionEfficiencyWeight.scoringImpact + possessionEfficiencyWeight.passingEfficiency + possessionEfficiencyWeight.shootingEfficiency) * 100;

                    player.possessionEfficiency = Math.min(100, Math.max(0, player.possessionEfficiency.toFixed(2))); // Ensure it's between 0 and 100                    player.blockingImpact = parseFloat(player.skbs) / parseFloat(player.toi); // Blocks per minute
                    player.defensiveImpact = (parseFloat(player.sktakeaways) - parseFloat(player.skgiveaways) + parseFloat(player.skinterceptions)) / parseFloat(player.toi);
                    player.netDefensiveImpact = player.defensiveImpact + player.blockingImpact;
                    player.ppImpact = parseFloat(player.skppg) / player.xG; // power play efficiency relative to expected goals

                    const totalOffenseWeight = {
                        goals: 20, // Direct impact on scoring
                        assists: 15, // Playmaking ability
                        shootingPercentage: 1, // Efficiency in scoring from shots
                        xG: 5, // Quality of scoring chances created
                        possessionEfficiency: 1, // Effective use of possession
                        shotAttemptEfficiency: .5, // Efficiency in generating shot attempts
                        ppg: 5 // Power play contribution
                    };

                    let totalOffenseScore =
                        Math.max((parseFloat(player.skgoals) / (avgGoals * Math.max(parseFloat(player.toi) / 60, 0.001))) * totalOffenseWeight.goals, 0) +
                        Math.max((parseFloat(player.skassists) / (avgAssists * Math.max(parseFloat(player.toi) / 60, 0.001))) * totalOffenseWeight.assists, 0) +
                        Math.max((parseFloat(player.skshotpct) / 100) * totalOffenseWeight.shootingPercentage, 0) +
                        Math.max((parseFloat(player.xG) / (Math.max(avgShots * avgShotPCT, 0.001))) * totalOffenseWeight.xG, 0) + // Using xG directly
                        Math.max(parseFloat(player.possessionEfficiency) * totalOffenseWeight.possessionEfficiency, 0) +
                        Math.max((((parseFloat(player.skshotattempts) / Math.max(parseFloat(player.toi) / 60, 0.001)) - (avgShotAttempts / 60)) / Math.max(avgShotAttempts / 60, 0.001)) * totalOffenseWeight.shotAttemptEfficiency, 0) + // Shot attempts per minute vs. average
                        Math.max((parseFloat(player.skppg) / Math.max(parseFloat(player.xG), 0.001)) * totalOffenseWeight.ppg, 0); // Power play efficiency

                    // Normalize to 100, ensuring it doesn't exceed 100
                    player.totalOffense = Math.min(100, totalOffenseScore);
                    player.totalOffenseScore = totalOffenseScore
                    const totalDefenseWeight = {
                        blockedShots: 20,
                        takeaways: 15,
                        giveaways: -6,
                        interceptions: 15,
                        penaltyMinutes: -8
                    };

                    const nonFightingPIM = player.skpim - fightingPIM;
                    const normalizedPIM = (nonFightingPIM / (parseFloat(player.toi) / 60)) * totalDefenseWeight.penaltyMinutes;


                    let totalDefenseScore =
                        (parseFloat(player.skbs) / (parseFloat(player.toi) / 60)) * totalDefenseWeight.blockedShots +
                        (parseFloat(player.sktakeaways) / (parseFloat(player.toi) / 60)) * totalDefenseWeight.takeaways +
                        (parseFloat(player.skgiveaways) / (parseFloat(player.toi) / 60)) * totalDefenseWeight.giveaways +
                        (parseFloat(player.skinterceptions) / (parseFloat(player.toi) / 60)) * totalDefenseWeight.interceptions +
                        (normalizedPIM)

                    // Normalize to 100, ensuring it doesn't go below 0
                    player.totalDefenseScore = totalDefenseScore

                    player.totalDefense = Math.max(0, Math.min(100, totalDefenseScore));
                } else {

                    const replacementLevel = {
                        goals: avgGoals,
                        assists: avgAssists, // Average goals per game from all players
                        shots: avgShots, // Average shots per game from all players
                        shotAttempts: avgShotAttempts,
                        shotPct: avgGoals / avgShots, // Average shooting percentage
                        passPct: avgPassPct, // Average passing percentage
                        shotOnNetPct: avgShotOnNetPct, // Average shots on net percentage
                        pim: avgPIM, // Average penalty minutes
                        penaltiesDrawn: avgPenaltiesDrawn, // Average penalties drawn
                        bs: avgBS, // Average penalties drawn
                        ints: avgInts,// Average penalties drawn
                        hits: avgHits, // Average penalties drawn
                        takeaways: avgTakes,// Average penalties drawn
                        giveaways: avgGives, // Average penalties drawn
                        penShotPct: avgPenShotSavePCT,
                        brkwaySavePct: avgbrkwaySavePCT,
                        savePct: avgSavePCT
                    };

                    setReplacementLevel(replacementLevel)

                    const maxPossibleGWAR = 1;
                    const goalsToWinsConversion = 5; // Example conversion, needs calibration

                    const shotsFaced = parseInt(player.glshots);
                    const goalsAllowed = parseInt(player.glga);
                    const breakawayShotsFaced = parseInt(player.glbrkshots);
                    const breakawaySaves = parseInt(player.glbrksaves);

                    const brkEGA = breakawayShotsFaced * (1 - avgbrkwaySavePCT / 100);
                    const brkGSAA = breakawaySaves - brkEGA;
                    player.brkGSAA = brkGSAA
                    const savePct = parseFloat(player.glsavepct) * 100;
                    const penSavePct = parseFloat(player.glpensavepct) * 100;

                    player.GSAA = (shotsFaced * (1 - (avgSavePCT / 100))) - goalsAllowed;
                    const leagueAverages = { avgSavePCT, avgPenShotSavePCT, avgbrkwaySavePCT }
                    player.EGA = calculateXGA(player, leagueAverages)
                    // Here's a more nuanced GWAR:
                    let playerGWAR = (
                        ((parseFloat(player.ratingOffense) + parseFloat(player.ratingDefense) + parseFloat(player.ratingTeamplay)) / 3) +
                        (player.GSAA / goalsToWinsConversion) +  // From regular shots GSAA contribution
                        (brkGSAA / goalsToWinsConversion) +  // From breakaway GSAA contribution
                        ((parseFloat(player.glsavepct) - avgSavePCT / 100) * shotsFaced) / goalsToWinsConversion +  // Impact of regular save percentage
                        ((parseFloat(player.glbrksavepct) - avgbrkwaySavePCT / 100) * breakawayShotsFaced) / goalsToWinsConversion +
                        ((penSavePct - avgPenShotSavePCT / 100) * parseInt(player.glpenshots)) / goalsToWinsConversion // Impact of breakaway save percentage
                    );
                    player.playerGWAR = playerGWAR
                    // Normalize GWAR for a single game:
                    player.GWAR = Math.max(0.1, playerGWAR);
                    let GWARPercentage = (player.GWAR / maxPossibleGWAR);
                    player.GWAR = Math.min(100, GWARPercentage);

                    const expectedGoalsAgainst = player.EGA; // Assuming this is already calculated

                    const expectedSaves = shotsFaced - expectedGoalsAgainst;
                    player.SAE = parseInt(player.glsaves) - expectedSaves;
                }
                player.huh = "huh"
            }
        }

        return updatedGameStats;
    }

    const positionMapping = {
        "5": "C",
        "4": "LW",
        "3": "RW",
        "2": "LD",
        "1": "RD",
        "0": "G"
    };

    const buildMapping = {
        "0": "GRN",
        "1": "PLY",
        "2": "SNP",
        "3": "PWF",
        "4": "TWF",
        "5": "ENF",
        "6": "DNG",
        "11": "DFD",
        "12": "OFD",
        "14": "TWD",
        "15": "PMD",
        "21": "HYB",
        "204": "W-TWF",
        "205": "S-ENF",
        "212": "A-OFD"
    };

    function convertSecondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
        const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60
        const sec = (seconds % 60).toFixed(); // remainder from minutes is seconds

        // Output as "HH:MM:SS"
        // Use .padStart(2, '0') to add leading zeroes if the numbers are less than 10
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = sec.toString().padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    function createSvgElement(consolegen) {
        // PlayStation SVG
        if (consolegen === "ps5" || consolegen === "ps4") {
            return (
                <svg width="18" height="18" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M399.77 203c-.8-17.1-3.3-34.5-10.8-50.1a82.45 82.45 0 00-16.5-23.2 105.59 105.59 0 00-21.3-16.3c-17.1-10.2-37.5-17-84.4-31S192 64 192 64v358.3l79.9 25.7s.1-198.8.1-299.5v-3.8c0-9.3 7.5-16.8 16.1-16.8h.5c8.5 0 15.5 7.5 15.5 16.8V278c11 5.3 29.2 9.3 41.8 9.1a47.79 47.79 0 0024-5.7 49.11 49.11 0 0018.4-17.8 78.64 78.64 0 009.9-27.3c1.87-10.8 1.97-22.1 1.57-33.3zM86.67 357.8c27.4-9.8 89.3-29.5 89.3-29.5v-47.2s-76.5 24.8-111.3 37.1c-8.6 3.1-17.3 5.9-25.7 9.5-9.8 4.1-19.4 8.7-28.1 14.8a26.29 26.29 0 00-9.2 10.1 17.36 17.36 0 00-.5 13.6c2 5.1 5.8 9.3 10.1 12.6 7.8 5.9 17.1 9.5 26.4 12.2a262.42 262.42 0 0088.4 13.3c14.5-.2 36-1.9 50-4.4v-42s-11 2.5-41.3 12.5c-4.6 1.5-9.2 3.3-14 4.3a104.87 104.87 0 01-21.6 2.2c-6.5-.3-13.2-.7-19.3-3.1-2.2-1-4.6-2.2-5.5-4.6-.8-2 .3-4 1.7-5.4 2.8-2.9 6.8-4.5 10.6-6z" fill="rgb(0, 111, 205)" />
                    <path d="M 512 345.9 c -0.1 -6 -3.7 -11.2 -7.9 -15 c -7.1 -6.3 -15.9 -10.3 -24.7 -13.5 c -5.5 -1.9 -9.3 -3.3 -14.7 -5 c -25.2 -8.2 -51.9 -11.2 -78.3 -11.3 c -8 0.3 -23.1 0.5 -31 1.4 c -21.9 2.5 -67.3 15.4 -67.3 15.4 v 48.8 s 67.5 -21.6 96.5 -31.8 a 94.43 94.43 0 0 1 30.3 -4.6 c 6.5 0.2 13.2 0.7 19.4 3.1 c 2.2 0.9 4.5 2.2 5.5 4.5 c 0.9 2.6 -0.9 5 -2.9 6.5 c -4.7 3.8 -10.7 5.3 -16.2 7.4 c -41 14.5 -132.7 44.7 -132.7 44.7 v 47 s 117.2 -39.6 170.8 -58.8 c 8.9 -3.3 17.9 -6.1 26.4 -10.4 c 7.9 -4 15.8 -8.6 21.8 -15.3 a 19.74 19.74 0 0 0 5 -13.1 Z" fill="rgb(0, 111, 205)" />
                </svg>
            );
        }
        // Xbox SVG
        else if (consolegen === "xbsx" || consolegen === "xone") {
            return (
                <svg width="18" height="18" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 126.8 248.3 c 39.7 -58.6 77.9 -92.8 77.9 -92.8 s -42.1 -48.9 -92.8 -67.4 l -3.3 -0.8 A 224.13 224.13 0 0 0 77.2 391 c 0 -4.4 0.6 -70.3 49.6 -142.7 Z M 480 256 a 223.71 223.71 0 0 0 -76.6 -168.7 l -3.2 0.9 c -50.7 18.5 -92.9 67.4 -92.9 67.4 s 38.2 34.2 77.9 92.8 c 49 72.4 49.6 138.3 49.5 142.7 A 222.8 222.8 0 0 0 480 256 Z M 201.2 80.9 c 29.3 13.1 54.6 34.6 54.6 34.6 s 25.5 -21.4 54.8 -34.6 c 36.8 -16.5 64.9 -11.3 72.3 -9.5 a 224.06 224.06 0 0 0 -253.8 0 c 7.2 -1.8 35.2 -7.1 72.1 9.5 Z M 358.7 292.9 C 312.4 236 255.8 199 255.8 199 s -56.3 37 -102.7 93.9 c -39.8 48.9 -54.6 84.8 -62.6 107.8 l -1.3 4.8 a 224 224 0 0 0 333.6 0 l -1.4 -4.8 c -8 -23 -22.9 -58.9 -62.7 -107.8 Z" fill="rgb(16, 124, 16)" />
                </svg>
            );
        }
        // Default or no consolegen provided
        return null;
    }

    const isGoalie = (player) => player.position === 'goalie';

    const handleImageError = () => {
        const nextIndex = imageUrls1.indexOf(currentImage1) + 1;
        if (nextIndex < imageUrls1.length) {
            setCurrentImage1(imageUrls1[nextIndex]);
        }
    };

    const [expanded, setExpanded] = useState(false);
    const [selectedStat, setSelectedStat] = useState(null);

    const statExplanations = {
        WAR: `Wins Above Replacement (WAR):\n
        WAR measures a player's overall contribution compared to a replacement-level player. It factors in offensive and defensive metrics.\n
        Replacement-level refers to the average of a stat from each player from each team.\n
        Impact refers to the product of the difference of a stat over the replacement-level multiplied by it's weighted importance. Example: Hits impact = (Players hits - replacement-level hits) * weight.\n
        Formula:\n
        WAR = (Ratings Impact + GAR Impact + Passing Impact + ShotPCT Impact + Shot Attempts Impact + Shots On Impact + Assist Impact + BS Impact + INT Impact + Hits Impact + Takes Impact) - (PIM Impact + Gives Impact)\n
        Player's Calculation:\n
        {playerStats}`,
        TO: `Total Offense (TO):\n
        TO evaluates a player's offensive contribution by weighing goals, assists, shooting percentage, expected goals, possession effeciency, shot attempt frequency, and power play impact.\n
        Impact refers to the product of the difference of a stat over the replacement-level multiplied by it's weighted importance. Example: Hits impact = (Players hits - replacement-level hits) * weight.\n
        Formula:\n
        TO = ((Goals Impact) + (Assists Impact) + (Shot % Impact) + (xG Impact) + (Possession Efficiency Impact) + (Shot Attempt Efficiency Impact) + (Power Play Goals Impact))\n
        Player's Calculation:\n
        {playerStats}`,
        TD: `Total Defense (TD):\n
        TD assesses a player's defensive contribution, factoring in blocked shots, takeaways, interceptions, giveaways, and penalties.\n
        Impact refers to the product of the difference of a stat over the replacement-level multiplied by it's weighted importance. Example: Hits impact = (Players hits - replacement-level hits) * weight.\n
        Formula:\n
        TD = ((Blocks Impact) + (Takeaways Impact) + (Interceptions Impact) - (Giveaways Impact) - (Penalty Minutes Impact))\n
        Player's Calculation:\n
        {playerStats}`,
        xG: `Expected Goals (xG):\n
        xG estimates how many goals a player should have scored based on scoring chances and the replacement-level shooting percentage.\n
        Replacement-level refers to the average of a stat from each player from each team.\n
        Formula:\n
        xG = Shots * Replacement-level ShotPCT\n
        Player's Calculation:\n
        {playerStats}`,
        GAE: `Goals Above Expected (GAE):\n
        GAE measures how many goals a player scores compared to their expected goals.\n
        Formula:\n
        GAE = Actual Goals - xG\n
        Player's Calculation:\n
        {playerStats}`,
        GAR: `Goals Above Replacement (GAR):\n
        GAR compares a player's goal contribution to a replacement-level player.\n
        Replacement-level refers to the average of a stat from each player from each team.\n
        Formula:\n
        GAR = (Player's Goals - Replacement-level goals)\n
        Player's Calculation:\n
        {playerStats}`,
        GWAR: `Goalie Wins Above Replacement (GWAR):\n
        GWAR evaluates a goalie's contribution to their team's wins compared to a replacement-level goalie. It incorporates shot stopping and situational performance.\n
        Impact refers to the product of the difference of a stat over the replacement-level multiplied by it's weighted importance. Example: Hits impact = (Players hits - replacement-level hits) * weight.\n
        Formula:\n
        GWAR = ((Ratings Impact) + (GSAA Impact) + (Breakaway Saves Impact) + (Penalty Shot Saves Impact) + (Save Percentage Impact))\n
        Player's Calculation:\n
        {playerStats}`,
        GSAA: `Goals Saved Above Average (GSAA):\n
        GSAA measures how many goals a goalie has saved compared to the replacement-level goalie, based on shots faced.\n
        Replacement-level refers to the average of a stat from each player from each team.\n
        Formula:\n
        GSAA = ((Shots Faced * (1 - (Replacement-level SavePct / 100))) - Goals Allowed)\n
        Player's Calculation:\n
        {playerStats}`,
        xGA: `Expected Goals Allowed (xGA):\n
        xGA estimates how many goals allowed a player should let in based on scoring chances and the replacement-level save percentage.\n
        Replacement-level refers to the average of a stat from each player from each team.\n
        Formula:\n
        xGA = (Regular Shots Faced * (1 - Replacement-level SavePct) / 100) + (Breakaway Shots Faced * (1 - Replacement-level BreakawaySavePct) / 100) + (Penshot Shots Faced * (1 - Replacement-level PenshotSavePct) / 100)\n
        Player's Calculation:\n
        {playerStats}`,
        Eff: `Effeciency (Eff):\n
        Eff evaluates a player's effeciency with the puck. \n
        Formula:\n
        Eff = (((Goals + Assists) / Possession * Weight) + (Passes / Possession * Weight) + (Shots / Possession * Weight))\n
        Player's Calculation:\n
        {playerStats}`,
    };

    const generateStatDetails = (statKey, player, replacementLevel) => {
        switch (statKey) {
            case 'WAR':
                return `WAR = (${(((parseFloat(player.ratingOffense) + (parseFloat(player.ratingDefense)) + parseFloat(player.ratingTeamplay)) / 3) / 195).toFixed(2)} + ${Math.max(0, player.GAR.toFixed(2) * .12).toFixed(2)} + ${Math.max((parseFloat(player.skassists) - replacementLevel.assists) / 8, 0).toFixed(2)} + ${Math.max((parseFloat(player.skpasspct) - replacementLevel.passPct) / 160, 0).toFixed(2)} + ${Math.max((parseFloat(player.skshotpct) - (replacementLevel.shotPct * 100)) / 450, 0).toFixed(2)} + ${Math.max((parseFloat(player.skshotonnetpct) - replacementLevel.shotOnNetPct) / 650, 0).toFixed(2)} + ${Math.max((parseFloat(player.skshotattempts) - replacementLevel.shotAttempts) / 300, 0).toFixed(2)} + ${Math.max((parseFloat(player.skbs) - replacementLevel.bs) / 13, 0).toFixed(2)} + ${Math.max((parseFloat(player.skinterceptions) - replacementLevel.ints) / 32, 0).toFixed(2)} + ${Math.max((parseFloat(player.skhits) - replacementLevel.hits) / 65, 0).toFixed(2)} + ${Math.max((parseFloat(player.sktakeaways) - replacementLevel.takeaways) / 32, 0).toFixed(2)}) - (${Math.max((parseFloat(player.skpim) - replacementLevel.pim) / 25, 0).toFixed(2)} + ${Math.max((parseFloat(player.skgiveaways) - replacementLevel.giveaways) / 60, 0).toFixed(2)}) = ${(player.playerWAR).toFixed(2)} = ${(Math.min(100, player.playerWAR).toFixed(2) * 100).toFixed(0)}%`;
            case 'TO':
                return `TO = ((${Math.max((parseFloat(player.skgoals) / (replacementLevel.goals * Math.max(parseFloat(player.toi) / 60, 0.001))) * 20, 0).toFixed(2)}) + (${Math.max((parseFloat(player.skassists) / (replacementLevel.assists * Math.max(parseFloat(player.toi) / 60, 0.001))) * 15, 0).toFixed(2)}) + (${Math.max((parseFloat(player.skshotpct) / 100) * 1, 0).toFixed(2)}) + (${Math.max((parseFloat(player.xG) / (Math.max(replacementLevel.shots * replacementLevel.shotPct, 0.001))) * 5, 0).toFixed(2)}) + (${Math.max(parseFloat(player.possessionEfficiency) * 1, 0).toFixed(2)}) + (${Math.max((((parseFloat(player.skshotattempts) / Math.max(parseFloat(player.toi) / 60, 0.001)) - (replacementLevel.shotAttempts / 60)) / Math.max(replacementLevel.shotAttempts / 60, 0.001)) * .5, 0).toFixed(2)}) + (${Math.max((parseFloat(player.skppg) / Math.max(parseFloat(player.xG), 0.001)) * 5, 0).toFixed(2)}) = ${player.totalOffenseScore.toFixed(2)} = ${(Math.min(100, player.totalOffenseScore).toFixed(0))}%`;
            case 'TD':
                return `TD = (${((parseFloat(player.skbs) / (parseFloat(player.toi) / 60)) * 20).toFixed(2)} + ${((parseFloat(player.sktakeaways) / (parseFloat(player.toi) / 60)) * 15).toFixed(2)} + ${((parseFloat(player.skinterceptions) / (parseFloat(player.toi) / 60)) * 15).toFixed(2)} - ${((parseFloat(player.skgiveaways) / (parseFloat(player.toi) / 60)) * -8).toFixed(2)} - ${((parseFloat(player.skpim) / (parseFloat(player.toi) / 60)) * -10).toFixed(2)}) = ${player.totalDefenseScore.toFixed(2)} = ${(Math.min(100, player.totalDefenseScore).toFixed(0))}%`;
            case 'xG':
                return `xG = ${player.skshots} * ${replacementLevel.shotPct.toFixed(2)} = ${player.xG.toFixed(2)}`
            case 'GAE':
                return `GAE: ${player.skgoals} - ${player.xG.toFixed(2)} = ${player.GAE.toFixed(2)}`;
            case 'GAR':
                return `GAR = ${player.skgoals} - ${replacementLevel.goals.toFixed(2)} = ${player.GAR.toFixed(2)}`;
            case 'GWAR':
                return `GWAR = ${((parseFloat(player.ratingOffense) + parseFloat(player.ratingDefense) + parseFloat(player.ratingTeamplay)) / 3).toFixed(2)} + ${(player.GSAA / 5).toFixed(2)} + ${(player.brkGSAA / 5).toFixed(2)} + ${(((parseFloat(player.glsavepct) - replacementLevel.savePct / 100) * (parseInt(player.glshots))) / 5).toFixed(2)} + ${(((parseFloat(player.glbrksavepct) - replacementLevel.brkwaySavePct / 100) * (parseInt(player.glbrkshots))) / 5).toFixed(2)} + ${((((parseFloat(player.glpensavepct) * 100) - replacementLevel.penShotPct / 100) * parseInt(player.glpenshots)) / 5).toFixed(2)} = ${player.playerGWAR.toFixed(2)} = ${(Math.min(100, player.playerGWAR)).toFixed(0)}%`;
            case 'GSAA':
                return `GSAA = (${parseInt(player.glshots)} * (1 - (${replacementLevel.savePct.toFixed(2)} / 100)) - ${parseInt(player.glga)}) = ${((parseInt(player.glshots) * (1 - (replacementLevel.savePct / 100))) - parseInt(player.glga)).toFixed(2)}`;
            case 'xGA':
                const xGARegular = (parseInt(player.glshots) - (parseInt(player.glbrkshots) + parseInt(player.glpenshots))) * (1 - (replacementLevel.savePct / 100));
                const xGABreakaway = parseInt(player.glbrkshots) * (1 - (replacementLevel.brkwaySavePct / 100));
                const xGAPenalty = parseInt(player.glpenshots) * (1 - (replacementLevel.penShotPct / 100));

                return `xGA = ${xGARegular.toFixed(2)} + ${xGABreakaway.toFixed(2)} + ${xGAPenalty.toFixed(2)} = ${player.EGA.toFixed(2)}`;
            case 'Eff':
                return `Eff = (((${parseInt(player.skgoals)} + ${parseInt(player.skassists)}) / ${parseInt(player.skpossession)} * 6) + (${parseInt(player.skpasses)} / ${parseInt(player.skpossession)} * .08) + (${parseInt(player.skshots)} / ${parseInt(player.skpossession)} * .1)) = ${parseInt(player.possessionEfficiency)}%`;
            default:
                return 'No details available.';
        }
    };


    const handleStatClick = (stat) => {
        if (selectedStat === stat) {
            setExpanded(false); // Close if the same stat is clicked
            setSelectedStat(null);
        } else {
            setExpanded(true); // Open if a new stat is clicked
            setSelectedStat(stat);
        }
    };


    const renderStat = (label, value) => (
        <Box
            onClick={() => handleStatClick(label)}
            sx={{
                marginX: 'auto',
                mt: 1,
                mb: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': { opacity: 0.8 }
            }}
        >
            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{label}</Typography>
            <Typography
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '30px',
                    width: '50px',
                    fontSize: '18px',
                    backgroundColor: label === 'WAR' || label === 'TO' || label === 'TD' || label === 'GWAR'
                        ? value >= 60 ? 'green' : value < 60 && value >= 50 ? 'orange' : 'red'
                        : value > 0 ? 'green' : 'red',
                }}
            >
                {label === 'WAR' || label === 'TO' || label === 'TD' || label === 'GWAR' || label === 'Eff' ? `${value.toFixed(0)}%` : value}
            </Typography>
        </Box>
    );

    if (!gameStats) {
        return (
            <Typography sx={{ color: 'white', fontSize: '16px', textAlign: 'center', marginTop: 2 }}>
                Loading game stats...
            </Typography>
        );
    } else {

        return (
            <Paper sx={{ height: 'fit-content', overflow: 'auto', backgroundColor: 'transparent' }}>
                <Box sx={{ display: 'flex', justifyContent: windowWidth < 600 ? 'space-between' : windowWidth < 900 ? 'none' : 'none', mb: 2, justifySelf: windowWidth < 600 ? 'none' : windowWidth < 900 ? 'center' : 'center' }}>
                    <FormControl variant="standard" size="medium" sx={{ ml: 3, minWidth: 120, '& .MuiSelect-icon': { color: 'white' } }}>
                        <InputLabel
                            id="team-select-label"
                            sx={{ color: 'rgb(25, 118, 210)' }}
                            shrink={true} // This ensures the label is always visible
                        >Team</InputLabel>
                        <Select
                            labelId="team-select-label"
                            id="team-select"
                            value={selectedTeam || ''}
                            label="Team"
                            onChange={handleTeamChange}
                            sx={{ fontSize: '14px', color: 'white', '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                        >
                            {Object.keys(gameStats.clubs).map((team, index) => (
                                <MenuItem key={index} value={team}>{gameStats.clubs[team].details.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {selectedTeam && (
                        <FormControl variant="standard" size="medium" sx={{ mr: 3, ml: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 1, minWidth: 120, '& .MuiSelect-icon': { color: 'white' } }}>
                            <InputLabel
                                id="player-select-label"
                                sx={{ color: 'rgb(25, 118, 210)' }}
                                shrink={true} // This ensures the label is always visible
                            >Player</InputLabel>
                            <Select
                                labelId="player-select-label"
                                id="player-select"
                                value={selectedPlayer || ''}
                                label="Player"
                                onChange={handlePlayerChange}
                                sx={{ fontSize: '14px', color: 'white', '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                            >
                                {teamPlayers.map((player, index) => (
                                    <MenuItem key={index} value={player.playername}>{player.playername}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>

                {currentPlayer && currentPlayer.posSorted != '0' && (

                    <Accordion
                        expanded={expanded}
                        sx={{
                            backgroundColor: '#1a1a1a',
                            color: 'white',
                            borderRadius: 2,
                            paddingTop: 1,
                            width: windowWidth < 600 ? '100%' : windowWidth < 900 ? '45%' : '45%',
                            '& .MuiAccordionSummary-content': { alignItems: 'center' },
                            margin: 'auto'
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box
                                                component="img"
                                                src={currentImage1}
                                                onError={handleImageError}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    mr: 1
                                                }}
                                            />
                                            <Typography sx={{ fontSize: '22px', fontWeight: 600, mr: 1.5 }}>{currentPlayer.playername}</Typography>
                                            {createSvgElement(currentPlayer.clientPlatform)}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography sx={{ fontSize: '14px', mt: 1 }}>Pos: <span style={{ fontWeight: 800 }}>{positionMapping[currentPlayer.posSorted]}</span></Typography>
                                                <Typography sx={{ fontSize: '14px' }}>Class: <span style={{ fontWeight: 800 }}>{buildMapping[currentPlayer.class || 'Unknown']}</span></Typography>
                                                <Typography sx={{ fontSize: '14px', mb: 2 }}>Poss time: <span style={{ fontWeight: 800 }}>{convertSecondsToHMS(currentPlayer.skpossession)}</span></Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mt: 1 }}>G: <span style={{ fontWeight: 800 }}>{currentPlayer.skgoals}</span></Typography>
                                                <Typography sx={{ fontSize: '14px' }}>A: <span style={{ fontWeight: 800 }}>{currentPlayer.skassists}</span></Typography>
                                                <Typography sx={{ fontSize: '14px', mb: 2 }}>P: <span style={{ fontWeight: 800 }}>{parseInt(currentPlayer.skgoals) + parseInt(currentPlayer.skassists)}</span></Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mt: 1 }}>H: <span style={{ fontWeight: 800 }}>{currentPlayer.skhits}</span></Typography>
                                                <Typography sx={{ fontSize: '14px' }}>I: <span style={{ fontWeight: 800 }}>&#8196;{currentPlayer.skinterceptions}</span></Typography>
                                                <Typography sx={{ fontSize: '14px', mb: 2 }}>T: <span style={{ fontWeight: 800 }}>{currentPlayer.sktakeaways}</span></Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mt: 1 }}>+/- &#8239;<span style={{ fontWeight: 800 }}>{currentPlayer.skplusmin}</span></Typography>
                                                <Typography sx={{ fontSize: '14px' }}>GW: <span style={{ fontWeight: 800 }}>{currentPlayer.skgiveaways}</span></Typography>
                                                <Typography sx={{ fontSize: '14px', mb: 2 }}>PM: <span style={{ fontWeight: 800 }}>{currentPlayer.skpim}</span></Typography>
                                            </Box>


                                            <Box sx={{ ml: 4 }}>
                                                {renderStat('WAR', currentPlayer.WAR)}
                                            </Box>
                                        </Box>

                                    </Box>

                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    {renderStat('TO', currentPlayer.totalOffense)}
                                    {renderStat('TD', currentPlayer.totalDefense)}
                                    {renderStat('Eff', currentPlayer.possessionEfficiency)}
                                    {renderStat('xG', currentPlayer.xG.toFixed(2))}
                                    {renderStat('GAE', currentPlayer.GAE.toFixed(2))}
                                    {renderStat('GAR', currentPlayer.GAR.toFixed(2))}
                                </Box>
                            </Box>

                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: '#2a2a2a', padding: 2 }}>
                            <Typography sx={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                                {selectedStat && statExplanations[selectedStat]?.replace('{playerStats}', generateStatDetails(selectedStat, currentPlayer, replacementLevel))}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}

                {currentPlayer && currentPlayer.GWAR && (
                    <Accordion
                        expanded={expanded}
                        sx={{
                            backgroundColor: '#1a1a1a',
                            color: 'white',
                            borderRadius: 2,
                            width: windowWidth < 600 ? '100%' : windowWidth < 900 ? '45%' : '45%',
                            '& .MuiAccordionSummary-content': { alignItems: 'center' },
                            margin: 'auto'
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box
                                                component="img"
                                                src={currentImage1}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    mr: 1
                                                }}
                                            />
                                            <Typography sx={{ fontSize: '22px', fontWeight: 600, mr: 1.5 }}>{currentPlayer.playername}</Typography>
                                            {createSvgElement(currentPlayer.clientPlatform)}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography sx={{ fontSize: '14px', mt: 1 }}>Pos: <span style={{ fontWeight: 800 }}>{positionMapping[currentPlayer.posSorted]}</span></Typography>
                                                <Typography sx={{ fontSize: '14px' }}>Class: <span style={{ fontWeight: 800 }}>{buildMapping[currentPlayer.class || 'Unknown']}</span></Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mt: 1 }}>SA: <span style={{ fontWeight: 800 }}>{currentPlayer.glsaves}</span></Typography>
                                                <Typography sx={{ fontSize: '14px' }}>GAA: <span style={{ fontWeight: 800 }}>{currentPlayer.glgaa}</span></Typography>
                                                <Typography sx={{ fontSize: '14px', mb: 2 }}>SA%: <span style={{ fontWeight: 800 }}>{currentPlayer.glsavepct}</span></Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mt: 1 }}>BWS%: <span style={{ fontWeight: 800 }}>{currentPlayer.glbrksavepct}</span></Typography>
                                                <Typography sx={{ fontSize: '14px' }}>PSS%: <span style={{ fontWeight: 800 }}>{currentPlayer.glpensavepct}</span></Typography>
                                            </Box>
                                            <Box sx={{ ml: 4 }}>
                                                {renderStat('GWAR', currentPlayer.GWAR)}
                                            </Box>
                                        </Box>

                                    </Box>

                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                                    <Box>
                                        {renderStat('xGA', currentPlayer.EGA.toFixed(2))}
                                    </Box>
                                    <Box sx={{ marginLeft: 2 }}>
                                        {renderStat('GSAA', currentPlayer.GSAA.toFixed(2))}
                                    </Box>
                                </Box>
                            </Box>

                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: '#2a2a2a', padding: 2 }}>
                            <Typography sx={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                                {selectedStat && statExplanations[selectedStat]?.replace('{playerStats}', generateStatDetails(selectedStat, currentPlayer, replacementLevel))}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Paper>
        );
    }
}

export default AdvancedStats;