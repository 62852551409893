import React from 'react';
import { Box, styled } from '@mui/material';

const PositionSegment = styled('div')(({ width, bgcolor }) => ({
    height: '20px',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: `${width}%`,
    backgroundColor: bgcolor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    fontSize: '12px',
    fontWeight: 'bold',
}));

const PositionalProgressBar = ({ totalGames, gamesByPosition }) => {
    const positions = Object.keys(gamesByPosition);

    // Sort positions by games played in descending order
    const sortedPositions = positions.sort((a, b) => gamesByPosition[b] - gamesByPosition[a]);

    return (
        <Box
            sx={{
                marginTop: 2,
                overflow: 'hidden',
                borderRadius: '20px',
                backgroundColor: '#e0e0e0',
                height: '20px',
                display: 'flex',
            }}
        >
            {sortedPositions.map((position, index) => {
                const gamesPlayed = gamesByPosition[position];
                if (gamesPlayed === 0) return null; // Exclude positions with 0 games played

                const width = (gamesPlayed / totalGames) * 100; // Width as percentage of the total
                const color = positionColors(position);

                // Display percentage inside the segment for the top 2 positions
                const showPercentage = index < 1; // Show for the top 2 positions

                return (
                    <PositionSegment key={position} width={width} bgcolor={color}>
                        {showPercentage
                            ? `${gamesPlayed} (${((gamesPlayed / totalGames) * 100).toFixed(1)}%)`
                            : gamesPlayed}
                    </PositionSegment>
                );
            })}
        </Box>
    );
};

// Helper function to determine color based on position
function positionColors(position) {
    switch (position) {
        case 'D': return '#ffc107';
        case 'C': return '#d72121';
        case 'LW': return '#4caf50';
        case 'RW': return '#2196f3';
        case 'G': return '#9321d7';
        default: return '#000';
    }
}

export default PositionalProgressBar;
