import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PlayerStatChart = ({ memberData, selectedStat }) => {
  // Aggregate the selected stat for all players
  const aggregatedValue = memberData.reduce(
    (acc, member) => acc + (parseFloat(member[selectedStat]) || 0),
    0
  );

  const percentages = memberData.map((member) =>
    ((parseFloat(member[selectedStat]) || 0) / aggregatedValue) * 100
  );

  const chartData = {
    labels: memberData.map((member, index) =>
      `${member.Username} (${percentages[index].toFixed(2)}%)`
    ),
    datasets: [
      {
        data: memberData.map((member) => parseFloat(member[selectedStat]) || 0),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
        ], // Add more colors if needed
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    onHover: (event, elements) => {
      const chart = elements[0]?._chart;
      if (!chart) return;
      const ctx = chart.ctx;

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        dataset.data.forEach((value, index) => {
          const percentage = ((value / aggregatedValue) * 100).toFixed(2);
          const label = `${value} (${percentage}%)`;

          // Position the label inside the slice
          const meta = chart.getDatasetMeta(datasetIndex).data[index];
          const position = meta.tooltipPosition();
          ctx.fillStyle = 'white';
          ctx.fillText(
            label,
            position.x - ctx.measureText(label).width / 2,
            position.y
          );
        });
      });
    },
  };


  return (
    <Box sx={{ marginTop: 5, height: '400px', width: '100%' }}>
      {memberData && memberData.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <Typography sx={{ color: 'white', fontSize: '16px' }}>
              {selectedStat}
            </Typography>
          </Box>
          <Doughnut data={chartData} options={options} />
        </>
      ) : (
        <Typography sx={{ color: 'white', textAlign: 'center' }}>
          No data available for the selected stat
        </Typography>
      )}
    </Box>
  );
};

export default PlayerStatChart;
