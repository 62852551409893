import React, { useEffect, useState, useMemo } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import { PropagateLoader } from 'react-spinners';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Tabs, Tab } from '@mui/material';
import TeamStatsDisplay from './TeamStatsDisplay';
import { Link, useNavigate } from 'react-router-dom';
import TeamsRecentGames from './TeamsRecentGames'
import { useLocation } from 'react-router-dom';
import { i } from 'mathjs';
function LeaguesTeamPageSixes({ team, league, lowercaseteam, color }) {
    useEffect(() => {
        document.title = `Chel stats | ${team.toLocaleUpperCase()}`;
    }, [team]);
    const location = useLocation();
    const { loading, setLoading } = useLoading();
    const [skaterHeaders, setSkaterHeaders] = useState([]);
    const [goalieHeaders, setGoalieHeaders] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [teamHeaders, setTeamHeaders] = useState([]);
    const [skaterData, setSkaterData] = useState([]);
    const [goalieData, setGoalieData] = useState([]);
    const [teamStats, setTeamStats] = useState(null);
    const [maxStats, setMaxStats] = useState(null);
    const [minStats, setMinStats] = useState(null);
    const [gameData, setGameData] = useState();
    const [activeTab, setActiveTab] = useState('teamStats');
    const [sortConfigSkaters, setSortConfigSkaters] = useState({
        key: 'Points',
        direction: 'desc',
    });

    const [sortConfigGoalies, setSortConfigGoalies] = useState({
        key: 'Wins',
        direction: 'desc',
    });

    const positionAcronyms = {
        'center': 'C',
        'defensemen': 'D',
        'leftwing': 'LW',
        'rightwing': 'RW',
        'goalie': 'G'
    };

    useEffect(() => {
        setLoading(true);

        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/teampage?league=${league}&team=${league} ${team}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const rawData = await response.json();
                processData(rawData.values);

                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };

        const processData = (rawData) => {
            if (!rawData || rawData.length === 0) {
                console.error('No data found for team page');
                return;
            }

            const teamHeaders = rawData[0].slice(0, 7);
            const skaterHeaders = rawData[0].slice(7, 62).map(header =>
                header.toLowerCase() === 'roster' ? 'Username' : header
            );
            const goalieHeaders = rawData[0].slice(63).map(header =>
                header.toLowerCase() === 'goalies' ? 'Username' : header
            );

            setTeamHeaders(teamHeaders);
            setSkaterHeaders(skaterHeaders);
            setGoalieHeaders(goalieHeaders);

            // Replace position names in skater data with acronyms
            const skaterRows = rawData.slice(1).map(row => row.slice(7, 62).map(cell => {
                const lowerCell = cell?.toLowerCase();
                return positionAcronyms[lowerCell] ? positionAcronyms[lowerCell] : cell;
            }));

            const teamRows = rawData.slice(1).map(row => row.slice(0, 7));
            const goalieRows = rawData.slice(1).map(row => row.slice(63).map(cell => {
                const lowerCell = cell?.toLowerCase();
                return positionAcronyms[lowerCell] ? positionAcronyms[lowerCell] : cell;
            }));

            setTeamData(teamRows.filter(row => row.some(cell => cell !== '')));
            setSkaterData(skaterRows.filter(row => row.some(cell => cell !== '')));
            setGoalieData(goalieRows.filter(row => row.some(cell => cell !== '')));
        };

        const fetchTeamStats = async () => {
            try {
                const response = await fetch(`/api/fetch/teamstats?league=${league}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const rawData = await response.text(); // Initially get it as text
                const parsedData = JSON.parse(rawData); // Parse the JSON string into an object
                parseTeamStats(parsedData);
            } catch (error) {
                console.error('Failed to fetch team stats:', error);
            }
        };

        const parseTeamStats = (data) => {
            try {
                if (!data || !data.values || !Array.isArray(data.values)) {
                    throw new Error('Invalid data format: Missing `values` array.');
                }

                const headers = data.values[0];
                const dataRows = data.values.slice(1);

                const parsedData = dataRows.map(row => {
                    return headers.reduce((obj, header, index) => {
                        obj[header.trim()] = row[index]?.trim() || '';
                        return obj;
                    }, {});
                });


                // Normalize the team name for consistent lookup
                if (team == 'Tokyo Electric Hounds') {
                    team = 'Tky Electric Hounds'
                }

                const searchTeamName = `${league} ${team}`.trim().toLowerCase();

                // Find the team in parsed data
                const teamStat = parsedData.find(stat => {
                    const statTeamName = stat.Team?.trim().toLowerCase();
                    return statTeamName === searchTeamName;
                });

                if (teamStat) {
                    setTeamStats(teamStat);
                    calculateMaxStats(parsedData);
                    calculateMinStats(parsedData);
                } else {
                    console.warn(`No matching team stats found for: ${searchTeamName}`);
                }
            } catch (error) {
                console.error("Error parsing team stats:", error);
            }
        };

        const calculateMaxStats = (parsedData) => {
            const numericHeaders = parsedData.length > 0 ? Object.keys(parsedData[0]).filter(key => key !== "Team" && key !== "ID") : [];

            const maxValues = numericHeaders.reduce((acc, header) => {
                acc[header] = Math.max(...parsedData.map(team => parseFloat(team[header]) || 0));
                return acc;
            }, {});

            setMaxStats(maxValues);
        };

        const calculateMinStats = (parsedData) => {
            const numericHeaders = parsedData.length > 0 ? Object.keys(parsedData[0]).filter(key => key !== "Team" && key !== "ID") : [];

            const minValues = numericHeaders.reduce((acc, header) => {
                acc[header] = Math.min(...parsedData.map(team => parseFloat(team[header]) || 0));

                return acc;
            }, {});

            setMinStats(minValues); // Return the computed min values
        };


        fetchSheetData();
        fetchTeamStats();
    }, [team]);

    useEffect(() => {
        if (location.state?.activeTab) {
            setActiveTab(location.state.activeTab);
        }
    }, [location.state]);

    const filterGamesByTeam = (data, team) => {
        
        // Convert the object into an array of [matchID, gameObject] pairs
        const filteredEntries = Object.entries(data).filter(([matchID, gameObject]) => {
            if (gameObject.game['Team 1'] === undefined || gameObject.game['Team 2'] === undefined) {
                return false
            }
            const team1Name = gameObject.game['Team 1'];
            const team2Name = gameObject.game['Team 2'];

            // Check if either team matches the given team name
            return team1Name.toLowerCase() === team.toLowerCase() || team2Name.toLowerCase() === team.toLowerCase();
        });

        // Convert the filtered array back into an object
        return Object.fromEntries(filteredEntries);
    };

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await fetch(`/api/gamedata/${league}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                if (team == 'Tokyo Electric Hounds') {
                    team = 'Tky Electric Hounds'
                }
                const filteredData = filterGamesByTeam(data, `${league} ${team}`);
                setGameData(filteredData)
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
            }
        };

        fetchGameData();
    }, []);

    const sortData = (data, headers, sortConfig) => {
        return data.sort((a, b) => {
            const index = headers.indexOf(sortConfig.key);
            if (index === -1) return 0;

            let valueA = a[index];
            let valueB = b[index];

            valueA = isNaN(+valueA) ? valueA : +valueA;
            valueB = isNaN(+valueB) ? valueB : +valueB;

            return (valueA < valueB ? -1 : 1) * (sortConfig.direction === 'asc' ? 1 : -1);
        });
    };

    const handleRequestSort = (setSortConfig, sortConfig) => (property) => {
        const isAsc = sortConfig.key === property && sortConfig.direction === 'asc';
        setSortConfig({ key: property, direction: isAsc ? 'desc' : 'asc' });
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    function extractLeagueName(fullString) {
        if (!fullString || typeof fullString !== 'string') {
            return ''; // Return an empty string for invalid input
        }
        return fullString.split('-')[0].trim();
    }

    const RenderTable = ({ data, headers, sortConfig, onSortChange, usernameHeaderName }) => {
        const navigate = useNavigate();
        const sortedData = useMemo(() => sortData(data, headers, sortConfig), [data, headers, sortConfig]);

        const handleRowClick = (username) => {
            // Redirect to the new page with the username as a parameter in the URL
            navigate(`/${league}/${team.toLowerCase().replace(/\s+/g, '-')}/players/${username}`);
        };

        return (
            <Box sx={{ maxWidth: '98%', m: 'auto', alignItems: 'center', overflowX: 'auto', marginTop: 2 }}>
                <TableContainer component={Paper} sx={{ marginTop: 1, marginBottom: 2, zIndex: 'tooltip' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#1C4587' }}>
                                {headers.map((header, index) => (
                                    <TableCell
                                        key={header}
                                        sx={{
                                            color: 'white',
                                            top: 0,
                                            p: '4px',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            backgroundColor: '#1C4587',
                                            position: index === 0 ? 'sticky' : 'static',
                                            left: index === 0 ? 0 : 'auto', // Position sticky only on the first column
                                            zIndex: index === 0 ? 2 : 'auto',
                                        }}
                                    >
                                        <TableSortLabel
                                            active={sortConfig.key === header}
                                            direction={sortConfig.key === header ? sortConfig.direction : 'asc'}
                                            onClick={() => onSortChange(header)}
                                        >
                                            {header === 'Username' ? usernameHeaderName : header} {/* Use dynamic name for Username header */}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((row, rowIndex) => (
                                <TableRow
                                    hover
                                    key={rowIndex}
                                    onClick={() => handleRowClick(row[headers.indexOf('Username')])}
                                    style={{ cursor: 'pointer' }}
                                    sx={{
                                        '&:hover .username-cell': { // Apply hover effect only on Username cell
                                            backgroundColor: '#f0f0f0', // Adjust to your preferred hover color
                                            color: '#1C4587', // Optional color change on hover
                                            fontWeight: 'bold',
                                        },
                                    }}
                                >
                                    {row.map((cell, cellIndex) => (
                                        <TableCell
                                            key={cellIndex}
                                            className={cellIndex === 0 ? 'username-cell' : ''} // Add class to Username column
                                            sx={{
                                                padding: '8px',
                                                fontWeight: '500',
                                                position: cellIndex === 0 ? 'sticky' : 'static', // Make the Username column sticky
                                                left: cellIndex === 0 ? 0 : 'auto', // Position sticky only on the first column
                                                zIndex: cellIndex === 0 ? 1 : 'auto',
                                                backgroundColor: cellIndex === 0 ? 'white' : 'inherit',
                                                '&:hover': cellIndex === 0 && { // Add hover styling directly
                                                    backgroundColor: '#f0f0f0',
                                                    color: '#1C4587',
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                        >
                                            {cell === "" ? '0' : cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    };


    const logoPath = require(`./${extractLeagueName(league).toUpperCase()}/Logos/${team.toLowerCase().replace(/\s+/g, '-')}.png`);
    const LogoComponent = ({ logoUrl }) => {
        const logoStyles = {
            width: '100px',
            height: '100px',
            objectFit: 'fill',
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                <img src={logoUrl} alt="Logo" style={logoStyles} />
            </div>
        );
    };

    let formattedPTSRank = "";
    if (teamStats) {
        if (teamStats['PTS Rank'] == '1') {
            formattedPTSRank = '1st';
        } else if (teamStats['PTS Rank'] == '2') {
            formattedPTSRank = '2nd';
        } else if (teamStats['PTS Rank'] == '3') {
            formattedPTSRank = '3rd';
        } else {
            formattedPTSRank = `${teamStats['PTS Rank']}th`;
        }
    }

    return (
        <Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                    <PropagateLoader color="#3498db" />
                </Box>
            ) : (
                <Box
                    sx={{
                        maxWidth: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginTop: 3,
                    }}
                >
                    <LogoComponent logoUrl={logoPath} />

                    {teamStats && (
                        <>
                            <Typography
                                sx={{
                                    marginTop: 0,
                                    color: 'white',
                                    fontSize: 32,
                                    fontWeight: 750,
                                    fontStyle: 'italic',
                                    fontFamily:
                                        'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                                }}
                            >
                                {team}
                            </Typography>

                            <Typography sx={{ color: '#B0B0B0', fontSize: 18 }}>
                                {`${teamStats?.W || 0}-${teamStats?.L || 0}-${teamStats?.OTL || 0}, ${formattedPTSRank}`}
                            </Typography>
                        </>
                    )}

                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        sx={{ marginTop: 3 }}
                    >
                        <Tab label="Team Stats" sx={{ color: 'white' }} value="teamStats" />
                        <Tab label="Recent Games" sx={{ color: 'white' }} value="recentGames" />
                        <Tab label="Player Stats" sx={{ color: 'white' }} value="playerStats" />
                    </Tabs>

                    {activeTab === "teamStats" && teamStats && maxStats && (
                        <TeamStatsDisplay teamData={teamStats} maxStats={maxStats} minStats={minStats} />
                    )}
                    {activeTab === "recentGames" && gameData && (
                        <TeamsRecentGames gamesData={gameData} league={league} team={team} />

                    )}
                    {activeTab === "playerStats" && (
                        <>
                            <RenderTable
                                data={skaterData}
                                headers={skaterHeaders}
                                sortConfig={sortConfigSkaters}
                                onSortChange={handleRequestSort(setSortConfigSkaters, sortConfigSkaters)}
                                usernameHeaderName="Skaters"
                            />

                            <RenderTable
                                data={goalieData}
                                headers={goalieHeaders}
                                sortConfig={sortConfigGoalies}
                                onSortChange={handleRequestSort(setSortConfigGoalies, sortConfigGoalies)}
                                usernameHeaderName="Goalies"
                            />
                        </>
                    )}

                </Box>

            )}
        </Box>
    );
}

export default LeaguesTeamPageSixes;
