import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Divider, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PositionToggle from './PositionToggle';
import TeamLogo from './TeamLogo';
import ScrollableTable from './ScrollableTable';
import { useStats } from '../../Contexts/StatsContext';
import { useUser } from '../../Contexts/UserContext';
import PlayerInfo from './PlayerInfo';
import OverallRadarChart from './OverallRadarChart';
import HockeyDataComponent from './HockeyDataComponent'
import AdComponent from '../../Main Components/AdComponent';
import PositionalProgressBar from './PositionalProgressBar'

function SetPlayer({ playerData, freeAgentState, clubInfo, jersey }) {
    const location = useLocation();
    const { state, dispatch } = useStats();
    const { positionState } = state;
    const { user } = useUser();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (playerData) {
            // Corrected condition check
            const skaterPositions = ["center", "leftWing", "rightWing", "defenseMen"];
            if (skaterPositions.includes(playerData.Position)) {
                dispatch({ type: 'SET_POSITION', payload: 'skater' });
            } else if (playerData.Position === 'goalie') {
                dispatch({ type: 'SET_POSITION', payload: 'goalie' });
            }
        }
    }, [playerData, dispatch]);

    if (playerData) {

        const gamesPlayedByPosition = {
            center: parseInt(playerData["C Gps"], 10),
            leftWing: parseInt(playerData["LW Gps"], 10),
            rightWing: parseInt(playerData["RW Gps"], 10),
            defenseMen: parseInt(playerData["D Gps"], 10),
            goalie: parseInt(playerData["G Gps"], 10),
        };

        const sortedPositions = Object.entries(gamesPlayedByPosition).sort((a, b) => b[1] - a[1]);

        const primaryPositionKey = sortedPositions[0][0];

        const secondaryPositionKey = sortedPositions[1][0];

        const positionAbbreviations = {
            center: 'C',
            leftWing: 'LW',
            rightWing: 'RW',
            defenseMen: 'D',
            goalie: 'G',
        };

        const primaryPosition = positionAbbreviations[primaryPositionKey] || primaryPositionKey;
        const secondaryPosition = positionAbbreviations[secondaryPositionKey] || secondaryPositionKey;

        if (freeAgentState === false) {

            const position = primaryPosition + '/' + secondaryPosition;
            let atAGlanceStats = []
            let statsSections = []
            let OffensiveStats = []
            let OffensiveStats2 = []
            let DefensiveStats = []
            let DefensiveStats2 = []
            let DefensiveStats3 = []
            let TeamplayTotals = []
            let TeamplayTotals2 = []
            let TeamplayTotals3 = []
            let ShootingStats = []
            let ShootingStats2 = []
            let PassingStats = []
            let PassingStats2 = []
            let XfactorZoneStats = []
            let XfactorZoneStats2 = []
            let XfactorSuperStats = []
            let XfactorSuperStats2 = []
            let GoalieStatsShutouts = []
            let GoalieStatsShutouts2 = []
            let GoalieStats = []
            let GoalieStats2 = []
            let GoalieStats3 = []
            let GoalieXfactorStats = []
            if (positionState === 'skater') {
                atAGlanceStats = [
                    { label: 'GP', value: playerData['Games Played'] },
                    { label: 'G', value: playerData['Goals'] },
                    { label: 'A', value: playerData['Assists'] },
                    { label: 'P', value: playerData['Points'] },
                    { label: '+/-', value: playerData['+/-'] },
                ];

                OffensiveStats = [
                    { label: 'Goals/PG', value: playerData['Goals per game'] },
                    { label: 'Assists/PG', value: playerData['Assists per game'] },
                    { label: 'Points/PG', value: parseFloat(playerData['Goals per game']) + parseFloat(playerData['Assists per game']) },
                    { label: 'PPG', value: playerData['Power play goals'] },
                    { label: 'GWGs', value: playerData['GWGs'] },
                    { label: 'Hattricks', value: playerData['Hattricks'] },
                    { label: 'Dekes', value: playerData['Dekes Made'] },
                    { label: 'Dekes/PG', value: playerData['Dekes per game'] },
                ];

                DefensiveStats = [
                    { label: 'Hits', value: playerData['Hits'] },
                    { label: 'Hits/PG', value: playerData['Hits per game'] },
                    { label: 'Ints', value: playerData['Interceptions'] },
                    { label: 'Ints/PG', value: playerData['Interceptions per game'] },
                    { label: 'Tkwys', value: playerData['Takeaways'] },
                    { label: 'Tkwys/PG', value: playerData['Takeaways per game'] },
                    { label: 'BS', value: playerData['Blocked shots'] },
                    { label: 'BS/PG', value: playerData['Blocked shots per game'] },
                    { label: 'PK zone clears', value: playerData['PK zone clears'] },
                    { label: 'Def GPs', value: playerData['D Gps'] },
                ];

                TeamplayTotals = [
                    { label: 'FO%', value: playerData['FO %'] },
                    { label: 'Fights won', value: playerData['Fights Won'] },
                    { label: 'Fights won%', value: (parseFloat(playerData['Fights Won %']) * 100).toFixed(1) },
                    { label: '+/- PG', value: playerData['+/- per game'] },
                    { label: 'Poss', value: playerData['Possession time'] },
                    { label: 'Poss/PG', value: playerData['Possession per game'].substring(3, 8) },
                    { label: 'PIM', value: playerData['PIM'] },
                    { label: 'PIM/PG', value: playerData['PIMS per game'] },
                    { label: 'Pens drawn', value: playerData['Penalties Drawn'] },
                    { label: 'Pens drawn/PG', value: playerData['Penalties drawn per game'] },
                    { label: 'Offsides', value: playerData['Offsides'] },
                    { label: 'Offsides/PG', value: playerData['Offsides per game'] },
                ];

                ShootingStats = [
                    { label: 'Shots', value: playerData['Shots'] },
                    { label: 'Shot%', value: playerData['Shot %'] },
                    { label: 'Shots/PG', value: playerData['Shots per game'] },
                    { label: 'On net%', value: playerData['Shots on net %'] },
                    { label: 'Dflcts', value: playerData['Deflections'] },
                    { label: 'Dflcts/PG', value: playerData['Deflections per game'] },
                    { label: 'Brkwy%', value: playerData['Breakaway %'] },
                    { label: 'PS%', value: playerData['PS %'] },

                ];

                PassingStats = [
                    { label: 'Passes', value: playerData['Passes'] },
                    { label: 'Attempts', value: playerData['Pass attempts'] },
                    { label: 'Passes/PG', value: playerData['Passes per game'] },
                    { label: 'Pass%', value: playerData['Pass %'] },
                    { label: 'Sauces', value: playerData['Saucer passes'] },
                    { label: 'Sauces/PG', value: playerData['Sauce per game'] },
                    { label: 'Gvwys', value: playerData['Giveaways'] },
                    { label: 'Gvwys/PG', value: playerData['Giveaways per game'] },
                ]

                XfactorZoneStats = [
                    { label: 'Goals', value: playerData['Xfactor zone goals'] },
                    { label: 'Goals/PG', value: (parseInt(playerData['Xfactor zone goals']) / parseInt(playerData['Games Played'])).toFixed(2) },
                    { label: 'Assists', value: playerData['Xfactor zone assists'] },
                    { label: 'Assists/PG', value: (parseInt(playerData['Xfactor zone assists']) / parseInt(playerData['Games Played'])).toFixed(2) },
                    { label: 'Hits', value: playerData['Xfactor zone hits'] },
                    { label: 'Hits/PG', value: (parseInt(playerData['Xfactor zone hits']) / parseInt(playerData['Games Played'])).toFixed(2) },
                    { label: 'Stick checks', value: playerData['Xfactor zone stick checks'] },
                    { label: 'Stick checks/PG', value: (parseInt(playerData['Xfactor zone stick checks']) / parseInt(playerData['Games Played'])).toFixed(2) },

                ]

                XfactorSuperStats = [
                    { label: 'Goals', value: playerData['Xfactor super-star goals'] },
                    { label: 'Goals/PG', value: (parseInt(playerData['Xfactor super-star goals']) / parseInt(playerData['Games Played'])).toFixed(2) },
                    { label: 'Assists', value: playerData['Xfactor super-star assists'] },
                    { label: 'Assists/PG', value: (parseInt(playerData['Xfactor super-star assists']) / parseInt(playerData['Games Played'])).toFixed(2) },
                    { label: 'Hits', value: playerData['Xfactor super-star hits'] },
                    { label: 'Hits/PG', value: (parseInt(playerData['Xfactor super-star hits']) / parseInt(playerData['Games Played'])).toFixed(2) },
                    { label: 'Stick checks', value: playerData['Xfactor super-star stick checks'] },
                    { label: 'Stick checks/PG', value: (parseInt(playerData['Xfactor super-star stick checks']) / parseInt(playerData['Games Played'])).toFixed(2) },

                ]


                statsSections = [
                    {
                        label: 'Offense',
                        content: OffensiveStats,
                    },
                    {
                        label: 'Defense',
                        content: DefensiveStats,
                    },
                    {
                        label: 'Teamplay',
                        content: TeamplayTotals,
                    },
                    {
                        label: 'Shooting',
                        content: ShootingStats,
                    },
                    {
                        label: 'Passing',
                        content: PassingStats,
                    },
                    {
                        label: 'X-factor (zone)',
                        content: XfactorZoneStats,
                    },
                    {
                        label: 'X-factor (superstar)',
                        content: XfactorSuperStats,
                    }

                ];
            } else if (positionState === 'goalie') {
                atAGlanceStats = [
                    { label: 'GP', value: playerData['Goalie games played'] },
                    { label: 'W', value: playerData['Goalie wins'] },
                    { label: 'SO', value: playerData['Shutouts'] },
                    { label: 'GAA', value: playerData['GAA'] },
                    { label: 'SV%', value: playerData['Save %'] },
                ];

                GoalieStatsShutouts = [
                    { label: 'SO periods', value: playerData['Shutout periods'] },
                    { label: 'SO periods/PG', value: playerData['Shutout periods per game'] },
                    { label: 'SO saves', value: playerData['Shutout saves'] },
                    { label: 'Saves/Per SO', value: (parseInt(playerData['Shutout saves']) / parseInt(playerData['Shutouts']).toFixed(2) || 0) },
                ]

                GoalieStatsShutouts2 = [
                    { label: 'Saves/Per SOP', value: (parseInt(playerData['Shutout saves']) / parseInt(playerData['Shutout periods']).toFixed(2) || 0) },
                    { label: 'SO shots', value: playerData['Shutout shots'] },
                    { label: 'Shots/Per SO', value: (parseInt(playerData['Shutout shots']) / parseInt(playerData['Shutouts']).toFixed(2) || 0) },
                    { label: 'Shots/Per SOP', value: (parseInt(playerData['Shutout shots']) / parseInt(playerData['Shutout periods']).toFixed(2) || 0) },

                ]

                GoalieStats = [
                    { label: 'Saves', value: playerData['Goalie saves'] },
                    { label: 'Saves/PG', value: playerData['Saves per game'] },
                    { label: 'Shots', value: playerData['Goalie shots'] },
                    { label: 'Shots/PG', value: (parseInt(playerData['Goalie shots']) / parseInt(playerData['Goalie games played'])).toFixed(2) },
                ]

                GoalieStats2 = [
                    { label: 'Brkwys', value: playerData['Breakaway shots against'] },
                    { label: 'Brkwy SV%', value: playerData['Breakaway save %'] },
                    { label: 'PSs', value: playerData['Penalty shots against'] },
                    { label: 'PS SV%', value: playerData['Penalty shot save %'] },
                ]

                GoalieStats3 = [
                    { label: 'Pokes', value: playerData['Goalie pokechecks'] },
                    { label: 'Pokes/PG', value: (parseInt(playerData['Goalie pokechecks']) / parseInt(playerData['Goalie games played'])).toFixed(2) },
                    { label: 'Dive saves', value: playerData['Diving saves'] },
                    { label: 'Dive SVs/PG', value: playerData['Diving saves per game'] },
                ]

                GoalieXfactorStats = [
                    { label: 'Zone saves', value: playerData['Xfactor zone ability saves'] },
                    { label: 'Zone saves/PG', value: (parseInt(playerData['Xfactor zone ability saves']) / parseInt(playerData['Goalie games played'])).toFixed(2) },
                    { label: 'Super-star saves', value: playerData['Xfactor superstar ability saves'] },
                    { label: 'Super-star saves/PG', value: (parseInt(playerData['Xfactor superstar ability saves']) / parseInt(playerData['Goalie games played'])).toFixed(2) },
                ]

                statsSections = [
                    {
                        label: 'Saves',
                        content: (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', minWidth: 'auto' }}>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {GoalieStats.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 1, paddingX: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {GoalieStats2.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 1, paddingX: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {GoalieStats3.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 1, paddingX: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        label: 'Shutouts',
                        content: (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', minWidth: 'auto' }}>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {GoalieStatsShutouts.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {GoalieStatsShutouts2.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        label: 'X-factor',
                        content: (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', minWidth: 'auto' }}>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {GoalieXfactorStats.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {DefensiveStats2.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', 'flexDirection': 'row' }}>
                                    {DefensiveStats3.map((stat, index) => (
                                        <React.Fragment key={stat.label}>
                                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                                            <Box sx={{ padding: 2, textAlign: 'center' }}>
                                                <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>
                                                    {stat.label}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '22px' }}>
                                                    {stat.value}
                                                </Typography>

                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Box>
                        ),
                    },
                ];
            }
            return (
                <Box>
                    <PlayerInfo jersey={jersey} clubInfo={clubInfo} playerData={playerData} crestID={playerData['[object Object]'].crestID} position={position} playstyle={playerData.overall_results.playstyle} skaterOverall={playerData.overall_results.overall_results["Overall Rating"]} goalieOverall={playerData.overall_results.goalie_overall_results["Overall Rating"]} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
                        <Typography variant="h6" sx={{ marginLeft: 1, color: 'white' }}>Overview</Typography>
                        <PositionToggle />
                    </Box>

                    <Divider sx={{ borderColor: '#424549', marginTop: 1, width: windowWidth < 600 ? '95%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />
                    <OverallRadarChart playerData={playerData} />

                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 3 }}>
                        <Divider sx={{ borderColor: '#424549', width: windowWidth < 600 ? '95%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />

                        <Typography variant="h6" sx={{ marginTop: 5, marginBottom: 1, marginLeft: 1, color: 'white', display: 'flex', width: '100%' }}>Last 5 games</Typography>
                        <ScrollableTable playerData={playerData}/>
                    </Box>

                    <Divider sx={{ borderColor: '#424549', marginTop: 7, width: windowWidth < 600 ? '95%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />
                    <Box elevation={3} sx={{ margin: 'auto', marginTop: 4, marginBottom: 5, maxWidth: windowWidth < 600 ? 500 : windowWidth < 900 ? '100%' : '100%', textAlign: 'center' }}>
                        <HockeyDataComponent playerStats={playerData} />
                    </Box>
                </Box>
            );
        } else if (freeAgentState === true) {
            const position = positionAbbreviations[playerData.Position] || playerData.Position;
            let atAGlanceStats = []
            let advancedStats = []
            let advancedStats2 = []
            const gamesPlayedObj = { 'C': parseInt(playerData['C Gps']), 'LW': parseInt(playerData['LW Gps']), 'RW': parseInt(playerData['RW Gps']), 'D': parseInt(playerData['D Gps']), 'G': parseInt(playerData['Goalie games played']) }

            if (positionState === 'skater') {
                atAGlanceStats = [
                    { label: 'GP', value: parseInt(playerData['C Gps'], 10) + parseInt(playerData['LW Gps'], 10) + parseInt(playerData['RW Gps'], 10) + parseInt(playerData['D Gps'], 10) },
                    { label: 'G', value: playerData['Goals'] },
                    { label: 'A', value: playerData['Assists'] },
                    { label: 'P', value: parseInt(playerData['Goals'], 10) + parseInt(playerData['Assists'], 10) },
                    { label: '+/-', value: playerData['+/-'] },
                    { label: 'PIM', value: playerData['PIM'] },
                ];

                advancedStats = [
                    { label: 'Goals per/g', value: playerData['Goals per game'] },
                    { label: 'Assists per/g', value: playerData['Assists per game'] },
                    { label: 'Points per/g', value: parseFloat((parseInt(playerData['Goals'], 10) + parseInt(playerData['Assists'], 10)) / (parseInt(playerData['C Gps'], 10) + parseInt(playerData['LW Gps'], 10) + parseInt(playerData['RW Gps'], 10) + parseInt(playerData['D Gps'], 10))).toFixed(2) },

                ];

                advancedStats2 = [
                    { label: '+/- per/g', value: playerData['+/- per game'] },
                    { label: 'Hits per/g', value: playerData['Hits per game'] },
                    { label: 'PIM per/g', value: playerData['PIMS per game'] },

                ];
            } else if (positionState === 'goalie') {
                atAGlanceStats = [
                    { label: 'GP', value: playerData['Goalie games played'] },
                    { label: 'SO', value: playerData['Shutouts'] },
                    { label: 'SOP', value: playerData['Shutout periods'] },
                    { label: 'GAA', value: playerData['GAA'] },
                    { label: 'SV%', value: playerData['Save %'] },
                ];

                advancedStats = [
                    { label: 'Saves per/g', value: playerData['Saves per game'] },
                    { label: 'SOs per/g', value: playerData['Shutouts per game'] },
                    { label: 'SOPs per/g', value: playerData['Shutout periods per game'] },
                ];
            }
            return (
                <Box>
                    <Box sx={{ height: 'fit-content', width: 'fit-content', alignItems: 'center', borderRadius: 50, backgroundColor: 'transparent', display: 'flex', flexWrap: 'wrap', color: 'white', marginTop: 3, justifyContent: 'center', paddingX: 5, marginX: 'auto', fontSize: 28 }}>
                        <h3>{playerData.Username}</h3>
                    </Box>
                    <Box sx={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.8)', borderRadius: 50, display: 'flex', flexWrap: 'wrap', color: 'white', marginX: 'auto', width: 'fit-content', gap: 1, paddingX: 6 }}>
                        <h3>{position}</h3>
                        <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'white', marginX: 'auto', marginY: 'auto', height: 30 }} />
                        <h3>FREE AGENT</h3>
                        <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'white', marginX: 'auto', marginY: 'auto', height: 30 }} />
                        <TeamLogo playerData={'t1000'} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Career stats</Typography>
                        <PositionToggle />
                    </Box>
                    <Divider sx={{ borderColor: '#424549', marginTop: 1.5 }} />
                    <Box elevation={3} sx={{ margin: 'auto', maxWidth: windowWidth < 600 ? '100%' : windowWidth < 900 ? 800 : 800, textAlign: 'center' }}>
                        <Typography variant="subtitle1" component="div" sx={{ color: 'white', marginTop: 2, padding: 1.5 }}>
                            Overview
                        </Typography>
                        <Typography sx={{ color: 'white' }}>Games played: {playerData['Games Played']}</Typography>
                        <PositionalProgressBar totalGames={parseInt(playerData['GPs'])} gamesByPosition={gamesPlayedObj} />
                        <Box sx={{ marginTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#171717', height: windowWidth < 600 ? 65 : windowWidth < 900 ? 110 : 110, borderRadius: 5, flexWrap: 'wrap' }}>
                            {atAGlanceStats.map((stat, index) => (
                                <React.Fragment key={stat.label}>
                                    {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: windowWidth < 600 ? 40 : windowWidth < 900 ? 55 : 55 }} />}
                                    <Box sx={{ padding: windowWidth < 600 ? 1.5 : windowWidth < 900 ? 3 : 3, textAlign: 'center' }}>
                                        <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: windowWidth < 600 ? '14px' : windowWidth < 900 ? '16px' : '16px' }}>
                                            {stat.label}
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: windowWidth < 600 ? '14px' : windowWidth < 900 ? '22px' : '22px' }}>
                                            {stat.value}
                                        </Typography>

                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                    <Box elevation={3} sx={{ margin: 'auto', maxWidth: windowWidth < 600 ? '100%' : windowWidth < 900 ? 800 : 800, textAlign: 'center', marginBottom: 3 }}>
                        <Typography variant="subtitle1" component="div" sx={{ color: 'white', marginTop: 2, padding: 1.5 }}>
                            Advanced stats
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#171717', height: windowWidth < 600 ? 65 : windowWidth < 900 ? 110 : 110, borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                            {advancedStats.map((stat, index) => (
                                <React.Fragment key={stat.label}>
                                    {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: windowWidth < 600 ? 40 : windowWidth < 900 ? 55 : 55 }} />}
                                    <Box sx={{ padding: windowWidth < 600 ? 1.5 : windowWidth < 900 ? 3 : 3, textAlign: 'center' }}>
                                        <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: windowWidth < 600 ? '14px' : windowWidth < 900 ? '16px' : '16px' }}>
                                            {stat.label}
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: windowWidth < 600 ? '14px' : windowWidth < 900 ? '22px' : '22px' }}>
                                            {stat.value}
                                        </Typography>

                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#171717', height: windowWidth < 600 ? 65 : windowWidth < 900 ? 110 : 110, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                            {advancedStats2.map((stat, index) => (
                                <React.Fragment key={stat.label}>
                                    {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: windowWidth < 600 ? 40 : windowWidth < 900 ? 55 : 55 }} />}
                                    <Box sx={{ padding: windowWidth < 600 ? 1.5 : windowWidth < 900 ? 3 : 3, textAlign: 'center' }}>
                                        <Typography variant="subtitle2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'white', fontSize: windowWidth < 600 ? '14px' : windowWidth < 900 ? '16px' : '16px' }}>
                                            {stat.label}
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: windowWidth < 600 ? '14px' : windowWidth < 900 ? '22px' : '22px' }}>
                                            {stat.value}
                                        </Typography>

                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                </Box>
            );
        }
    }
}

export default SetPlayer;