import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useStats } from '../../Contexts/StatsContext';
import { useNavigate } from 'react-router-dom';
const ScrollableTable = ({ playerData }) => {
    const { state } = useStats();
    const { positionState } = state;
    const recentGameStats = playerData.playerRecentData.playerGameStats
    const navigate = useNavigate();
    function convertSecondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
        const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60
        const sec = (seconds % 60).toFixed(); // remainder from minutes is seconds

        // Output as "HH:MM:SS"
        // Use .padStart(2, '0') to add leading zeroes if the numbers are less than 10
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = sec.toString().padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const positionMapping = {
        "5": "C",
        "4": "LW",
        "3": "RW",
        "2": "LD",
        "1": "RD",
        "0": "G"
    };

    const buildMapping = {
        "0": "GRN",
        "1": "PLY",
        "2": "SNP",
        "3": "PWF",
        "4": "TWF",
        "5": "ENF",
        "6": "DNG",
        "11": "DFD",
        "12": "OFD",
        "14": "TWD",
        "15": "PMD",
        "21": "HYB",
        "204": "W-TWF",
        "205": "S-ENF",
        "212": "A-OFD"
    };

    const handleRowClick = (matchID, gameType) => {
        // Redirect to a specific link (e.g., player details or match details)
        navigate(`/clubs/recent-games?teamname=${playerData['[object Object]'].teamName}&console=common-gen5&gameType=${gameType}&matchId=${matchID}`); // Navigate to game stats page
    };

    if (recentGameStats) {

        if (positionState === 'skater') {
            return (
                <TableContainer component={Paper} style={{ height: 'fit-content', overflow: 'auto', backgroundColor: 'transparent' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>Date</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>Opponent</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>Res</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>Pos</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>Class</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>RP</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>G</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>A</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>P</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>S</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>+/-</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>Poss</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>PIM</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>Hits</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>Ints</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>Tkws</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>Gvws</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recentGameStats
                                .filter(game => game != null)
                                .map((game, index) => {
                                    // Calculate the change in rankpoints
                                    const prevGame = recentGameStats[index + 1];
                                    const rankpointsChange = prevGame ? game.rankpoints - prevGame.rankpoints : 0;

                                    // Determine the indicator icon
                                    const indicator =
                                        rankpointsChange > 0
                                            ? '+' // Up arrow for increase
                                            : rankpointsChange < 0
                                                ? '-' // Down arrow for decrease
                                                : ''; // Dash for no change

                                    return (
                                        <TableRow key={game.gameInfo.matchID} onClick={() => handleRowClick(game.gameInfo.matchID, game.gameInfo.gameType)} sx={{ color: 'white', fontSize: '14px' }}>
                                            <TableCell align="left" sx={{ color: 'white', fontSize: '14px', width: '1%', whiteSpace: 'nowrap' }}>{game.gameInfo.date}</TableCell>
                                            <TableCell component="th" scope="row" sx={{ color: 'white', fontSize: '14px', minWidth: '165px' }}>
                                                {game.gameInfo.opponent}
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.gameInfo.result}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{positionMapping[game.posSorted]}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{buildMapping[game.class]}</TableCell>
                                            <TableCell align="left" sx={{ color: 'white', fontSize: '14px', textWrap: 'nowrap' }}>
                                                {game.rankpoints} &#8239;{rankpointsChange !== 0 && `(${indicator}${Math.abs(rankpointsChange)})`}
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skgoals}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skassists}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{parseInt(game.skgoals, 10) + parseInt(game.skassists, 10)}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skshots}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skplusmin}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{convertSecondsToHMS(game.skpossession)}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skpim}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skhits}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skinterceptions}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.sktakeaways}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.skgiveaways}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else if (positionState === 'goalie') {
            return (
                <TableContainer component={Paper} style={{ height: 'fit-content', overflow: 'auto', backgroundColor: 'transparent' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>Date</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>Opponent</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>Res</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>Pos</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '14px' }}>RP</TableCell>   
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>SA</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>GA</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>SV%</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>BRKSV</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>BRKSV%</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>PENSV</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>PENSV%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recentGameStats
                                .filter(game => game != null)
                                .map((game, index) => {
                                    // Calculate the change in rankpoints
                                    const prevGame = recentGameStats[index + 1];
                                    const rankpointsChange = prevGame ? game.rankpoints - prevGame.rankpoints : 0;

                                    // Determine the indicator icon
                                    const indicator =
                                        rankpointsChange > 0
                                            ? '+' // Up arrow for increase
                                            : rankpointsChange < 0
                                                ? '-' // Down arrow for decrease
                                                : ''; // Dash for no change
                                    return (

                                        <TableRow key={game.gameInfo.matchID} sx={{ color: 'white', fontSize: '14px' }}>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px', width: '1%', whiteSpace: 'nowrap' }}>{game.gameInfo.date}</TableCell>
                                            <TableCell component="th" scope="row" sx={{ color: 'white', fontSize: '14px', minWidth: '165px' }}>
                                                {game.gameInfo.opponent}
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.gameInfo.result}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.position}</TableCell>
                                            <TableCell align="left" sx={{ color: 'white', fontSize: '14px', textWrap: 'nowrap' }}>
                                                {game.rankpoints} &#8239;{rankpointsChange !== 0 && `(${indicator}${Math.abs(rankpointsChange)})`}
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.glshots}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.glga}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.glsavepct}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.glbrksaves}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.glbrksavepct}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.glpensaves}</TableCell>
                                            <TableCell align="right" sx={{ color: 'white', fontSize: '14px' }}>{game.glpensavepct}</TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    } else {
        return (
            <Typography sx={{ color: 'white', fontSize: '18px', textAlign: 'center', margin: 'auto', marginTop: 5 }}>No recent games found</Typography>
        )
    }
};

export default ScrollableTable;
