import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Tab, Tabs, Typography, Divider, Paper, Button } from '@mui/material';
import { useLoading } from '../../Contexts/LoadingContext';
import { useLocation } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import ScoringTimelineDisplay from './ScoringTimelineDisplay'
import ThreeStarsDisplay from './ThreeStarsDisplay'
import GameStats from './GameStats'
import GamePlayerStats from './GamePlayerStats'
import AdvancedStats from './AdvancedStats'
import cloneDeep from 'lodash.clonedeep';
import AdComponent from '../../Main Components/AdComponent';
import VignetteAdComponent from '../../Main Components/VignetteAdComponent';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import GameSelector from './GameSelector'
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            style={{ height: '100%', overflow: 'auto', width: '100%', minHeight: '200px' }} // You might merge this with `...other` if passing styles via props
        >
            {value === index && (
                <Box sx={{ marginTop: 4 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function RecentGame() {
    const [value, setValue] = React.useState('recap');
    const [clubData, setClubData] = useState(null);
    const [clubData2, setClubData2] = useState(null);

    const [teamID1, setTeamID1] = useState('');
    const [teamID2, setTeamID2] = useState('');
    const [team1Score, setTeam1Score] = useState('');
    const [team2Score, setTeam2Score] = useState('');
    const [winningTeamId, setWinningTeamId] = useState('');
    const [gameDate, setGameDate] = useState('');
    const dataFetched = useRef(false);
    const storyFetched = useRef(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const [storyLoading, setStoryLoading] = useState(true);

    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const [selectedGame, setSelectedGame] = useState(null);
    const [gamesObject, setGamesObject] = useState(null)
    const [generatedStory, setGeneratedStory] = useState('');
    const [gameObject, setGameObject] = useState('');

    const [scoringTimeline, setScoringTimeline] = useState('');
    const [threestars, setThreeStars] = useState('');

    const { loading, setLoading } = useLoading();
    const [gameType, setGameType] = useState(''); // default value
    const [imageUrls1, setImageUrls1] = useState('');
    const [imageUrls2, setImageUrls2] = useState('');

    const [currentImage1, setCurrentImage1] = useState(imageUrls1[0]);
    const [currentImage2, setCurrentImage2] = useState(imageUrls2[0]);
    const [isFetching, setIsFetching] = useState(false);
    const [gameData, setGameData] = useState('');
    const highlightStyle = {
        fontWeight: 'bold',
        color: 'white',
        fontSize: '36px',
        marginX: 3
    };
    const notHighlightStyle = {
        color: 'white', marginRight: 3, fontSize: '36px', marginX: 3
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleImageError = () => {
        const nextIndex = imageUrls1.indexOf(currentImage1) + 1;
        if (nextIndex < imageUrls1.length) {
            setCurrentImage1(imageUrls1[nextIndex]);
        }
    };
    const handleImageError2 = () => {
        const nextIndex = imageUrls2.indexOf(currentImage2) + 1;
        if (nextIndex < imageUrls2.length) {
            setCurrentImage2(imageUrls2[nextIndex]);
        }
    };
    useEffect(() => {
        if (clubData?.teamData) {
            document.title = `Chel stats | Recent game | ${clubData.teamData.name}`;
        }
    }, []);

    useEffect(() => {
        const fetchClubData = async () => {
            setLoading(true);
            const queryParams = new URLSearchParams(location.search);
            const teamNameParam = queryParams.get('teamname');
            const consoleGenParam = queryParams.get('console');
            const gameTypeParam = queryParams.get('gameType');
            setGameType(gameTypeParam)
            const matchIdParam = queryParams.get('matchId');
            let apiUrl = '/api/clubs/stats';
            let queryParts = [];

            if (teamNameParam) {
                queryParts.push(`teamname=${encodeURIComponent(teamNameParam)}`);
            }
            if (consoleGenParam) {
                queryParts.push(`console=${encodeURIComponent(consoleGenParam)}`);
            }

            if (queryParts.length) {
                apiUrl += `?${queryParts.join('&')}`;
            }

            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setClubData(data);
                setTeamID1(data.teamData.clubId)
                if (matchIdParam) {
                    const foundGame = data.recentGames[gameTypeParam].find(game => game.matchId === matchIdParam);
                    if (foundGame) {
                        setSelectedGame(foundGame);
                        setGamesObject(data.recentGames[gameTypeParam])
                    } else {
                        console.error("Match ID not found in the club's recent games");
                    }
                }
            } catch (error) {
                console.error('Failed to fetch club data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (location.search && !dataFetched.current) {
            fetchClubData();
            dataFetched.current = true;
        }
    }, [location.search, setLoading]);

    useEffect(() => {

        const fetchClubDatas = async (selectedGame) => {

            const consoleGenParam = 'common-gen5'

            let queryParts = [];
            let apiUrl = '/api/clubs/stats';

            queryParts.push(`teamname=${encodeURIComponent(selectedGame.clubs[teamID2].details.name)}`);
            queryParts.push(`console=${encodeURIComponent(consoleGenParam)}`);

            if (queryParts.length) {
                apiUrl += `?${queryParts.join('&')}`;
            }

            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setClubData2(data);

            } catch (error) {
                console.error('Failed to fetch club data:', error);
            }


        };

        if (selectedGame) {
            const clubIds = Object.keys(selectedGame.clubs);
            const id1 = teamID1;
            const id2 = clubIds.find(id => id !== id1);
            const team1 = selectedGame.clubs[id1] || {};
            const team2 = selectedGame.clubs[id2] || {};
            setTeamID2(id2);
            setTeam1Score(team1.score);
            setTeam2Score(team2.score);
            setWinningTeamId(parseInt(team1.score) > parseInt(team2.score) ? id1 : parseInt(team2.score) > parseInt(team1.score) ? id2 : null);

            const crestID = team1?.details?.customKit?.crestAssetId
            let imageUrls1 = []

            if (crestID) {
                imageUrls1 = [
                    team1.details.customKit.useBaseAsset === "1" ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png` : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
                    'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
                ];
            } else {
                imageUrls1 = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
            }

            setImageUrls1(imageUrls1)
            setCurrentImage1(imageUrls1[0])
            const crestID2 = team2.details.customKit.crestAssetId;
            let imageUrls2 = []
            if (crestID2) {
                imageUrls2 = [
                    team2.details.customKit.useBaseAsset === "1" ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID2}.png` : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID2}.png`,
                    'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
                ];
            } else {
                imageUrls2 = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
            }

            setImageUrls2(imageUrls2)
            setCurrentImage2(imageUrls2[0])

            let timestamp = selectedGame.timestamp;

            let date = new Date(timestamp * 1000);
            let formatter = new Intl.DateTimeFormat('en-US', {
                month: 'short',   // e.g., Jan, Feb, Mar, ...
                day: '2-digit',   // e.g., 01, 02, 03, ..., 31
                hour: '2-digit',  // e.g., 01, 02, ..., 12, 13, ..., 23, 24
                minute: '2-digit', // e.g., 00, 01, ..., 59 // e.g., 00, 01, ..., 59
                hour12: true,
                timeZoneName: 'short'   // 12-hour time format, set to false for 24-hour format
            });

            // Format the date to the desired string
            let formattedDate = formatter.format(date);
            setGameDate(formattedDate)
        }

        if (selectedGame && !isFetching && teamID2) {
            setIsFetching(true);
            fetchClubDatas(selectedGame).then(() => {
                setIsFetching(false);
            });
        }
    }, [selectedGame, teamID1, teamID2, setTeamID1, setTeamID2]);

    const prepareGameObject = async (selectedGame) => {
        if (!selectedGame) return null;
        try {
            let gameObject = {}
            let gameToChange = selectedGame
            let clubIds = Object.keys(gameToChange.clubs);
            let id1 = clubIds[0];
            let id2 = clubIds.find(id => id !== id1);
            let team1 = gameToChange.clubs[id1];
            let team2 = gameToChange.clubs[id2];
            let endedIn = 'Regulation'

            gameObject[team1.details.name] = {}
            gameObject[team2.details.name] = {}
            gameObject[team1.details.name]['teamID'] = id1
            gameObject[team2.details.name]['teamID'] = id2
            gameObject[team1.details.name]['Score'] = team1.score
            gameObject[team2.details.name]['Score'] = team2.score
            gameObject[team1.details.name]['player_stats'] = {}
            gameObject[team2.details.name]['player_stats'] = {}

            let statsList = ['playername', 'position', 'skgoals', 'skassists', 'ratingDefense', 'ratingOffense', 'ratingTeamplay', 'skgwg', 'skhits', 'skinterceptions', 'sktakeaways', 'skpim', 'glga', 'glshots','glgaa', 'glsaves', 'glsavepct']

            for (let player in gameToChange.players[id1]) {
                let PlayerName = gameToChange.players[id1][player]['playername']
                gameObject[team1.details.name]['player_stats'][PlayerName] = {}
                for (let stat in statsList) {
                    let Stat = gameToChange.players[id1][player][statsList[stat]]
                    gameObject[team1.details.name]['player_stats'][PlayerName][statsList[stat]] = Stat
                }

                if (parseInt(gameToChange.players[id1][player].toi) > 60) {
                    endedIn = 'Overtime'
                }

                gameObject[team1.details.name]['player_stats'][PlayerName].teamID = id1
            }

            for (let player in gameToChange.players[id2]) {
                let PlayerName = gameToChange.players[id2][player]['playername']
                gameObject[team2.details.name]['player_stats'][PlayerName] = {}
                for (let stat in statsList) {
                    let Stat = gameToChange.players[id2][player][statsList[stat]]
                    gameObject[team2.details.name]['player_stats'][PlayerName][statsList[stat]] = Stat
                }

                if (parseInt(gameToChange.players[id2][player].toi) > 60) {
                    endedIn = 'Overtime'
                }

                gameObject[team2.details.name]['player_stats'][PlayerName].teamID = id2

            }

            gameObject['endedIn'] = endedIn

            let timestamp = selectedGame.timestamp;

            let date = new Date(timestamp * 1000);
            let formatter = new Intl.DateTimeFormat('en-US', {
                month: 'short',   // e.g., Jan, Feb, Mar, ...
                day: '2-digit',   // e.g., 01, 02, 03, ..., 31
                hour: '2-digit',  // e.g., 01, 02, ..., 12, 13, ..., 23, 24
                minute: '2-digit', // e.g., 00, 01, ..., 59 // e.g., 00, 01, ..., 59
                hour12: true     // 12-hour time format, set to false for 24-hour format
            });

            // Format the date to the desired string
            let formattedDate = formatter.format(date);
            gameObject['timestamp'] = formattedDate
            return gameObject
        } catch (error) {
            console.error('Error generating the game:', error);
        }
    };


    const simulateScoringTimeline = async (gameObject) => {
        if (!gameObject) return null;

        const generateTimestampAndPeriod = (isOvertime) => {
            const minute = Math.floor(Math.random() * (isOvertime ? 5 : 20)).toString().padStart(2, '0');
            const second = Math.floor(Math.random() * 60).toString().padStart(2, '0');
            const timestamp = `${minute}:${second}`;
            let periodNumber = isOvertime ? 'OT' : Math.ceil(Math.random() * 3).toString();
            return { timestamp, periodNumber };
        };

        let scoringTimeline = [];
        const clonedGameObject = cloneDeep(gameObject);  // Using lodash's cloneDeep to ensure a full deep clone

        const processTeam = (teamName, teamData, isOvertime) => {
            Object.entries(teamData.player_stats).forEach(([playerName, stats]) => {
                const goals = parseInt(stats.skgoals);
                const gwg = parseInt(stats.skgwg);

                for (let i = 0; i < goals; i++) {
                    const { timestamp, periodNumber } = generateTimestampAndPeriod(isOvertime && gwg > 0 && i === goals - 1);

                    let assistPlayers = [];
                    Object.entries(teamData.player_stats).forEach(([assistPlayerName, assistStats]) => {
                        if (assistPlayerName !== playerName && parseInt(assistStats.skassists) > 0 && assistPlayers.length < 2) {
                            assistPlayers.push(assistPlayerName);
                            assistStats.skassists = parseInt(assistStats.skassists) - 1;
                        }
                    });

                    scoringTimeline.push({
                        timestamp,
                        periodNumber,
                        teamName,
                        scorer: playerName,
                        assists: assistPlayers,
                        teamID: stats.teamID
                    });

                    if (isOvertime && gwg > 0 && i === goals - 1) break;
                }
            });
        };

        // Process both teams
        Object.keys(clonedGameObject).filter(key => clonedGameObject[key].teamID).forEach(teamKey => {
            processTeam(teamKey, clonedGameObject[teamKey], clonedGameObject.endedIn === 'Overtime');
        });

        // Sort by period and then timestamp
        scoringTimeline.sort((a, b) => {
            if (a.periodNumber === 'OT' && b.periodNumber !== 'OT') return 1;
            if (b.periodNumber === 'OT' && a.periodNumber !== 'OT') return -1;
            if (a.periodNumber === b.periodNumber) return a.timestamp.localeCompare(b.timestamp);
            return parseInt(a.periodNumber) - parseInt(b.periodNumber);
        });

        return scoringTimeline;
    };


    const calculateThreeStars = async (gameObject) => {
        if (!gameObject) return null;
        let playerPerformances = [];

        // Define weights for different stats
        const weights = {
            skgoals: 3,    // Goals are weighted more heavily
            skassists: 2,  // Assists have a moderate weight
            skhits: .4,     // Hits are considered less important but still contribute
            sktakeaways: .4,
            skinterceptions: .4,
            ratingOffense: .07,
            ratingDefense: .07,
            ratingTeamplay: .07,
            skgwg: 5,
            glgaa: -12,
            glsaves: 2,
            glsavepct: 15
            // Takeaways are considered equally to hits
            // Add more weights for other stats as needed
        };


        // Combine player stats from both teams
        Object.entries(gameObject).forEach(([teamName, teamData]) => {
            if (teamName === 'endedIn' || teamName === 'timestamp') return; // Skip non-team properties

            Object.entries(teamData.player_stats).forEach(([playerName, stats]) => {
                let performanceScore = 0;
                // Calculate weighted score
                Object.keys(stats).forEach(statKey => {
                    if (weights[statKey]) {
                        performanceScore += stats[statKey] * weights[statKey];
                    }
                });

                playerPerformances.push({
                    teamName,
                    playerName,
                    performanceScore,
                    ...stats,
                    position: stats['position'] // Include other stats for potential tie-breakers or detailed analysis
                });
            });
        });

        // Sort by performanceScore in descending order
        playerPerformances.sort((a, b) => b.performanceScore - a.performanceScore);

        // Return the top three players as the three stars of the game
        return playerPerformances.slice(0, 3).map((player, index) => ({
            position: player.position,
            star: index + 1,
            playerName: player.playerName,
            teamName: player.teamName,
            performanceScore: player.performanceScore,
            goals: player.skgoals,
            assists: player.skassists,
            pim: player.skpim,
            saves: player.glsaves,
            savepct: player.glsavepct,
            ga: player.glga,
            sa: player.glshots,
            off: player.ratingOffense,
            def: player.ratingDefense,
            team: player.ratingTeamplay
            // Include additional stats as needed
        }));
    };

    const calculateGameStats = async (gameData) => {
        const weights = {
            "ratingDefense": 0,
            "ratingOffense": 0,
            "ratingTeamplay": 0,
            "skpasspct": .3,
            "skshots": 5,
            "skfow": 1,
            "skpossession": 0.01,
            "skshotattempts": 1.8,
            "sktakeaways": 3,
            "skinterceptions": 3,
            "skpasses": .4,
            "skpim": -10,
            "skhits": .3
        };

        let teamScores = {};
        let team1 = "";
        let team2 = "";
        let team1ID, team2ID;
        teamScores["game1"] = {};

        const aggregate = gameData['aggregate'];
        for (const teamId in aggregate) {
            aggregate[teamId]['skpasspct'] = `${aggregate[teamId]['skpasspct'] / 5}`;
            let teamScore = Object.keys(weights).reduce((score, stat) => {
                return score + (aggregate[teamId][stat] ? parseFloat(aggregate[teamId][stat]) * weights[stat] : 0);
            }, 0);

            teamScore += parseInt(gameData['clubs'][teamId]['toa']) * .2;
            teamScore += parseInt(gameData['clubs'][teamId]['score']) * 60;
            teamScores["game1"][teamId] = teamScore;

            if (!team1) {
                team1 = gameData['clubs'][teamId]?.details?.name;
                team1ID = gameData['clubs'][teamId]?.details?.clubId;
            } else {
                try {
                    team2 = gameData['clubs'][teamId]?.details?.name;
                } catch {
                    team2 = "Unknown club"
                }

                try {
                    team2ID = gameData['clubs'][teamId]?.details?.clubId;;
                } catch {
                    for (let x in gameData['clubs']) {
                        if (x != team1ID) {
                            team2ID = x
                        }
                    }
                }
            }
        }

        const deservedWinnerId = Object.keys(teamScores["game1"]).reduce((a, b) => teamScores["game1"][a] > teamScores["game1"][b] ? a : b);
        const deservedLoserId = Object.keys(teamScores["game1"]).reduce((a, b) => teamScores["game1"][a] < teamScores["game1"][b] ? a : b);

        try {
            var deservedWinnerName = gameData['clubs'][deservedWinnerId]['details']['name'];
        } catch {
            var deservedWinnerName = "Unknown club"
        }

        try {
            var deservedLoserName = gameData['clubs'][deservedLoserId]['details']['name'];
        } catch {
            var deservedLoserName = "Unknown club"
        }

        var totalScore = teamScores["game1"][deservedWinnerId] + teamScores["game1"][deservedLoserId];
        var team1Score = Math.min(Math.max(Math.round((teamScores["game1"][team1ID] / totalScore) * 100), 0), 100);
        var team2Score = Math.min(Math.max(Math.round((teamScores["game1"][team2ID] / totalScore) * 100), 0), 100);

        let deservedToWinString = "Evenly played match!";
        if (deservedWinnerName !== deservedLoserName) {
            if (team1Score > team2Score) {
                deservedToWinString = `Team ${team1} deserved to beat ${team2} with a score of ${team1Score}% to ${team2Score}%`;
            } else {
                deservedToWinString = `Team ${team2} deserved to beat ${team1} with a score of ${team2Score}% to ${team1Score}%`;
            }
        }


        try {
            var team2name = gameData['clubs'][team2ID]['details']['name']
        } catch {
            var team2name = "Unknown club"
        }

        try {
            var team2AssetId = gameData['clubs'][team2ID]['details']['customKit']['crestAssetId']
        } catch {
            var team2AssetId = "t1000"
        }
        // Construct the new game data
        let newGameData = {
            "deserved_winner": deservedWinnerName,
            "deserved_winner_score": team1Score,
            "deserved_loser": deservedLoserName,
            "deserved_loser_score": team2Score,
            "deserved_to_win_string": deservedToWinString,
            "game_stats": {
                "select_string": `${team1} ${gameData['clubs'][team1ID]['scoreString']} ${team2} (${gameData['timeAgo']['number']} ${gameData['timeAgo']['unit']} ago)`,
                "team1": Object.fromEntries(Object.entries(weights).map(([key]) => [key, gameData['aggregate'][team1ID][key]])),
                "team2": Object.fromEntries(Object.entries(weights).map(([key]) => [key, gameData['aggregate'][team2ID][key]])),
                "team1_deserved_score": team1Score,
                "team2_deserved_score": team2Score,
                "team1_name": gameData['clubs'][team1ID]['details']['name'],
                "team2_name": team2name,
                "team1_crestId": gameData['clubs'][team1ID]['details']['customKit']['crestAssetId'],
                "team2_crestId": team2AssetId,
                "team1_toa": gameData['clubs'][team1ID]['toa'],
                "team2_toa": gameData['clubs'][team2ID]['toa'],
                "team1_goals": gameData['clubs'][team1ID]['score'],
                "team2_goals": gameData['clubs'][team2ID]['score'],
                "team1_ID": team1ID,
                "team2_ID": team2ID,
                "team1_Avg_RP": (parseInt(gameData['aggregate'][team1ID]['rankpoints']) / Object.keys(gameData['players'][team1ID]).length),
                "team2_Avg_RP": (parseInt(gameData['aggregate'][team2ID]['rankpoints']) / Object.keys(gameData['players'][team2ID]).length),
            }
        };

        if (gameData['clubs'][team1ID]['passa'] === '0' && gameData['clubs'][team2ID]['passa'] === '0' || gameData['clubs'][team1ID]['winnerByDnf'] === "1" || gameData['clubs'][team2ID]['winnerByDnf'] === "1") {
            newGameData["game_stats"]["DNF"] = "True";
        } else {
            newGameData["game_stats"]["DNF"] = "False";
        }

        return newGameData;
    }

    useEffect(() => {
        // This function should be defined within the useEffect or outside the component
        const fetchGameStory = async (game) => {
            if (selectedGame) {
                setStoryLoading(true);
                try {
                    const gameObject = await prepareGameObject(game);

                    const response = await fetch('/api/generate-story', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ gameData: gameObject }),
                    });
                    const data = await response.json();
                    if (!response.ok) throw new Error('Failed to fetch the game story');
                    setGeneratedStory(data.story); // Adjust according to the actual response structure
                } catch (error) {
                    console.error('Error fetching the game story:', error);
                } finally {
                    setStoryLoading(false);
                }
            }
        };

        if (selectedGame && !storyFetched.current) {
            fetchGameStory(selectedGame);
            storyFetched.current = true;
        }
    }, [selectedGame, setLoading]);

    useEffect(() => {
        if (!selectedGame) return;

        const prepareAndSimulate = async () => {
            const gameObjectPrepared = await prepareGameObject(selectedGame);
            setGameObject(gameObjectPrepared); // Set gameObject in state

            const timeline = await simulateScoringTimeline(gameObjectPrepared); // Use the newly prepared gameObject
            setScoringTimeline(timeline); // Set the scoring timeline in state

            const threestars = await calculateThreeStars(gameObjectPrepared)
            setThreeStars(threestars)

            const gameData = await calculateGameStats(selectedGame)
            setGameData(gameData)
        };

        prepareAndSimulate();
    }, [selectedGame]);

    const handleGoToClub = (teamname, consolegen) => {

        let targetUrl = `/clubs?teamname=${teamname}&console=${consolegen}`;


        navigate(targetUrl, { replace: true });

    };

    function getFirstThreeLetters(str) {
        if (str != undefined) {
            // Remove all spaces from the input string
            const noSpaces = str.replace(/\s+/g, '');

            // Return the first three characters of the resulting string
            return noSpaces.substring(0, 3);
        } else {
            return ""
        }
    }

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}><PropagateLoader color="#3498db" /></div>;
    }

    if (clubData) {
        return (
            <Box sx={{ marginBottom: 5, width: windowWidth < 600 ? '98%' : windowWidth < 900 ? '60%' : '60%', marginX: 'auto' }}>
                {selectedGame && (
                    <>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: 15, marginTop: 4, marginLeft: 1.5 }}>{gameDate}</Typography>
                        {/* <GameSelector games={gamesObject} gameType={gameType}/> */}
                        <Container sx={{ overflow: 'clip', position: 'relative', marginTop: 2, borderRadius: 2, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: 0, height: '145px', marginX: 'auto' }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 1, overflow: 'clip', '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: -20,
                                    right: 0,
                                    bottom: 0,
                                    backgroundImage: `url(${currentImage1})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'left',
                                    backgroundSize: '42%',
                                    opacity: 0.13,
                                    zIndex: 2,
                                }
                            }}>
                                <img src={currentImage1} onError={handleImageError} alt="" style={{ display: 'none' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 22, fontWeight: 'bold' }}>{(getFirstThreeLetters(selectedGame?.clubs[teamID1]?.details?.name) || "Team1").toUpperCase() || 'Team 1'}</Typography>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 14 }}>SOG: {selectedGame.clubs[teamID1]?.shots || 0}</Typography>
                                </Box>
                                <Typography noWrap sx={teamID1 === winningTeamId ? highlightStyle : notHighlightStyle}>{selectedGame.clubs[teamID1]?.score || '0'}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                <Typography noWrap sx={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>FINAL</Typography>
                                <Typography noWrap sx={{ color: 'white', fontSize: '18px' }}>{gameObject.endedIn === "Regulation" ? "" : "OT"}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 1, overflow: 'clip', '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: -20,
                                    bottom: 0,
                                    backgroundImage: `url(${currentImage2})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'right',
                                    backgroundSize: '42%',
                                    opacity: 0.13,
                                    zIndex: 2,
                                }
                            }}>
                                <img src={currentImage2} onError={handleImageError2} alt="" style={{ display: 'none' }} />
                                <Typography noWrap sx={teamID2 === winningTeamId ? highlightStyle : notHighlightStyle}>{selectedGame.clubs[teamID2]?.score || '0'}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: 2 }}>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 22, fontWeight: 'bold' }}>{(getFirstThreeLetters(selectedGame?.clubs[teamID2]?.details.name) || "Team2").toUpperCase() || 'Team 2'}</Typography>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 14 }}>SOG: {selectedGame.clubs[teamID2]?.shots || 0}</Typography>
                                </Box>
                            </Box>
                        </Container>
                        <Box sx={{ marginLeft: 2 }}>
                            <Button
                                sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                                onClick={() => handleGoToClub(selectedGame.clubs[teamID1].details.name, queryParams.get('console'))}
                            >
                                {selectedGame.clubs[teamID1]?.details.name || ""}
                            </Button>
                            <Button
                                sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                                onClick={() => handleGoToClub(selectedGame.clubs[teamID2].details.name, queryParams.get('console'))}
                            >
                                {selectedGame.clubs[teamID2]?.details.name || ""}
                            </Button>
                        </Box>

                        <Container sx={{ marginTop: 3, borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="stats tabs" variant="scrollable">
                                <Tab key='recap' label='Recap' value='recap' sx={{ color: 'white' }} />
                                <Tab key='game stats' label='Game stats' value='game stats' sx={{ color: 'white' }} />
                                <Tab key='player stats' label='Player stats' value='player stats' sx={{ color: 'white' }} />
                                <Tab key='advanced stats' label='Advanced stats' value='advanced stats' sx={{ color: 'white' }} />
                            </Tabs>
                        </Container>
                        <TabPanel value={value} index='recap'>
                            <Typography sx={{ color: 'white', marginTop: 3, marginBottom: 2, marginLeft: 2, fontSize: '24px', fontWeight: 'bold' }}>Post-game report</Typography>

                            <Paper
                                elevation={3}
                                sx={{
                                    padding: 1,
                                    color: 'white',
                                    marginTop: 2,
                                    margin: 1,
                                    backgroundColor: '#171717',
                                    boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: '100px', // Ensure a minimum height while loading
                                }}
                            >
                                {storyLoading ? (
                                    <CircularProgress color="primary" />
                                ) : (
                                    <Typography>{generatedStory?.content}</Typography>
                                )}
                            </Paper>
                            <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 5, width: windowWidth < 600 ? '95%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />
                            {/* <AdComponent /> */}
                            <Box sx={{ marginTop: 3 }}>
                                <Typography sx={{ color: 'white', marginTop: 3, marginBottom: 2, marginLeft: 2, fontSize: '24px', fontWeight: 'bold' }}>Goals <Typography component="span" sx={{ color: 'grey.500' }}>(simulated)</Typography></Typography>
                                <ScoringTimelineDisplay scoringTimeline={scoringTimeline}
                                    teamID1={teamID1}
                                    teamID2={teamID2}
                                    imageUrls1={imageUrls1}
                                    imageUrls2={imageUrls2} />
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 5, width: windowWidth < 600 ? '95%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />
                                <Typography sx={{ color: 'white', marginTop: 3, marginBottom: 1, marginLeft: 2, fontSize: '24px', fontWeight: 'bold' }}>Three stars</Typography>
                                {/* <AdComponent slotID={"7688723993"} /> */}
                                <ThreeStarsDisplay stars={threestars} />
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index='game stats'>
                            {/* <AdComponent slotID={"7688723993"} /> */}
                            <GameStats gameData={gameData} />
                        </TabPanel>
                        <TabPanel value={value} index='player stats'>
                            <GamePlayerStats rawGameStats={selectedGame} teamID1={teamID1} teamID2={teamID2} />
                            {/* <AdComponent slotID={"7688723993"} /> */}
                        </TabPanel>
                        <TabPanel value={value} index='advanced stats'>
                            <AdvancedStats rawGameStats={selectedGame} teamID1={teamID1} teamID2={teamID2} clubData1={clubData} clubData2={clubData2} />
                        </TabPanel>
                    </>
                )}
            </Box>
        );
    }
}

export default RecentGame;
