import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Select, MenuItem, InputLabel, Paper, InputAdornment, IconButton, Switch, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { useLoading } from '../Contexts/LoadingContext';

const SearchAndDropdownCombined = ({where}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchType, setSearchType] = useState('players'); // Changed default to 'players'
    const [teamname, setTeamName] = useState('');
    const [username, setUsername] = useState('');
    const [consolegen, setConsolegen] = useState('common-gen5');
    const [error, setError] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { loading, setLoading } = useLoading();
    const [isClubSearch, setIsClubSearch] = useState(false); // State for toggle

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setTeamName(queryParams.get('teamname') || '');
        setUsername(queryParams.get('username') || '');
        setConsolegen(queryParams.get('console') || 'common-gen5');

        // Determine search type based on the current path
        const currentPath = location.pathname;
        let defaultSearchType = 'players'; // Default to players
        if (currentPath.includes('/clubs')) {
            defaultSearchType = 'clubs';
        }
        setSearchType(defaultSearchType);
        setIsClubSearch(defaultSearchType === 'clubs');
    }, [location.search, location.pathname]);

    const handleSearchType = (event, newSearchType) => {
        if (newSearchType !== null) {
            setSearchType(newSearchType);
            setIsClubSearch(newSearchType === 'clubs');
            // Update URL based on selected search type
        }
    };

    const handleTeamNameChange = (event) => setTeamName(event.target.value);
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        if (event.target.value) {
            setError(''); // Clear error when user starts typing
        }
    };

    const handleButtonClick = () => {
        setLoading(true);
        if (searchType === 'clubs') {
            let targetUrl = `/clubs?teamname=${teamname.trim()}&console=${consolegen}`;
            const fullUrl = window.location.origin + targetUrl;
            if (window.location.href === fullUrl) {
                window.location.reload();
            } else {
                window.location.replace(fullUrl);
            }
        } else if (searchType === 'players') {
            if (!username.trim()) {
                setError('Gamertag is required');
                return;
            }
            let targetUrl = `/players?username=${username.trim()}&console=${consolegen}`;
            if (teamname) {
                targetUrl += `&teamname=${teamname.trim()}`;
            } else {
                targetUrl += `&freeAgent=True`;
            }
            const fullUrl = window.location.origin + targetUrl;
            if (window.location.href === fullUrl) {
                window.location.reload();
            } else {
                window.location.replace(fullUrl);
            }
        }
    };

    const handleToggle = (event) => {
        setIsClubSearch(event.target.checked);
        setSearchType(event.target.checked ? 'clubs' : 'players');
        // Clear fields when toggling to avoid confusion
        if (event.target.checked) {
            setUsername('');
        } else {
            setTeamName('');
        }
        setError(''); // Clear any errors when switching mode
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', margin: 'auto', marginTop: 3 }}>
            {/* Toggle between search types */}
            <Box display="flex" alignItems="center" marginBottom={1}>
                <Paper elevation={3} sx={{ bgcolor: where == 'accordion' ? '#121212' : '#1f1f1f', position: 'relative', borderRadius: '20px', overflow: 'hidden', height: '30px', width: '260px' }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '50%',
                            height: '100%',
                            bgcolor: '#1976d2',
                            transform: searchType === 'players' ? 'translateX(0)' : 'translateX(100%)',
                            transition: 'transform 0.3s ease-in-out',
                            borderRadius: '20px',
                            textAlign: 'center',
                            
                        }}
                    />
                    <ToggleButtonGroup
                        value={searchType}
                        exclusive
                        onChange={handleSearchType}
                        sx={{
                            '& .MuiToggleButtonGroup-grouped': {
                                border: 0,
                                borderRadius: '20px',
                                width: '130px',
                                height: '30px'
                            },
                        }}
                    >
                        <ToggleButton value="players" sx={{ color: 'white', zIndex: 1, bgcolor: 'transparent', fontSize: '12px' }}>
                            Player search
                        </ToggleButton>
                        <ToggleButton value="clubs" sx={{ color: 'white', zIndex: 1, bgcolor: 'transparent', fontSize: '12px' }}>
                            Club search
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Paper>
            </Box>

            <Box sx={{ display: 'flex', flexWrap: windowWidth < 600 ? 'wrap' : 'nowrap', gap: 1, alignItems: 'center', width: '100%' }}>
                {!isClubSearch && (
                    <TextField
                        label={error ? error : "Gamertag"}
                        variant="outlined"
                        value={username}
                        onChange={handleUsernameChange}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleButtonClick();
                            }
                        }}
                        error={!!error}
                        sx={{
                            
                            width: '48.5%',
                            input: {
                                color: 'white',
                                '&:-webkit-autofill': {
                                    WebkitTextFillColor: 'white !important',
                                    transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
                                    WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                                }
                            },
                            label: { color: error ? 'red' : 'gray' }, // Change label color to red if error exists
                            '& .MuiOutlinedInput-root': {
                               
                                '& fieldset': { borderColor: error ? 'red' : 'white' }, // Red border on error
                                '&:hover fieldset': { borderColor: error ? 'red' : 'white' },
                                '&.Mui-focused fieldset': { borderColor: error ? 'red' : 'white' },
                            },
                            '& .MuiInputLabel-root.Mui-focused': { color: error ? 'red' : 'white' } // Change label color when focused
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setUsername('')} sx={{ color: 'white' }}>
                                        {username ? <ClearIcon /> : null}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}

                <TextField
                    label={isClubSearch ? "Club name" : "Club name (optional)"}
                    variant="outlined"
                    value={teamname}
                    onChange={handleTeamNameChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleButtonClick();
                        }
                    }}
                    sx={{
                        
                        width: !isClubSearch ? '48.5%' : '99.2%',
                        input: {
                            color: 'white',
                            '&:-webkit-autofill': {
                                WebkitTextFillColor: 'white !important',
                                transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
                                WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                            }
                        },
                        label: { color: 'gray' },
                        '& .MuiOutlinedInput-root': {
                            
                            '& fieldset': { borderColor: 'white' },
                            '&:hover fieldset': { borderColor: 'white' },
                            '&.Mui-focused fieldset': { borderColor: 'white' },
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setTeamName('')} sx={{ color: 'white' }}>
                                    {teamname ? <ClearIcon /> : null}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    sx={{ width: windowWidth < 600 ? '100%' : '20%', height: windowWidth < 600 ? 'unset' : '50px' }}
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                >
                    Search
                </Button>
            </Box>
        </Box>
    );
};

export default SearchAndDropdownCombined;