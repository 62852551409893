import React, { useRef, useEffect, useState } from 'react';
import { Box, Paper, Typography, Grid, Card, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function GameTile({ game, id, team, consolegen, gametype }) {
    let team1Id = id
    let team2Id

    for (let clubId in game['clubs']) {
        if (clubId != team1Id) {
            team2Id = clubId;
        }
    }

    let imageUrls1 = [];
    const crestID = game.clubs[team1Id].details.customKit.crestAssetId
    if (crestID) {
        imageUrls1 = [
            game.clubs[team1Id].details.customKit.useBaseAsset === "1" ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png` : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
        ];
    } else {
        imageUrls1 = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
    }

    const [currentImage1, setCurrentImage1] = useState(imageUrls1[0]);

    const handleImageError = () => {
        const nextIndex = imageUrls1.indexOf(currentImage1) + 1;
        if (nextIndex < imageUrls1.length) {
            setCurrentImage1(imageUrls1[nextIndex]);
        }
    };

    let imageUrls2 = [];
    const crestID2 = game.clubs[team2Id]?.details?.customKit?.crestAssetId;
    if (crestID2) {
        imageUrls2 = [
            game.clubs[team2Id].details.customKit.useBaseAsset === "1" ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID2}.png` : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID2}.png`,
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
        ];
    } else {
        imageUrls2 = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
    }

    const [currentImage2, setCurrentImage2] = useState(imageUrls2[0]);

    const handleImageError2 = () => {
        const nextIndex = imageUrls2.indexOf(currentImage2) + 1;
        if (nextIndex < imageUrls2.length) {
            setCurrentImage2(imageUrls2[nextIndex]);
        }
    };

    const team1Score = parseInt(game.clubs[team1Id].score, 10);
    const team2Score = parseInt(game.clubs[team2Id].score, 10);
    const winningTeamId = team1Score > team2Score ? team1Id : team2Score > team1Score ? team2Id : null;

    // Highlight style
    const highlightStyle = {
        fontWeight: 'bold',
        color: 'white'
    };

    const navigate = useNavigate();

    const navigateToGameStats = () => {
        navigate(`/clubs/recent-games?teamname=${team}&console=${consolegen}&gameType=${gametype}&matchId=${game.matchId}`); // Navigate to game stats page
    };

    if (game) {
        let timestamp = game['timestamp'];

        let date = new Date(timestamp * 1000);
        let formatter = new Intl.DateTimeFormat('en-US', {
            month: 'short',   // e.g., Jan, Feb, Mar, ...
            day: '2-digit',   // e.g., 01, 02, 03, ..., 31
            hour: '2-digit',  // e.g., 01, 02, ..., 12, 13, ..., 23, 24
            minute: '2-digit', // e.g., 00, 01, ..., 59 // e.g., 00, 01, ..., 59
            hour12: true,
            timeZoneName: 'short'    // 12-hour time format, set to false for 24-hour format
        });

        // Format the date to the desired string
        let formattedDate = formatter.format(date);
        return (
            <Card sx={{ borderRadius: 5, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', minWidth: 300, margin: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <CardActionArea onClick={navigateToGameStats}>
                    <Grid container spacing={1.5} alignItems="center" sx={{ padding: 1.5 }}>
                        {/* Team 1 Row */}
                        <Typography noWrap sx={{ color: 'white', marginLeft: 2, marginTop: 1, fontWeight: 400, fontSize: '14px' }}>{formattedDate}</Typography>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Grid item xs={2} display="flex" alignItems="center">
                                <img src={currentImage1} alt="Team 1 Crest" onError={handleImageError} style={{ width: 35, height: 35 }} />
                            </Grid>
                            <Grid item xs={8} display="flex" alignItems="center">
                                <Typography noWrap sx={team1Id === winningTeamId ? highlightStyle : { color: 'white' }}>{game.clubs[team1Id].details.name.toUpperCase()}</Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                                <Typography noWrap sx={team1Id === winningTeamId ? highlightStyle : { color: 'white' }}>{game.clubs[team1Id].score}</Typography>
                            </Grid>
                        </Grid>
                        {/* Team 2 Row */}
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Grid item xs={2} display="flex" alignItems="center">
                                <img src={currentImage2} alt="Team 2 Crest" onError={handleImageError2} style={{ width: 35, height: 35 }} />
                            </Grid>
                            <Grid item xs={8} display="flex" alignItems="center">
                                <Typography noWrap sx={team2Id === winningTeamId ? highlightStyle : { color: 'white' }}>{game.clubs[team2Id]?.details?.name?.toUpperCase() ?? "Disbanded"}</Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                                <Typography noWrap sx={team2Id === winningTeamId ? highlightStyle : { color: 'white' }}>{game.clubs[team2Id]?.score}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        );
    }
}

function RecentGames({ team, consolegen, games, id, gametype }) {
    const scrollRef = useRef(null);
    const gamesArray = Object.values(games).reverse();

    useEffect(() => {
        // Check if the scroll container is not null
        if (scrollRef.current) {
            // Scroll to the rightmost part of the container
            const { scrollWidth, clientWidth } = scrollRef.current;
            const maxScrollLeft = scrollWidth - clientWidth;
            scrollRef.current.scrollLeft = maxScrollLeft;
        }
    }, [gamesArray]);

    if (games.length != 0 && JSON.stringify(games) !== JSON.stringify({error: "Unexpected token I in JSON at position 0"}))
        return (
            <Box ref={scrollRef} sx={{
                display: 'flex',
                overflowX: 'scroll',
                p: 1, '&::-webkit-scrollbar': { display: 'none' }, // For WebKit browsers
                scrollbarWidth: 'none', // For Firefox
                '-ms-overflow-style': 'none'
            }}>

                {gamesArray.map((game, index) => (
                    Object.keys(game['clubs']).length === 2 && (
                        <GameTile key={index} game={game} id={id} team={team} consolegen={consolegen} gametype={gametype} />
                    )
                ))}

            </Box>
        );
    else {
        return (
            <Typography sx={{ color: 'white', fontSize: '16px', textAlign: 'center', margin: 'auto', marginTop: 3 }}>No {gametype == "RegularSeason" ? "regular season" : gametype == "ClubFinals" ? "club finals" : gametype == "PrivateGames" ? "private matches" : ""} games found</Typography>
        )
    }
}

export default RecentGames;
