import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${LinearProgress.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${LinearProgress.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function convertSecondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
  const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60
  const sec = (seconds % 60).toFixed(); // remainder from minutes is seconds

  // Output as "HH:MM:SS" if there are hours, otherwise "MM:SS"
  const formattedHours = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = sec.toString().padStart(2, '0');

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
}

function SharedProgressBar({ team1Stats, team2Stats, label }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const total = parseFloat(team1Stats) + parseFloat(team2Stats);
  const team1Percentage = total !== 0 ? (parseFloat(team1Stats) / total) * 100 : 0;

  // Determine if the label is one of the types that require formatting
  const shouldFormatTime = label === "Possession" || label === "Time on Attack";

  // Conditionally format stats based on label
  const team1FormattedStats = shouldFormatTime ? convertSecondsToHMS(team1Stats) : parseFloat(team1Stats).toFixed(0);
  const team2FormattedStats = shouldFormatTime ? convertSecondsToHMS(team2Stats) : parseFloat(team2Stats).toFixed(0);

  return (
    <Box sx={{ width: '100%', marginBottom: 7 }}>
      <Box sx={{ position: 'relative', width: '100%', marginBottom: 2 }}>
        {/* Stats above progress bar */}
        <Box display="flex" justifyContent="space-between" alignItems="center" position="absolute" width="100%" top={-25}>
          <Typography variant="body2" sx={{ color: 'white', fontSize: '16px', fontWeight: 500, marginLeft: 1 }}>
            {team1FormattedStats}
          </Typography>
          <Typography variant="body2" color="white" sx={{ fontSize: '16px', fontWeight: 500 }}>
            {label}
          </Typography>
          <Typography variant="body2" sx={{ color: 'white', fontSize: '16px', fontWeight: 500, marginRight: 1 }}>
            {team2FormattedStats}
          </Typography>
        </Box>
        {/* Progress bar */}
        <Box display="flex" alignItems="center" sx={{ marginTop: 2 }}>
          <BorderLinearProgress variant="determinate" value={team1Percentage} sx={{ width: '100%', marginX: .4 }} />
        </Box>
      </Box>
    </Box>
  );
}

export default SharedProgressBar;
