import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useStats } from '../../Contexts/StatsContext';
import './shimmer.css';
import PressableTooltip from './PressableTooltip'
function PlayerInfo({ crestID, playerData, position, skaterOverall, goalieOverall, playstyle, clubInfo, jersey }) {
    const { state } = useStats();
    const { positionState } = state;
    const [roleLetter, setRoleLetter] = useState('')
    const [jerseyNum, setJerseyNum] = useState('')

    let imageUrls = [];
    if (crestID) {
        imageUrls = [
            clubInfo.useBaseAsset === "1" ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png` : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
        ];
    } else {
        imageUrls = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
    }

    const [currentImage, setCurrentImage] = useState(imageUrls[0]);

    const handleImageError = () => {
        const nextIndex = imageUrls.indexOf(currentImage) + 1;
        if (nextIndex < imageUrls.length) {
            setCurrentImage(imageUrls[nextIndex]);
        }
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    let atAGlanceStats = [];
    let overall_rating = ""
    let playStyle = ""
    let Position = ""
    let record = ""
    let winPerc = ""
    let overallColor = ""
    let winPercColor = ""
    let tier = ""
    let winTier = ""
    if (positionState === 'skater') {
        atAGlanceStats = [
            { label: 'GP', value: playerData["Games Played"] },
            { label: 'G', value: playerData.Goals },
            { label: 'A', value: playerData.Assists },
            { label: 'P', value: playerData.Points },
        ];

        playStyle = (
            <span>
                <span>&#8226;</span> {playstyle}
            </span>
        );
        overall_rating = skaterOverall
        Position = position
        record = playerData.Record
        winPerc = ((parseInt(playerData['Wins']) / parseInt(playerData['Games Played'])) * 100).toFixed(1)
        if (parseInt(winPerc) >= 80) {
            winPercColor = "#D10707"
            winTier = "Elite"
            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 70 && parseInt(winPerc) < 80) {
            winPercColor = "#9E1AB0"
            winTier = "Diamond"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 60 && parseInt(winPerc) < 70) {
            winPercColor = "#3AC9D9"
            winTier = "Platinum"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 50 && parseInt(winPerc) < 60) {
            winPercColor = "#FDD14D"
            winTier = "Gold"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 40 && parseInt(winPerc) < 50) {
            winPercColor = "#95AFB3"
            winTier = "Silver"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) < 40) {
            winPercColor = "#B27B5C"
            winTier = "Bronze"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        }

    } else if (positionState === 'goalie') {
        atAGlanceStats = [
            { label: 'GGP', value: playerData["Goalie games played"] },
            { label: 'GAA', value: playerData.GAA },
            { label: 'SV%', value: playerData["Save %"] },
            { label: 'SO', value: playerData.Shutouts },
        ];

        playStyle = ""
        overall_rating = goalieOverall
        Position = "G"
        record = playerData["Goalie record"]
        winPerc = String(((parseInt(playerData["Goalie wins"]) / parseInt(playerData["Goalie games played"])) * 100).toFixed(1))
        if (winPerc == "NaN") {
            winPerc = "0"
        }
        if (parseInt(winPerc) >= 80) {
            winPercColor = "#D10707"
            winTier = "Elite"
            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 70 && parseInt(winPerc) < 80) {
            winPercColor = "#9E1AB0"
            winTier = "Diamond"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 60 && parseInt(winPerc) < 70) {
            winPercColor = "#3AC9D9"
            winTier = "Platinum"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 50 && parseInt(winPerc) < 60) {
            winPercColor = "#FDD14D"
            winTier = "Gold"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) >= 40 && parseInt(winPerc) < 50) {
            winPercColor = "#95AFB3"
            winTier = "Silver"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        } else if (parseInt(winPerc) < 40) {
            winPercColor = "#B27B5C"
            winTier = "Bronze"

            if (parseInt(overall_rating) >= 95) {
                overallColor = "#D10707"
                tier = "Elite"
            } else if (parseInt(overall_rating) >= 90 && parseInt(overall_rating) < 95) {
                overallColor = "#73FBFD"
                tier = "Diamond"
            } else if (parseInt(overall_rating) >= 80 && parseInt(overall_rating) < 90) {
                overallColor = "#FDD14D"
                tier = "Gold"
            } else if (parseInt(overall_rating) >= 70 && parseInt(overall_rating) < 80) {
                overallColor = "#95AFB3"
                tier = "Silver"
            } else if (parseInt(overall_rating) < 70) {
                overallColor = "#B27B5C"
                tier = "Bronze"
            }
        }
    }

    const explanationTextSkater = (
        <>
            <Typography>Colors explanation:</Typography>
            <Typography variant="body2">
                <span variant="body2">Overall rating: </span>
                <span variant="body2" style={{ color: overallColor }}>({overall_rating}) {tier}</span>
            </Typography>
            <Typography>
                <span variant="body2">Win%: </span>
                <span variant="body2" style={{ color: winPercColor }}>({winPerc}) {winTier}</span>
            </Typography>
            {/* Add more explanations as needed */}
        </>
    );

    useEffect(() => {
        async function getRoleLetter(info, username) {
            if (username === info.captain) {
                setRoleLetter("'C'"); // Captain
            } else if (username === info.alternateCaptain1 || username === info.alternateCaptain2) {
                setRoleLetter("'A'"); // Alternate Captain
            } else {
                setRoleLetter(''); // No special role
            }
        }

        if (clubInfo) {
            getRoleLetter(clubInfo.info, playerData.Username);
        }

        if (jersey) {
            setJerseyNum(jersey ? `&#8226; #${jersey}` : '');
        }

    }, [setRoleLetter, clubInfo, jersey]);

    const penaltyShotAttemptsPerGame =
        playerData.skpenaltyattempts && playerData['Games Played']
            ? playerData.skpenaltyattempts / playerData['Games Played']
            : 0;

    const playerType = penaltyShotAttemptsPerGame > 0.18 ? <span>&#8226; 3s</span> : <span>&#8226; 6s</span>;


    return (
        <Box
            className="shimmer-border"
            style={{ '--color1': overallColor, '--color2': winPercColor }}
            sx={{ maxHeight: '505px', borderRadius: 5, }}
        >
            <Box sx={{
                maxHeight: '500px',
                width: '99.5%',
                borderRadius: 5,
                backgroundColor: '#171717',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                color: 'white',
                marginTop: 8,
                marginX: 'auto',
                fontSize: 22,
                overflow: 'clip',
                boxShadow: '2px 6px 10px rgba(0, 0, 0, 0.8)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: windowWidth < 600 ? -60 : windowWidth < 900 ? 20 : 20,
                    bottom: 0,
                    backgroundImage: `url(${currentImage})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                    backgroundSize: windowWidth < 600 ? '75%' : windowWidth < 900 ? '30%' : '30%',
                    opacity: 0.165,
                    zIndex: 2,
                }
            }}>
                <PressableTooltip username={playerData.Username} explanationTextSkater={explanationTextSkater} />
                <div style={{ zIndex: 10, marginTop: 15, marginLeft: 15, }}>
                    <Typography sx={{ fontSize: playerData.Username.length < 11 ? 36 : playerData.Username.length >= 11 && playerData.Username.length < 16 ? 28 : 26, fontWeight: 750, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{playerData.Username.toUpperCase()} {roleLetter}</Typography>
                    <Typography sx={{ marginTop: .4, fontSize: 16, fontWeight: 450, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{playerData['[object Object]'].teamName.toUpperCase()} <span>&#8226;</span> {Position} <span dangerouslySetInnerHTML={{ __html: jerseyNum }}></span></Typography>
                    <Typography sx={{ marginTop: .4, fontSize: 16, fontWeight: 450, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{overall_rating} OVR {playStyle} {playerType}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, zIndex: 10, marginTop: 4, marginLeft: 15, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 450, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{record}, {winPerc}%</Typography>
                </div>
                <img src={currentImage} onError={handleImageError} alt="" style={{ display: 'none' }} />

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', marginTop: 1 }}>
                    {atAGlanceStats.map((stat, index) => (
                        <React.Fragment key={stat.label}>
                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                            <Box sx={{ padding: 2, paddingX: windowWidth < 600 ? 2 : windowWidth < 900 ? 5 : 5, textAlign: 'start' }}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#e1e1e1', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                    {stat.label}
                                </Typography>
                                <Typography variant="h7" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                    {stat.value}
                                </Typography>

                            </Box>
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default PlayerInfo;
