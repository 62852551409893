import React, { useEffect, useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    TablePagination,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';

// Utility function for sorting
const sortTeams = (teams, sortConfig) => {
    const { field, direction } = sortConfig;

    if (!field) return teams;

    return [...teams].sort((a, b) => {
        const valA = isNaN(a[field]) ? a[field] : Number(a[field]);
        const valB = isNaN(b[field]) ? b[field] : Number(b[field]);

        if (valA < valB) return direction === 'asc' ? -1 : 1;
        if (valA > valB) return direction === 'asc' ? 1 : -1;
        return 0;
    });
};

function TopClubsLeaderboard() {
    const navigate = useNavigate();

    // Toggle states: isPerGame = false => "Career" data, true => "Per Game" data
    const [isSeasonRanking, setIsSeasonRanking] = useState(false);

    // Two different data arrays for two different endpoints
    const [top100Data, setTop100Data] = useState([]);
    const [seasonStatsData, setSeasonStatsData] = useState([]);
    // Combined or dynamic “active” data that is displayed in the table
    const [sortedTeams, setSortedTeams] = useState([]);
    const [sortConfig, setSortConfig] = useState({ field: '', direction: 'asc' });

    // Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Loading + error states
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // For responsive margins
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch BOTH sets of data once (or fetch on demand if desired):
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // 1) Fetch the "Top 100 Clubs" data
                const top100Resp = await fetch('/api/top100Clubs');
                if (!top100Resp.ok) throw new Error('Top 100 Clubs fetch failed');
                const top100Data = await top100Resp.json();
                setTop100Data(top100Data);

                // 2) Fetch the "Season Stats" data
                const seasonResp = await fetch('/api/topClubsSeason');
                if (!seasonResp.ok) throw new Error('Season Stats fetch failed');
                const seasonData = await seasonResp.json();
                setSeasonStatsData(seasonData);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Decide which dataset we’re using based on isPerGame
    const activeData = isSeasonRanking ? seasonStatsData : top100Data;

    // Re-sort whenever activeData or sortConfig changes
    useEffect(() => {
        setSortedTeams(sortTeams(activeData, sortConfig));
    }, [activeData, sortConfig]);

    // Toggle handler: switch between "career" and "perGame"
    const handleToggle = (event, newValue) => {
        if (newValue !== null) {
            setIsSeasonRanking(newValue === 'seasonRanking');
            setPage(0); // Reset pagination to first page
            setSortConfig({ field: '', direction: 'asc' }); // Optionally reset sorting
        }
    };

    // Sort column click
    const handleSort = (field) => {
        setSortConfig((prev) => {
            if (prev.field === field) {
                return { field, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { field, direction: 'asc' };
        });
    };

    // Table Pagination
    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Navigate to club
    const handleGoToClub = (teamname) => {
        const targetUrl = `/clubs?teamname=${teamname}&console=common-gen5`;
        navigate(targetUrl, { replace: true });
    };

    // Fallback crest
    const handleCrestError = (e) => {
        e.target.src =
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png';
    };

    // Show arrow if column is being sorted
    const renderSortArrow = (columnKey) => {
        if (sortConfig.field !== columnKey) return '';
        return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
    };

    // Paginated slice
    const paginatedTeams = sortedTeams.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    // Loading + Error handling
    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                <PropagateLoader color="#3498db" />
            </div>
        );
    }

    if (error) {
        return (
            <Paper sx={{ p: 2, textAlign: 'center' }}>
                <Typography color="error" variant="body1">
                    Error: {error}
                </Typography>
            </Paper>
        );
    }

    return (
        <Box sx={{ p: 0, marginX: windowWidth < 600 ? 1 : windowWidth < 900 ? 10 : 10 }}>

            {/* Toggle Button Group */}
            <Box display="flex" mt={4} mb={1}>
                <Box>
                    <Paper
                        elevation={3}
                        sx={{
                            marginLeft: 1,
                            bgcolor: '#1f1f1f',
                            position: 'relative',
                            borderRadius: '20px',
                            height: '30px',
                            width: '300px',
                            overflow: 'hidden',
                        }}
                    >
                        {/* Sliding Background */}
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '50%',
                                height: '100%',
                                bgcolor: '#1976d2',
                                transform: isSeasonRanking ? 'translateX(100%)' : 'translateX(0)',
                                transition: 'transform 0.3s ease-in-out',
                                borderRadius: '20px',
                                top: 0,
                                left: 0,
                            }}
                        />
                        {/* Toggle Buttons */}
                        <ToggleButtonGroup
                            value={isSeasonRanking ? 'seasonRanking' : 'top100'}
                            exclusive
                            onChange={handleToggle}
                            sx={{
                                position: 'relative', // To ensure it's above the sliding background
                                zIndex: 1,
                                width: '100%',
                                height: '100%',
                                '& .MuiToggleButtonGroup-grouped': {
                                    border: 0,
                                    borderRadius: '20px',
                                    width: '150px',
                                    height: '30px',
                                    margin: 0, // Remove margin between buttons
                                },
                            }}
                        >
                            <ToggleButton
                                value="top100"
                                sx={{
                                    color: 'white',
                                    bgcolor: 'transparent',
                                    fontSize: '12px',
                                    '&:hover': {
                                        bgcolor: 'transparent',
                                    },
                                }}
                            >
                                Top 100 Clubs
                            </ToggleButton>
                            <ToggleButton
                                value="seasonRanking"
                                sx={{
                                    color: 'white',
                                    bgcolor: 'transparent',
                                    fontSize: '12px',
                                    '&:hover': {
                                        bgcolor: 'transparent',
                                    },
                                }}
                            >
                                Season Ranking
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
                </Box>
            </Box>
            <TableContainer component={Paper} sx={{
                overflow: 'auto', backgroundColor: '#121212',
                '&::-webkit-scrollbar': { width: '0px' },
                /* Track */
                '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 5px #121212', borderRadius: '10px' },
                /* Handle */
                '&::-webkit-scrollbar-thumb': { background: '#1976d2', borderRadius: '10px' },
                /* Handle on hover */
                '&::-webkit-scrollbar-thumb:hover': { background: '#303030' }
            }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* RANK */}
                            <TableCell
                                onClick={() => handleSort('rank')}
                                sx={{ padding: '6px', fontSize: '13px', color: 'white', borderBottom: 'none', cursor: 'pointer' }}
                            >
                                Rank{renderSortArrow('rank')}
                            </TableCell>

                            {/* CREST IMAGE */}
                            <TableCell sx={{ fontSize: '13px', borderBottom: 'none', color: 'white' }}>Crest</TableCell>

                            {/* TEAM NAME */}
                            <TableCell
                                onClick={() => handleSort('clubName')}
                                sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                            >
                                Club Name{renderSortArrow('clubName')}
                            </TableCell>

                            {/* RECORD */}
                            <TableCell
                                onClick={() => handleSort('record')}
                                sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                            >
                                Record{renderSortArrow('record')}
                            </TableCell>

                            {/* GP */}
                            <TableCell
                                onClick={() => handleSort('gp')}
                                sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                            >
                                GP{renderSortArrow('gp')}
                            </TableCell>

                            {/* WINS */}
                            <TableCell
                                onClick={() => handleSort('wins')}
                                sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                            >
                                Wins{renderSortArrow('wins')}
                            </TableCell>

                            {/* LOSSES */}
                            <TableCell
                                onClick={() => handleSort('losses')}
                                sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                            >
                                Losses{renderSortArrow('losses')}
                            </TableCell>

                            {/* OTL */}
                            <TableCell
                                onClick={() => handleSort('otl')}
                                sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                            >
                                OTL{renderSortArrow('otl')}
                            </TableCell>

                            {!isSeasonRanking && (


                                <><TableCell
                                    onClick={() => handleSort('windifferential')}
                                    sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer', textWrap: 'nowrap' }}
                                >
                                    Win Diff{renderSortArrow('windifferential')}
                                </TableCell><TableCell
                                    onClick={() => handleSort('gf')}
                                    sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                >
                                        GF{renderSortArrow('gf')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('ga')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        GA{renderSortArrow('ga')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('gdiff')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        Gdiff{renderSortArrow('gdiff')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('agf')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        AGF{renderSortArrow('agf')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('aga')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        AGA{renderSortArrow('aga')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('atoa')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        ATOA{renderSortArrow('atoa')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('winmar')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer', textWrap: 'nowrap' }}
                                    >
                                        Win MAR{renderSortArrow('winmar')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('lossmar')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer', textWrap: 'nowrap' }}
                                    >
                                        Loss MAR{renderSortArrow('lossmar')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('hits')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        Hits{renderSortArrow('hits')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('hitspg')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer', textWrap: 'nowrap' }}
                                    >
                                        Hits PG{renderSortArrow('hitspg')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('shotspg')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        SPG{renderSortArrow('shotspg')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('shotperc')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        ST%{renderSortArrow('shotperc')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('shotsapg')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        SAPG{renderSortArrow('shotsapg')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('pim')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PIM{renderSortArrow('pim')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('pimpg')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer', textWrap: 'nowrap' }}
                                    >
                                        PIM PG{renderSortArrow('pimpg')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('streak')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        Streak{renderSortArrow('streak')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('ppg')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PPG{renderSortArrow('ppg')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('ppo')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PPO{renderSortArrow('ppo')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('ppperc')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PP%{renderSortArrow('ppperc')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('shg')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        SHG{renderSortArrow('shg')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('shga')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        SHGA{renderSortArrow('shga')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('ppga')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PPGA{renderSortArrow('ppga')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('tsh')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        TSH{renderSortArrow('tsh')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('pkperc')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PK%{renderSortArrow('pkperc')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('faceoffswon')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        FOW{renderSortArrow('faceoffswon')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('faceoffslost')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        FOL{renderSortArrow('faceoffslost')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('fop')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        FO%{renderSortArrow('fop')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('brkgoals')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        BRKG{renderSortArrow('brkgoals')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('brkperc')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        BRK%{renderSortArrow('brkperc')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('onetimergoals')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        OTG{renderSortArrow('onetimergoals')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('onetimerperc')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        OT%{renderSortArrow('onetimerperc')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('papg')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PPA{renderSortArrow('papg')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('paperc')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PP%{renderSortArrow('paperc')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('psgoals')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PSG{renderSortArrow('psgoals')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('pschances')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        PSA{renderSortArrow('pschances')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('blkshots')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        BS{renderSortArrow('blkshots')}
                                    </TableCell><TableCell
                                        onClick={() => handleSort('so')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        SO{renderSortArrow('so')}
                                    </TableCell>
                                </>
                            )}

                            {isSeasonRanking && (
                                <>
                                    <TableCell
                                        onClick={() => handleSort('rankingPoints')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        Points{renderSortArrow('rankingPoints')}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleSort('seasons')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        Sea{renderSortArrow('seasons')}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleSort('titlesWon')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        T{renderSortArrow('titlesWon')}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleSort('leaguesWon')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        LT{renderSortArrow('leaguesWon')}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleSort('totalCupsWon')}
                                        sx={{ fontSize: '13px', borderBottom: 'none', color: 'white', cursor: 'pointer' }}
                                    >
                                        Cups{renderSortArrow('totalCupsWon')}
                                    </TableCell>
                                </>
                            )}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedTeams.map((team, index) => {
                            // 1) Determine crest URL
                            const crestID = team?.clubInfo?.customKit?.crestAssetId;
                            const useBaseAsset = team?.clubInfo?.customKit?.useBaseAsset;
                            let crestUrl =
                                'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png';

                            if (crestID) {
                                crestUrl =
                                    useBaseAsset === '1'
                                        ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png`
                                        : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`;
                            }

                            return (

                                <TableRow
                                    key={team.clubId}
                                    hover
                                    sx={{ borderBottom: 'none', cursor: 'pointer', borderTopColor: '#424549' }}
                                    onClick={() => handleGoToClub(team.clubName)}
                                >
                                    <TableCell sx={{ fontSize: '14px', paddingLeft: '6px', borderBottom: 'none', color: 'white' }}>
                                        {page * rowsPerPage + index + 1}
                                    </TableCell>

                                    {/* Team Crest */}
                                    <TableCell sx={{ borderBottom: 'none', }}>
                                        <img
                                            src={crestUrl}
                                            onError={handleCrestError}
                                            alt={`Crest for ${team.clubName}`}
                                            style={{ width: 30, height: 30 }}
                                        />
                                    </TableCell>

                                    {/* Club Name */}
                                    <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white', textWrap: 'nowrap' }}>{team.clubName}</TableCell>

                                    {/* Record */}
                                    <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white', textWrap: 'nowrap' }}>{team.record}</TableCell>

                                    {/* GP */}
                                    <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.gp}</TableCell>

                                    {/* Wins */}
                                    <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.wins}</TableCell>

                                    {/* Losses */}
                                    <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.losses}</TableCell>

                                    {/* OTL */}
                                    <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.otl}</TableCell>

                                    {!isSeasonRanking && (
                                        <>
                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.windifferential}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.gf}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.ga}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.gdiff}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.agf}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.aga}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.atoa}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.winmar}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.lossmar}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.hits}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.hitspg}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.shotspg}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.shotperc}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.shotsapg}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.pim}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.pimpg}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.streak}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.ppg}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.ppo}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.ppperc}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.shg}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.shga}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.ppga}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.tsh}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.pkperc}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.faceoffswon}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.faceoffslost}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.fop}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.brkgoals}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.brkperc}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.onetimergoals}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.onetimerperc}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.papg}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.paperc}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.psgoals}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.pschances}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.blkshots}</TableCell>

                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.so}</TableCell>
                                        </>
                                    )}

                                    {isSeasonRanking && (
                                        <>
                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.rankingPoints}</TableCell>
                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.seasons}</TableCell>
                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.titlesWon}</TableCell>
                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.leaguesWon}</TableCell>
                                            <TableCell sx={{ fontSize: '14px', borderBottom: 'none', color: 'white' }}>{team.totalCupsWon}</TableCell>

                                        </>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={sortedTeams.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    color: 'white', '& .MuiTablePagination-selectIcon': {
                        color: 'white', // Changes the color of the dropdown arrow
                    },
                }}
            />
        </Box>
    );
}

export default TopClubsLeaderboard;
