import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import CircularMeter from './CircularMeter'

function ClubsInfo({ crestID, clubData }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let imageUrls = [];
    if (crestID) {
        imageUrls = [
            clubData.teamData.clubInfo.customKit.useBaseAsset === "1" ? `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png` : `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
        ];
    } else {
        imageUrls = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
    }

    const [currentImage, setCurrentImage] = useState(imageUrls[0]);

    const handleImageError = () => {
        const nextIndex = imageUrls.indexOf(currentImage) + 1;
        if (nextIndex < imageUrls.length) {
            setCurrentImage(imageUrls[nextIndex]);
        }
    };

    const winPerc = ((Number(clubData.team_stats.wins) / Number(clubData.team_stats.totalGames)) * 100).toFixed(1)
    const divURL = `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/divisioncrest/divisioncrest${clubData.teamData.currentDivision}.png`

    let atAGlanceStats = [
        { label: 'G', value: clubData.team_stats.goals },
        { label: 'GPG', value: clubData.team_stats.goals_per_game },
        { label: 'GA', value: clubData.team_stats.goalsAgainst },
        { label: 'GAPG', value: clubData.team_stats.goalsAgainst_per_game },
    ];

    const LogoComponent = ({ logoUrl }) => {
        const logoStyles = {
            width: '100px',
            height: '100px',
            objectFit: 'fill',
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                <img src={logoUrl} onError={handleImageError} alt="Logo" style={logoStyles} />
            </div>
        );
    };

    function generateLast10Record(results) {
        let wins = 0;
        let losses = 0;
        let otl = 0;

        results.forEach(result => {
            switch (result) {
                case "0":
                    wins++;
                    break;
                case "1":
                    losses++;
                    break;
                default:
                    otl++;
            }
        });

        return `${wins}-${losses}-${otl}`;
    }

    return (
        <Box sx={{
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 4
        }}>
            <LogoComponent logoUrl={currentImage} />
            <Typography
                sx={{
                    marginTop: 0,
                    color: 'white',
                    fontSize: 28,
                    fontWeight: 750,
                    fontStyle: 'italic',
                    fontFamily:
                        'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                }}
            >
                {clubData.teamData.clubInfo.name.toUpperCase()}
            </Typography>
            <Typography sx={{ color: '#B0B0B0', fontSize: 18 }}>
                {clubData.teamData.record}, {winPerc} W%
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '300px', marginTop: 3 }}>
                <Box >
                    <Typography sx={{ color: 'white', fontSize: 18 }}>
                        {generateLast10Record([clubData.teamData.recentResult0, clubData.teamData.recentResult1, clubData.teamData.recentResult2, clubData.teamData.recentResult3, clubData.teamData.recentResult4, clubData.teamData.recentResult5, clubData.teamData.recentResult6, clubData.teamData.recentResult7, clubData.teamData.recentResult8, clubData.teamData.recentResult9])}
                    </Typography>
                    <Typography sx={{ color: '#B0B0B0', fontSize: 16 }}>
                        Last 10
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{ color: 'white', fontSize: 18 }}>
                        {clubData.top100Ranking}
                    </Typography>
                    <Typography sx={{ color: '#B0B0B0', fontSize: 16 }}>
                        Top100 rank
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundColor: 'transparent', marginTop: 3, width: '350px' }}>
                {atAGlanceStats.map((stat, index) => (
                    <React.Fragment key={stat.label}>
                        {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                        <Box sx={{ padding: 2, paddingX: windowWidth < 600 ? 2 : windowWidth < 900 ? 5 : 5, textAlign: 'start' }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#a5a5a5', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                {stat.label}
                            </Typography>
                            <Typography variant="h7" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                {stat.value}
                            </Typography>
                        </Box>
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
}

export default ClubsInfo;
