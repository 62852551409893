import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useStats } from '../../Contexts/StatsContext';
import SearchAndDropdownCombined from '../../Main Components/SearchAndDropdownCombined';
import PlayerOptions from './playerOptions';
import { useUser } from '../../Contexts/UserContext';
import { useLoading } from '../../Contexts/LoadingContext';
import { PropagateLoader } from 'react-spinners';
import SetPlayer from './SetPlayer';
import { useNavigate } from 'react-router-dom';
import './BodyCheckButton.css';
import bodyCheckSound from './bodycheck.mp3'; // path to your sound file
import useSound from 'use-sound';
import VignetteAdComponent from '../../Main Components/VignetteAdComponent';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
export const refreshUserData = async (setUser) => {
    try {
        const response = await fetch('/api/auth/session', { credentials: 'include' }); // Ensure the session is included
        const data = await response.json();
        if (data.isLoggedIn) {
            setUser(data.user); // Set user data if logged in
        } else {
            setUser(null); // Set to null if not logged in
        }
    } catch (error) {
        console.error('Error refreshing user data:', error);
        setUser(null); // Handle error by clearing user state
    }
};


function PlayerStats() {
    const [username, setUsername] = useState('');
    const [teamname, setTeamName] = useState('');
    const [clubId, setclubId] = useState('');
    const dataFetched = useRef(false);
    const [consolegen, setConsolegen] = React.useState('common-gen5');
    const [freeAgentState, setFreeAgentState] = useState(false);
    const [profileClaimed, setProfileClaimed] = useState(false);
    const [hasClaimedProfile, setHasClaimedProfile] = useState(false);
    const { loading, setLoading } = useLoading();
    const { user, setUser } = useUser();
    const [playerData, setPlayerData] = useState(null);
    const location = useLocation();
    const [refreshKey, setRefreshKey] = useState(0);
    const [clubInfo, setClubInfo] = useState('');
    const [jersey, setJersey] = useState('');
    const [error, setError] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarOpen2, setSnackbarOpen2] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [params, setParams] = useState({});
    const [state, setState] = React.useState({
        open: false,
        Transition: Slide,
    });
    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    const navigate = useNavigate();
    useEffect(() => {
        document.title = `Chel stats | Player stats`;
    }, []);

    const [shake, setShake] = useState(false);
    const [play] = useSound(bodyCheckSound);
    const [bodycheckCount, setBodycheckCount] = useState(0);

    const triggerShake = async () => {
        play();
        setShake(true);
        // Turn off shake after the animation duration
        setTimeout(() => setShake(false), 500); // duration of the animation

        try {
            const response = await fetch(`/api/bodycheck/${username}`, { method: 'POST', credentials: 'include' });
            const data = await response.json();
            if (response.ok) {
                setBodycheckCount(data.bodychecks);
            } else {
                console.error('Failed to bodycheck:', data);
            }
        } catch (error) {
            console.error('Error sending bodycheck request:', error);
        }
    };

    const fetchPlayerData = async (forceRefresh = false) => {
        if (forceRefresh) {
            setRefreshKey(prevKey => prevKey + 1); // Increment to trigger useEffect
            return; // Early return; let useEffect handle the actual fetching
        }
        setLoading(true);
        setError('');
        const usernameParam = queryParams.get('username');
        setUsername(usernameParam)
        const teamNameParam = queryParams.get('teamname');
        setTeamName(teamNameParam)
        const consoleGenParam = queryParams.get('console');
        const freeAgentParam = queryParams.get('freeAgent'); // Capturing the 'freeAgent' parameter

        let apiUrl = '/api/players/stats';
        let queryParts = [];

        if (usernameParam != '') {
            queryParts.push(`username=${encodeURIComponent(usernameParam)}`);
        } else {
            setError('Gamertag is required')
            return
        }
        if (teamNameParam) {
            queryParts.push(`teamname=${encodeURIComponent(teamNameParam)}`);
            setFreeAgentState(false)
        }
        if (consoleGenParam) {
            queryParts.push(`console=${encodeURIComponent(consoleGenParam)}`);
        }
        // Adding the 'freeAgent' parameter to the API call if it exists
        if (freeAgentParam === 'True') {
            queryParts.push(`freeAgent=True`);
            setFreeAgentState(true)
        }

        // Construct the full API URL with query parameters if available
        if (queryParts.length) {
            apiUrl += `?${queryParts.join('&')}`;
        }
        try {
            const response = await fetch(apiUrl);

            if (!response.ok) {
                setError("Unexpected error. Please try again.")
                return
            }
            const data = await response.json();
            setPlayerData(data);

            if (data["[object Object]"]) {
                setclubId(String(data["[object Object]"].teamID))
            } else {
                setclubId(String(0))
            }
        } catch (error) {
            setError(`Can't find data for ${queryParams.get('username')} on team: ${queryParams.get('teamname')}`);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (location.search && !dataFetched.current) {
            fetchPlayerData();

            dataFetched.current = true;
        }
    }, [location.search]);

    useEffect(() => {
        async function fetchBodycheckCount() {
            try {
                const response = await fetch(`/api/getbodycheckcount/${username}`, { method: 'GET', credentials: 'include' });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setBodycheckCount(data.bodychecks);
            } catch (error) {
                console.error('Error sending bodycheck request:', error);
            }
        }

        if (username) { // Ensure username is not empty before making the request
            fetchBodycheckCount();
        }

        async function getClubInfo(teamID) {
            try {
                const response = await fetch(`/api/club/${teamID}/info`, { credentials: 'include' });
                if (response.ok) {
                    const clubInfo = await response.json();
                    setClubInfo(clubInfo);
                }
            } catch (error) {
                console.error('Failed to get club scouting data:', error);
            }
        };

        if (clubId) {
            getClubInfo(clubId)
        }

        async function getJersey(username) {
            try {
                const response = await fetch(`/api/player/${username}/jersey`, { credentials: 'include' });
                if (response.ok) {
                    const jersey = await response.json();
                    setJersey(jersey);
                }
            } catch (error) {
                console.error('Failed to get club scouting data:', error);
            }
        };

        if (username) {
            getJersey(username)
        }
    }, [setBodycheckCount, setClubInfo, clubId]);


    useEffect(() => {
        if (user) {
            fetchClaimedProfileStatus();
        }
    }, [user]);

    useEffect(() => {
        const checkProfileClaimed = () => {
            if (!user || !playerData) return false;
            return user.claimedProfile && user.claimedProfile.username === playerData.username && user.claimedProfile.teamname === playerData.teamname && user.claimedProfile.clubId === String(playerData["[object Object]"].teamId) && user.claimedProfile.consolegen === playerData.consolegen;
        };

        setProfileClaimed(checkProfileClaimed());
    }, [user, playerData]);

    useEffect(() => {
        if (user) {
            refreshUserData(setUser);
        }
    }, [setUser]);

    const fetchClaimedProfileStatus = async () => {
        try {
            const response = await fetch('/api/user/claimed-profile-status');
            const data = await response.json();
            if (response.ok) {
                setHasClaimedProfile(data.hasClaimedProfile);
            } else {
                // Handle failure (optional)
                console.error('Failed to fetch claim status');
            }
        } catch (error) {
            console.error('Error fetching claim status:', error);
        }
    };


    const isCurrentProfileClaimed = () => {
        if (!user || !playerData) return false;

        const hasClaimedData = user.claimedProfile && (
            user.claimedProfile.username ||
            user.claimedProfile.teamname ||
            user.claimedProfile.clubId ||
            user.claimedProfile.consolegen
        );

        if (!hasClaimedData) {
            return false;
        }

        let platform = playerData.Platform;
        if (platform === 'ps5' || platform === 'xbsx') {
            platform = 'common-gen5';
        } else if (platform === 'ps4' || platform === 'xone') {
            platform = 'common-gen4';
        }

        const playerUsername = playerData.Username ? playerData.Username.toLowerCase() : '';
        const playerTeamName = playerData['[object Object]'].teamName ? playerData['[object Object]'].teamName.toLowerCase() : '';
        const playerClubId = String(playerData['[object Object]'].teamID);
        const userUsername = user.claimedProfile.username ? user.claimedProfile.username.toLowerCase() : '';
        const userTeamName = user.claimedProfile.teamname ? user.claimedProfile.teamname.toLowerCase() : '';
        const userClubId = user.claimedProfile.clubId;
        const userConsoleGen = user.claimedProfile.consolegen;

        return userUsername === playerUsername && userTeamName === playerTeamName && userClubId === playerClubId;
    };

    const showClaimButton = user && !hasClaimedProfile && freeAgentState != true;
    const showUnclaimButton = user && freeAgentState != true && isCurrentProfileClaimed();
    const showOptionsButton = user && freeAgentState != true && isCurrentProfileClaimed();

    const handleUnclaimProfile = async () => {
        try {
            const response = await fetch('/api/user/unclaim-profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any needed headers, like authentication tokens
                },
                credentials: 'include', // Needed if you're using sessions
            });
            const data = await response.json();
            if (response.ok) {
                // Assuming setUser is a method provided by your context to update the user state
                setUser({ ...user, claimedProfile: null }); // Update user state to reflect the unclaimed profile
                console.log(data.message); // Optional: log success message
            } else {
                throw new Error(data.message || 'Failed to unclaim profile');
            }
        } catch (error) {
            console.error('Error unclaiming profile:', error.message);
        }
    };

    const handleClaimProfile = async (username, teamname, clubId, consolegen) => {
        // Example details, replace with actual data as needed
        const profileDetails = { username, teamname, clubId, consolegen };
        try {
            const response = await fetch('/api/user/claim-profile', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(profileDetails),
            });
            if (response.ok) {
                // Update local state or inform the user of success
                setHasClaimedProfile(true);
                const updatedUser = { ...user, claimedProfile: { username, teamname, clubId, consolegen } };
                setUser(updatedUser);
            } else {
                // Handle errors or failure
                console.error('Failed to claim profile');
            }
        } catch (error) {
            console.error('Error claiming profile:', error);
        }
    };

    const handleSaveOptions = (options) => {

        console.log(options);
    };

    const handleShareProfile = () => {
        // Copy the current URL to the clipboard
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                // You can display a message to the user indicating the link was successfully copied
                console.log('Profile link copied to clipboard!');
            })
            .catch(err => {
                // Handle any errors
                console.error('Failed to copy the profile link:', err);
            });

        setSnackbarOpen(true);
    };

    useEffect(() => {
        const params = getUrlParams();
        setParams(params);
        if (params['username']) {
            // Check if the player search is already saved
            const savedPlayer = localStorage.getItem(`player_${params['username']}`);
            setIsSaved(!!savedPlayer);
        }
    }, []);

    const getUrlParams = () => {
        const params = new URLSearchParams(window.location.search);
        const paramsObject = {};
        params.forEach((value, key) => {
            paramsObject[key] = value;
        });
        return paramsObject;
    };

    // Function to save URL parameters to local storage
    const handleSave = () => {
        const params = getUrlParams();
        localStorage.setItem(`player_${params['username']}`, JSON.stringify(params));
        setIsSaved(true);
        setSnackbarMessage('Search saved');
        setSnackbarOpen2(true);
    };
    const handleUnsave = () => {
        if (isSaved) {
            localStorage.removeItem(`player_${params['username']}`);
            setIsSaved(false);
            setSnackbarMessage('Saved search removed');
            setSnackbarOpen2(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleCloseSnackbar2 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen2(false);
    };

    const handleGoToClub = () => {

        let targetUrl = `/clubs?teamname=${teamname}&console=${consolegen}`;

        if (location.pathname + location.search === targetUrl) {
            // If already at the location, increment refreshKey to trigger a refresh
            setRefreshKey(prevKey => prevKey + 1);
        } else {
            // Navigate to the new URL
            navigate(targetUrl, { replace: true });
        }
    };

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><PropagateLoader color="#3498db" /></div>;
    } else if (error) {
        return (
            <Container>
                <SearchAndDropdownCombined
                />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', color: 'white' }}>{error}</div>

            </Container>
        )
    } else {
        return (
            <div className={shake ? 'screen-shake' : ''}>
                <Box sx={{p: 1}}>
                    <SearchAndDropdownCombined
                    />
                    {playerData && (
                        <>
                            {showClaimButton && (
                                <Button sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }} onClick={() => handleClaimProfile(username, teamname, clubId, consolegen)}>Claim Profile</Button>
                            )}
                            {showUnclaimButton && (
                                <Button sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }} onClick={handleUnclaimProfile}>Unclaim Profile</Button>
                            )}
                            {showOptionsButton && (
                                <PlayerOptions onSave={handleSaveOptions} username={user.username} />
                            )}
                            {freeAgentState == false && (
                                <Button
                                    sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                                    onClick={handleGoToClub}
                                >
                                    View Club
                                </Button>
                            )}
                            {freeAgentState == false && (
                                <Button
                                    onClick={triggerShake}
                                    sx={{
                                        marginTop: 2,
                                        marginRight: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        backgroundColor: '#1f1f1f',
                                        borderRadius: '20px',
                                        color: 'white',
                                        fontWeight: '300',
                                        fontSize: '12px'
                                    }}
                                >
                                    Bodycheck {bodycheckCount ? `(${bodycheckCount})` : ''}
                                </Button>
                            )}

                        </>
                    )}
                    <Button
                        sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                        onClick={handleShareProfile}
                    >
                        Share
                    </Button>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2400} // Snackbar will close after 6000ms
                        onClose={handleCloseSnackbar}
                        TransitionComponent={state.Transition}
                        message="URL copied"
                        sx={{
                            '& .MuiSnackbarContent-root': {
                                backgroundColor: '#1976d2', // Snackbar background color
                                color: '#fff' // Text color
                            }
                        }}
                        action={
                            <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                                Close
                            </Button>
                        }
                    />

                    <Button
                        sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                        onClick={isSaved ? handleUnsave : handleSave}
                    >
                        {isSaved ? 'Unsave search' : 'Save search'}
                    </Button>

                    <Snackbar
                        open={snackbarOpen2}
                        autoHideDuration={2400} // Snackbar will close after 6000ms
                        onClose={handleCloseSnackbar2}
                        TransitionComponent={state.Transition}
                        message={snackbarMessage}
                        sx={{
                            '& .MuiSnackbarContent-root': {
                                backgroundColor: '#1976d2', // Snackbar background color
                                color: '#fff' // Text color
                            }
                        }}
                        action={
                            <Button color="inherit" size="small" onClick={handleCloseSnackbar2}>
                                Close
                            </Button>
                        }
                    />


                    <div style={{ marginTop: 30 }}>
                        {loading ? (
                            <div>Loading...</div>
                        ) : error ? (
                            <div style={{ color: 'white' }}>{error}</div> // Display the error message
                        ) : (
                            <div>
                                {/* Your existing player data display logic here */}
                            </div>
                        )}
                    </div>
                    <SetPlayer playerData={playerData} freeAgentState={freeAgentState} clubInfo={clubInfo} jersey={jersey} />
                </Box>
            </div>
        );
    }
}

export default PlayerStats;