import React, { useEffect } from 'react';
import { Container, Typography, Link, Paper, List, ListItem } from '@mui/material';
import AdComponent from './AdComponent';

const ChangeLog = () => {
    const changes = [
        {
            date: "January 15, 2025",
            content: [
                "Added EVGs (Even strength goals) and PPGs (Power play goals) as a career and per game stat to player and club pages for players",
                "Added average RP to game stats in game pages",
                "Added RP to players in game pages",
                "Added main mode (3s, 6s) to player profile cards",
                "Added percentage to players top played position",
                "Updated the player ranking leaderboard to fix loading issues related to having too many users. Players are now separated by their main mode, there are now less stats, and sorting is disabled",
                "Updated WAR ratings to use PIMs minus fights and scaled giveaways by how many goals the opposing team scored",
                "Fixed a backend issue that didn't allow some players to view their recent game stats if their clubs name matched with other clubs names",
                "Fixed logos on WAR cards",
                "Fixed stat indicators in recent performance"
            ],
        },
        {
            date: "January 12, 2025",
            content: [
                "Fixed the sorting feature of the new player rankings leaderboard",
                "Added RP and classes to the last five games section in player searches. You can also now navigate to each games page on click",
                "Fixed user rankings in the overall rating chart",
                "You can now press enter to search instead of clicking the search button"
            ],
        },
        {
            date: "January 11, 2025",
            content: [
                "Added new leaderboard section",
                "Added new player ranking leaderboard, containing the ranked stats of every searched club player on Chel stats!",
                "Added top 100 club leaderboard and season rankings from the EA website",
                "Added back overall ratings for recent games",
                "Fixed some UI elements and stats"
            ],
        },
        {
            date: "January 7, 2025",
            content: [
                "Reverted how overall ratings work. It's now a hyrbid, taking the orginal calculation and balancing (with some minor tweaks) and combining this with new contributions with their visualization. Winger and Defensemen now also are less penalized for having lower faceoff % and deflections per game."
            ],
        },
        {
            date: "January 5, 2025",
            content: [
                "Updated the UI of many components",
                "Added Advanced stats to game pages. Including: WAR, GWAR, etc..",
                "Overhauled overall ratings so that each category is made up of the average of each contributing weighted stat. Adjusted weights to adapt to the overhaul.",
                "Updated Club stats pages, including updated UI, last 10 record, added more stats to the player stats table, and made those stats per game toggleable",
                "Updated the search fields to be more effecient and now player/club search toggleable",
                "Fixed some stats decimal places"
            ],
        },
        {
            date: "November 20, 2024",
            content: [
                "Updated Overall Rating weights to make it easier for wingers to have better handeye by increasing Faceoff % and Deflections weights. Reduced weights all around to adapt to 25s gameplay including: Reduced weight for Pass %, Assists per game, Offsides per game. Also increases weight for Defenders for Hits per game and Penalties drawn per game for wingers.",
                "Updated some UI elements",
                "League updates:",
                "Added game records to league home pages, team pages, and player profiles",
                "Updated nav bar styling",
                "Made goalie leaderboards auto sort by GAA",
                "Added overall rating visualization",
            ],
        },
        {
            date: "October 12, 2024",
            content: [
                "Added new player stats: Breakaway goals/attempts, Penalty shot goals/attempts, PIM (non-fight) ",
                "Added percentages for the club stats distribution chart",
                "Changed Physicality ratings to now use PIM (non-fight) instead of total PIMs",
                "Updated club roster section, adding new DNF, quit counts, and captaincy",
                "Fixed the club leader sections to sort properly",
                "Updated some UI elements"
            ],
        },
        {
            date: "October 2, 2024",
            content: [
                "Added new club stats: Rank points, Average win margin, Average loss margin",
                "Added more club leader sections: Handeye, Hitters",
                "Changed club overall rating visualization",
                "Fixed/Changed the UI of many components to freshen everything up",
                "Added more error handling to searches"
            ],
        },
        {
            date: "September 30, 2024",
            content: [
                "Added new login method",
                "Added new outline for player cards that will change color depending on the players win % and overall rating. This is to generate uniqueness for each player and represent their skill",
                "Added new overall rating breakdowns",
                "Added club overall ratings",
                "Added club member stats distribution graph",
                "Added new defensive stat: GTIR (Giveaways, Takeaways, Interceptions ratio",
                "Added search bars to the home page",
                "Updated some UI elements",
                "Removed login with discord and dicord features (highlights and imagine not scoring there)",
                "Removed Agency (Free agents and scouting clubs)",

            ],
        },
        {
            date: "May 22, 2024",
            content: [
                "Added the ability for any user to save player/club searches for quick access from the home page",
                "Added button links for club navigation from the recent game pages",
                "Moved the home pages about info to it's own separate page"
            ],
        },
        {
            date: "April 29, 2024",
            content: [
                "Chel stats 3.0!",
                "Welcome to the 3rd rendition of Chel stats, where I've completely rewrote the website using the React framework. This change allows for an incredible upgrade to responsiveness in terms of functionality and design as well as a long list of improvements as described below:",
                "Added completely new design/styling using MUI",
                "All data is no longer stored in local storage and loads directly from taking URL parameters and completely refreshes on each page load. This also means links are sharable and direct users to specific player/club stat endpoints.",
                "Updated stat viewing components across the board",
                "Added the ability to login with Discord which provides a plethora of new features such as: Saving stat profiles, utilizing discord IDs to direct users to contact other users, discord server message extraction (for highlights/Imagine not scoring there pages), role identication (For disabling ads)",
                "Added overall rating rankings to the radar chart, as well as website-wide comparisons, and recent performance comparisons",
                "Added the ability for users to set their saved profile as a free agent to be viewable in the free agents page",
                "Added the ability for clubs to set their scouting preferences to be viewable in the scouting page, as well as the abiity to link their discord servers for users to join as they please",
                "Added new features for recent game viewing like: ChatGPT generated game writeups, simulated scoring timelines, and three stars",
                "Chel stats | EDGE, a brand new API for leagues featuring advanced statistics like WAR, makes it's way onto the site where it provides league statistic hosting for many of the top leagues around. Feel free to explore and join leagues in the league directory!",

            ],
        },
        // More entries here...
    ];

    useEffect(() => {
        document.title = `Chel stats | Change Log`;
    }, []);

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'white', marginY: 5 }}>
                Change Log
            </Typography>
            {changes.map((change, index) => (
                <Paper key={index} elevation={10} sx={{ margin: 2, padding: 2, backgroundColor: 'transparent', border: 'solid #727373 .001rem' }}>
                    <Typography variant="h6" sx={{color: 'white'}}>{change.date}</Typography>
                    {change.content.map((item, idx) => (
                        <Typography key={idx} paragraph sx={{color: 'white'}}>
                            - {item}
                        </Typography>
                    ))}
                </Paper>
            ))}

        </Container>
    );
};

export default ChangeLog;
