import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box } from '@mui/material';

function ScoringTimelineDisplay({ scoringTimeline, teamID1, teamID2, imageUrls1, imageUrls2 }) {
    const [currentImage1, setCurrentImage1] = useState(imageUrls1[0]);
    const [currentImage2, setCurrentImage2] = useState(imageUrls2[0]);

    const handleImageError1 = () => {
        const nextIndex = imageUrls1.indexOf(currentImage1) + 1;
        if (nextIndex < imageUrls1.length) {
            setCurrentImage1(imageUrls1[nextIndex]);
        }
    };

    const handleImageError2 = () => {
        const nextIndex = imageUrls2.indexOf(currentImage2) + 1;
        if (nextIndex < imageUrls2.length) {
            setCurrentImage2(imageUrls2[nextIndex]);
        }
    };

    // Move the definition of processTimeline before its use
    const processTimeline = (timeline) => {
        if (!Array.isArray(timeline)) {
            console.error('Invalid timeline:', timeline);
            return {}; // or return {}, depending on how you want to handle this case
        }
        const goalsByPeriod = {};
        const cumulativeGoals = {}; // Tracks cumulative goals by player

        timeline.forEach((goal) => {
            // Initialize period in goalsByPeriod if it doesn't exist
            if (!goalsByPeriod[goal.periodNumber]) {
                goalsByPeriod[goal.periodNumber] = [];
            }

            // Update cumulative goal count for the scorer
            if (!cumulativeGoals[goal.scorer]) {
                cumulativeGoals[goal.scorer] = 0;
            }
            cumulativeGoals[goal.scorer] += 1;

            // Add goal to the period with the current cumulative count for the scorer
            goalsByPeriod[goal.periodNumber].push({
                ...goal,
                cumulativeGoals: cumulativeGoals[goal.scorer], // Include the cumulative goal count up to this goal
            });
        });

        return goalsByPeriod;
    };

    useEffect(() => {
        if (imageUrls1.length > 0) {
            setCurrentImage1(imageUrls1[0]);
        }
        if (imageUrls2.length > 0) {
            setCurrentImage2(imageUrls2[0]);
        }
    }, [imageUrls1, imageUrls2]);

    // Now it's safe to call processTimeline
    const goalsByPeriod = processTimeline(scoringTimeline);

    return (
        <Box sx={{ margin: 'auto' }}>
            {Object.entries(goalsByPeriod).map(([period, goals]) => (
                <Box key={period} sx={{ marginBottom: 4 }}>
                    <Typography sx={{ marginBottom: 2, color: 'grey.500', marginLeft: 2, fontSize: '18px' }}>{period == 1 ? '1st' : period == 2 ? '2nd' : period == 3 ? '3rd' : 'OT'} Period</Typography>
                    {goals.map((goal, index) => (
                        <Paper key={index} elevation={3} sx={{ margin: 1, marginBottom: 1.5, padding: 2, paddingY: 3, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, position: 'relative' }}>
                                <Box sx={{ flexDirection: 'column', alignContent: 'center', position: 'relative', marginRight: 7 }}>
                                    <Typography sx={{ color: 'white', textAlign: 'center', position: 'absolute', bottom: 11, left: 3}}>{(goal.teamName.substring(0, 3) || "").toUpperCase()}</Typography>
                                    <img
                                        src={goal.teamID === teamID1 ? currentImage1 : currentImage2}
                                        alt="Team Logo"
                                        style={{ width: 45, height: 45, position: 'absolute', bottom: -40, left: -3 }}
                                        onError={goal.teamID === teamID1 ? handleImageError1 : handleImageError2}
                                    />
                                </Box>
                                <Box>
                                    <Typography sx={{ color: 'white' }}>
                                        <Typography component="span" sx={{ fontWeight: 500, color: 'white' }}>
                                            {goal.scorer}
                                        </Typography>

                                        <Typography component="span" sx={{ fontWeight: 'normal', color: 'grey.500' }}>
                                            &#8239;({goal.cumulativeGoals})
                                        </Typography>

                                    </Typography>
                                    <Typography sx={{ color: 'white', display: 'inline' }}>
                                        {goal.timestamp}
                                        <Typography component="span" sx={{ color: 'grey.500' }}>
                                            {goal.assists && goal.assists.length > 0
                                                ? ` - Assisted by ${goal.assists.join(" and ")}`
                                                : " - Unassisted"}
                                        </Typography>
                                    </Typography>

                                </Box>
                            </Box>
                        </Paper>
                    ))}
                </Box>
            ))}
        </Box>
    );
}

export default ScoringTimelineDisplay;
