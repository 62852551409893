import React from "react";
import { Box, styled, Tooltip, Typography } from "@mui/material";

// Bar segment
const RatingSegment = styled("div")(
  ({ width, bgcolor, isFirst, isLast, isSecLast }) => ({
    height: "20px",
    flexBasis: `${width}%`, // interpret "width" as a percentage of the total bar
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: bgcolor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000",
    fontSize: "12px",
    position: "relative",
    borderTopLeftRadius: isFirst ? "20px" : "0px",
    borderBottomLeftRadius: isFirst ? "20px" : "0px",
    borderTopRightRadius: isLast ? "20px" : "0px",
    borderBottomRightRadius: isLast ? "20px" : "0px",
    borderRight: isSecLast ? "unset" : "solid",
  })
);

// Vertical line for net sum
const VerticalLine = styled("div")(({ position }) => ({
  position: "absolute",
  left: `${position}%`,
  top: "-10px",
  bottom: "-10px",
  width: "2px",
  backgroundColor: "#2196f3",
  zIndex: 1
}));

// Label for the net sum above the line
const SumLabel = styled("div")(({ position }) => ({
  position: "absolute",
  top: "-35px",
  left: `${position}%`,
  transform: "translateX(-50%)",
  color: "#2196f3",
  fontSize: "16px",
  fontWeight: "bold",
  zIndex: 2
}));

/**
 * Displays positives + negatives in one bar:
 *  - netSum = sum of stats, clamped to [0..99]
 *  - vertical line at netSum
 *  - each stat's segment width = fraction of absolute values
 *  - negative stats in red, positive in blue
 *  - leftover (gray) = whatever % isn't used by rendered segments
 */
export default function CategoryContributionChart({ ratings, label }) {
  const entries = Object.entries(ratings);

  // 1) totalAbs = sum of absolute values
  const totalAbs = entries.reduce((acc, [_, val]) => acc + Math.abs(val), 0);

  // 2) netSum = sum of values (rounded)
  let netSum = Math.round(entries.reduce((acc, [_, val]) => acc + val, 0));
  // 3) clamp netSum to [0..99]
  if (netSum < 0) netSum = 0;
  if (netSum > 99) netSum = 99;

  // The vertical line’s position
  const linePosition = (netSum / 99) * 100;

  // Build array of segments. Skip any that are too small (< 0.5%).
  const allSegments = entries
    .map(([statName, statValue], idx) => {
      const absVal = Math.abs(statValue);
      const fractionOfAbs = totalAbs > 0 ? absVal / totalAbs : 0;
      const statUnits = fractionOfAbs * netSum;
      const widthPercent = (statUnits / 99) * 100;

      const isNegative = statValue < 0;
      const segmentColor = isNegative ? "#f44336" : "#2196f3";

      return {
        idx,
        statName,
        statValue,
        widthPercent,
        segmentColor
      };
    })
    // Filter out any segments that are 0.5% or below, as you do now
    .filter((seg) => seg.widthPercent > 0.5);

  // 4) sum of rendered segments
  const sumOfRendered = allSegments.reduce(
    (acc, seg) => acc + seg.widthPercent,
    0
  );

  // 5) leftover = fill up to 100% total
  let leftover = 100 - sumOfRendered;
  if (leftover < 0) leftover = 0; // clamp

  return (
    <Box>
      <Typography
        variant="h6"
        color="white"
        sx={{ mb: 1, fontSize: "14px" }}
      >
        {label} Rating
      </Typography>

      <Box
        sx={{
          marginTop: 2,
          borderRadius: "20px",
          backgroundColor: "#e0e0e0",
          height: "20px",
          position: "relative"
        }}
      >
        {/* The line + label at netSum */}
        <VerticalLine position={linePosition} />
        <SumLabel position={linePosition}>{netSum}</SumLabel>

        <Box display="flex" width="100%" height="100%" position="relative">
          {allSegments.map((seg, i) => {
            const isFirst = i === 0;
            // Mark as last if it’s the last in *this* array & leftover = 0
            const isLast = i === allSegments.length - 1 && leftover <= 0;
            const isSecLast = i === allSegments.length - 1;

            return (
              <Tooltip
                key={seg.statName}
                title={`${seg.statName} contribution:\n${seg.statValue.toFixed(
                  1
                )}`}
                arrow
                placement="top"
                enterTouchDelay={1}
                leaveDelay={1000}
              >
                <RatingSegment
                  width={seg.widthPercent}
                  bgcolor={seg.segmentColor}
                  isFirst={isFirst}
                  isLast={isLast}
                  isSecLast={isSecLast}
                >
                  {Math.round(seg.statValue)}
                </RatingSegment>
              </Tooltip>
            );
          })}

          {/* leftover if there's any space left to 100% */}
          {leftover > 0 && (
            <RatingSegment
              width={leftover}
              bgcolor="#bdbdbd"
              isLast={true}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
