import React, { useState, useEffect } from 'react';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Typography, Box, Switch, Modal, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useLoading } from '../../Contexts/LoadingContext';
import { PropagateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

function PlayerStatsTable({ clubData, clubInfo }) {
    const players = clubData.memberData;
    const [orderDirection, setOrderDirection] = useState('desc');
    const [orderBy, setOrderBy] = useState('overallRating.Overall Rating');
    const [isPerGame, setIsPerGame] = useState(false); // New state for toggle
    const { loading, setLoading } = useLoading();
    const [modalOpen, setModalOpen] = useState(false);
    function getValueByPath(obj, path) {
        if (!path) return obj;
        const properties = path.replace(/\[(\w+)\]/g, '.$1').split('.');
        if (path == 'Breakaway save %' || path == 'Penalty shot save %') {
            return parseFloat(obj[path]).toFixed(3);
        } else {
            return properties.reduce((acc, curr) => acc && acc[curr], obj);
        }
    }

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const exemptFromPerGame = ['Penalty shot save %', 'Breakaway save %', 'FO %', 'Fights Won %', 'Breakaway %', 'PS %', 'Username', 'Position', 'overallRating.Overall Rating', 'Games Played', 'DNF', 'playerQuitDisc', 'Shot %', 'Pass %', 'Record', 'Platform', 'Win %', 'GAA', 'Save %', 'Goalie games played', 'Goals allowed', 'Possession per game']; // Add any stats that shouldn't be per-game here

    const goalieStats = [
        'Goalie games played',
        'Goalie wins',
        'Goals allowed',
        'GAA',
        'Save %',
        'Goalie shots',
        'Goalie saves',
        'Shutouts',
        'Shutout periods'
        // Add any other goalie-specific stats here
    ];

    function timeStringToSeconds(timeString) {
        if (!timeString) return 0; // Handle cases where time might be empty or null
        const parts = timeString.split(':');
        return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
    }

    const sortedPlayers = React.useMemo(() => {
        return [...players].sort((a, b) => {
            let valueA = getValueByPath(a, orderBy);
            let valueB = getValueByPath(b, orderBy);

            // If per-game stats are enabled, calculate per-game values for sorting
            if (orderBy === 'Possession per game') {
                valueA = timeStringToSeconds(valueA);
                valueB = timeStringToSeconds(valueB);
            }
            else if (isPerGame && !exemptFromPerGame.includes(orderBy)) {
                const gamesPlayedStat = goalieStats.includes(orderBy) ? 'Goalie games played' : 'Games Played';
                const gamesPlayedA = getValueByPath(a, gamesPlayedStat);
                const gamesPlayedB = getValueByPath(b, gamesPlayedStat);
                valueA = gamesPlayedA > 0 ? (valueA / gamesPlayedA) : 0;
                valueB = gamesPlayedB > 0 ? (valueB / gamesPlayedB) : 0;
            }

            const textCols = ['Username', 'Position', 'Platform'];
            if (textCols.includes(orderBy)) {
                const lowerA = valueA.toLowerCase();
                const lowerB = valueB.toLowerCase();
                if (lowerA < lowerB) return orderDirection === 'asc' ? -1 : 1;
                if (lowerA > lowerB) return orderDirection === 'asc' ? 1 : -1;
            } else {
                const numA = Number(valueA);
                const numB = Number(valueB);
                if (numA < numB) return orderDirection === 'asc' ? -1 : 1;
                if (numA > numB) return orderDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [players, orderBy, orderDirection, isPerGame]); // Include isPerGame in the dependency array // Include isPerGame in the dependency array // Include isPerGame in the dependency array


    const statMapping = {
        "Username": "Player",
        "Position": "Pos",
        "overallRating.Overall Rating": "OVR", // Adjusted path for nested property
        "Games Played": "GP",
        "DNF": "DNF",
        "playerQuitDisc": "Quits",
        "Record": "Record",
        "Win %": "Win%",
        "Goals": "G",
        "Assists": "A",
        "Points": "P",
        "+/-": "+/-",
        "Possession per game": "Poss per game",
        "PIM": "PIM",
        "PIMs minus fights": "PIM (NF)",
        "Penalties Drawn": "PenD",
        "GWGs": "GWGs",
        "Power play goals": "PPGs",
        "EVGs": "EVGs",
        "Shots": "SOG",
        "Shot %": "S%",
        "skbrkgoals": "BWGs",
        "skbreakaways": "BWAs",
        "Breakaway %": "BW%",
        "penaltyshotgoals": "PSGs",
        "skpenaltyattempts": "PSAs",
        "PS %": "PS%",
        "Passes": "Ps",
        "Pass attempts": "PAs",
        "Pass %": "P%",
        "Saucer passes": "SPs",
        "Dekes": "DKAs",
        "Dekes Made": "DKs",
        "Deflections": "Defl",
        "Faceoffs": "FOs",
        "Faceoff wins": "FOWs",
        "FO %": "FO%",
        "Giveaways": "Gvwys",
        "Hits": "Hits",
        "Takeaways": "Tkwys",
        "Interceptions": "Ints",
        "Blocked shots": "BS",
        "Fights": "Fs",
        "Fights Won": "FWs",
        "Fights Won %": "FW%",
        "Goalie games played": "GGPs",
        "Goalie wins": "Ws",
        "Goals allowed": "GA",
        "GAA": "GAA",
        "Save %": "SV%",
        "Goalie shots": "SA",
        "Goalie saves": "SVs",
        "Shutouts": "SOs",
        "Shutout periods": "SOPs",
        "Diving saves": "DVS",
        "Goalie pokechecks": "PKs",
        "Breakaway saves": "BWSs",
        "Breakaway save %": "BWS%",
        "Penalty shot saves": "PSS",
        "Penalty shot save %": "PSS%",
        // Additional stats as needed...
    };

    const statExplain = {
        "Player": "Username",
        "Pos": "Position",
        "OVR": "Overall rating",
        "GP": "Games played",
        "DNF": "Games not finished",
        "Quits": "Times player disconnected",
        "Record": "Player's win-loss record",
        "Win%": "Percentage of games won",
        "G": "Goals",
        "A": "Assists",
        "P": "Points",
        "+/-": "Plus minus",
        "Poss per game": "Average possession time per game",
        "PIM": "Penalty minutes",
        "PIM (NF)": "Penalty minutes without fights",
        "PenD": "Penalties drawn",
        "GWGs": "Game-winning goals",
        "PPGs": "Powerplay goals",
        "EVGs": "Even strength goals",
        "SOG": "Shots on goal",
        "S%": "Shooting percentage",
        "BWGs": "Breakaways goals",
        "BWAs": "Breakaways attempted",
        "BW%": "Breakaway scoring percentage",
        "PSGs": "Penalty shot goals",
        "PSAs": "Penalty shots attempted",
        "PS%": "Penalty shot scoring percentage",
        "Ps": "Passes",
        "PAs": "Pass attempts",
        "P%": "Passing percentage",
        "SPs": "Saucer passes made",
        "DKAs": "Dekes attempted",
        "DKs": "Dekes succeeded",
        "Defl": "Deflections",
        "FOs": "Faceoffs taken",
        "FOWs": "Faceoffs won",
        "FO%": "Faceoff win percentage",
        "Gvwys": "Giveaways",
        "Hits": "Hits given",
        "Tkwys": "Takeaways",
        "Ints": "Interceptions",
        "BS": "Shots blocked",
        "Fs": "Fights",
        "FWs": "Fights won",
        "FW%": "Fight win percentage",
        "GGPs": "Games played as goalie",
        "Ws": "Games won as goalie",
        "GA": "Goals against",
        "GAA": "Goals against average",
        "SV%": "Save percentage",
        "SA": "Shots faced",
        "SVs": "Saves made",
        "SOs": "Shutout games",
        "SOPs": "Shutout periods",
        "DVS": "Diving saves",
        "PKs": "Pokechecks",
        "BWSs": "Breakaway saves",
        "BWS%": "Breakaway save percentage",
        "PSS": "Penalty shot saves",
        "PSS%": "Penalty shot save percentage"
    };


    // Function to calculate per game stat if applicable
    const calculateStat = (player, statKey) => {
        // Determine which games played stat to use based on whether the stat is for goalies
        const gamesPlayedStat = goalieStats.includes(statKey) ? 'Goalie games played' : 'Games Played';

        // Check if the stat should be exempt from per-game conversion
        if (exemptFromPerGame.includes(statKey)) {
            return getValueByPath(player, statKey);
        }

        if (isPerGame) {
            const total = getValueByPath(player, statKey);
            const gamesPlayed = getValueByPath(player, gamesPlayedStat);
            return gamesPlayed > 0 ? (total / gamesPlayed).toFixed(2) : '0';
        }
        return getValueByPath(player, statKey);
    };

    function createSvgElement(consolegen, username) {

        if (clubInfo != "") {
            if (username == clubInfo.info.captain) {
                username = username + "\u2800'C'"

            } else if (username == clubInfo.info.alternateCaptain1 || username == clubInfo.info.alternateCaptain2) {
                username = username + "\u2800'A'"

            }
        }
        // PlayStation SVG
        if (consolegen === "ps5" || consolegen === "ps4") {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <svg width="20" height="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="M399.77 203c-.8-17.1-3.3-34.5-10.8-50.1a82.45 82.45 0 00-16.5-23.2 105.59 105.59 0 00-21.3-16.3c-17.1-10.2-37.5-17-84.4-31S192 64 192 64v358.3l79.9 25.7s.1-198.8.1-299.5v-3.8c0-9.3 7.5-16.8 16.1-16.8h.5c8.5 0 15.5 7.5 15.5 16.8V278c11 5.3 29.2 9.3 41.8 9.1a47.79 47.79 0 0024-5.7 49.11 49.11 0 0018.4-17.8 78.64 78.64 0 009.9-27.3c1.87-10.8 1.97-22.1 1.57-33.3zM86.67 357.8c27.4-9.8 89.3-29.5 89.3-29.5v-47.2s-76.5 24.8-111.3 37.1c-8.6 3.1-17.3 5.9-25.7 9.5-9.8 4.1-19.4 8.7-28.1 14.8a26.29 26.29 0 00-9.2 10.1 17.36 17.36 0 00-.5 13.6c2 5.1 5.8 9.3 10.1 12.6 7.8 5.9 17.1 9.5 26.4 12.2a262.42 262.42 0 0088.4 13.3c14.5-.2 36-1.9 50-4.4v-42s-11 2.5-41.3 12.5c-4.6 1.5-9.2 3.3-14 4.3a104.87 104.87 0 01-21.6 2.2c-6.5-.3-13.2-.7-19.3-3.1-2.2-1-4.6-2.2-5.5-4.6-.8-2 .3-4 1.7-5.4 2.8-2.9 6.8-4.5 10.6-6z" fill="rgb(0, 111, 205)" />
                        <path d="M 512 345.9 c -0.1 -6 -3.7 -11.2 -7.9 -15 c -7.1 -6.3 -15.9 -10.3 -24.7 -13.5 c -5.5 -1.9 -9.3 -3.3 -14.7 -5 c -25.2 -8.2 -51.9 -11.2 -78.3 -11.3 c -8 0.3 -23.1 0.5 -31 1.4 c -21.9 2.5 -67.3 15.4 -67.3 15.4 v 48.8 s 67.5 -21.6 96.5 -31.8 a 94.43 94.43 0 0 1 30.3 -4.6 c 6.5 0.2 13.2 0.7 19.4 3.1 c 2.2 0.9 4.5 2.2 5.5 4.5 c 0.9 2.6 -0.9 5 -2.9 6.5 c -4.7 3.8 -10.7 5.3 -16.2 7.4 c -41 14.5 -132.7 44.7 -132.7 44.7 v 47 s 117.2 -39.6 170.8 -58.8 c 8.9 -3.3 17.9 -6.1 26.4 -10.4 c 7.9 -4 15.8 -8.6 21.8 -15.3 a 19.74 19.74 0 0 0 5 -13.1 Z" fill="rgb(0, 111, 205)" />
                    </svg>
                    <Typography sx={{ fontSize: '14px', marginLeft: '15px' }}>{username}</Typography>
                </Box>
            );
        }
        // Xbox SVG
        else if (consolegen === "xbsx" || consolegen === "xone") {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <svg width="20" height="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="M 126.8 248.3 c 39.7 -58.6 77.9 -92.8 77.9 -92.8 s -42.1 -48.9 -92.8 -67.4 l -3.3 -0.8 A 224.13 224.13 0 0 0 77.2 391 c 0 -4.4 0.6 -70.3 49.6 -142.7 Z M 480 256 a 223.71 223.71 0 0 0 -76.6 -168.7 l -3.2 0.9 c -50.7 18.5 -92.9 67.4 -92.9 67.4 s 38.2 34.2 77.9 92.8 c 49 72.4 49.6 138.3 49.5 142.7 A 222.8 222.8 0 0 0 480 256 Z M 201.2 80.9 c 29.3 13.1 54.6 34.6 54.6 34.6 s 25.5 -21.4 54.8 -34.6 c 36.8 -16.5 64.9 -11.3 72.3 -9.5 a 224.06 224.06 0 0 0 -253.8 0 c 7.2 -1.8 35.2 -7.1 72.1 9.5 Z M 358.7 292.9 C 312.4 236 255.8 199 255.8 199 s -56.3 37 -102.7 93.9 c -39.8 48.9 -54.6 84.8 -62.6 107.8 l -1.3 4.8 a 224 224 0 0 0 333.6 0 l -1.4 -4.8 c -8 -23 -22.9 -58.9 -62.7 -107.8 Z" fill="rgb(16, 124, 16)" />
                    </svg>
                    <Typography sx={{ fontSize: '14px', marginLeft: '15px' }}>{username}</Typography>
                </Box>
            );
        }
        // Default or no consolegen provided
        return null;
    }

    const navigate = useNavigate();

    const handleRowClick = (username, teamname, consolegen) => {
        navigate(`/players?username=${username}&teamname=${teamname}&console=${consolegen}`);
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    // Modal style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        paddingBottom: 6,
        color: 'white',
        backgroundColor: '#121212',
        overflowY: 'auto',
        maxHeight: '60vh'
    };

    const handlePerGameToggle = (event, newMode) => {
        if (newMode !== null) {
            setIsPerGame(newMode === 'perGame');
        }
    };

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><PropagateLoader color="#3498db" /></div>;
    }

    return (
        <Box>
            <Box display="flex" mb={2}>
                <Box display="flex" mt={2} ml={1} justifyContent={'space-between'} alignItems="center" width="100%">
                    <Box>
                        <Paper elevation={3} sx={{ bgcolor: '#1f1f1f', position: 'relative', borderRadius: '20px', overflow: 'hidden', height: '30px', width: '180px' }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '50%',
                                    height: '100%',
                                    bgcolor: '#1976d2',
                                    transform: isPerGame ? 'translateX(100%)' : 'translateX(0)',
                                    transition: 'transform 0.3s ease-in-out',
                                    borderRadius: '20px',
                                    textAlign: 'center'
                                }}
                            />
                            <ToggleButtonGroup
                                value={isPerGame ? 'perGame' : 'career'}
                                exclusive
                                onChange={handlePerGameToggle}
                                sx={{
                                    '& .MuiToggleButtonGroup-grouped': {
                                        border: 0,
                                        borderRadius: '20px',
                                        width: '90px',
                                        height: '30px'
                                    },
                                }}
                            >
                                <ToggleButton value="career" sx={{ color: 'white', zIndex: 1, bgcolor: 'transparent', fontSize: '12px' }}>
                                    Career
                                </ToggleButton>
                                <ToggleButton value="perGame" sx={{ color: 'white', zIndex: 1, bgcolor: 'transparent', fontSize: '12px' }}>
                                    Per Game
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Paper>
                    </Box>
                    <Button variant="contained" size="small" onClick={toggleModal} sx={{ mr: 1 }}>Legend</Button>
                </Box>
            </Box>

            <Modal
                open={modalOpen}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    ...style,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative' // Relative positioning for child elements
                }}>
                    <Box sx={{
                        flex: '1 1 auto',
                        overflowY: 'auto',
                        maxHeight: 'calc(100% - 60px)' // Subtract space for the button
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2, color: 'white' }}>
                            Stats Legend
                        </Typography>
                        {Object.entries(statExplain).map(([statKey, explanation]) => (
                            <Typography key={statKey} sx={{ mb: 1.5, color: 'white' }}>
                                <strong>{statKey}:</strong> {explanation}
                            </Typography>
                        ))}
                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '15px',
                        mb: 0,
                        zIndex: 1000 // Ensure button stays on top of scrolling content
                    }}>
                        <Button onClick={toggleModal} sx={{ color: 'white' }}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <TableContainer component={Paper} sx={{
                minHeight: 250, overflow: 'auto', backgroundColor: '#121212',
                '&::-webkit-scrollbar': { width: '0px' },
                /* Track */
                '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 5px #121212', borderRadius: '10px' },
                /* Handle */
                '&::-webkit-scrollbar-thumb': { background: '#1976d2', borderRadius: '10px' },
                /* Handle on hover */
                '&::-webkit-scrollbar-thumb:hover': { background: '#303030' }
            }}>
                <Table stickyHeader aria-label="player stats table">
                    <TableHead>
                        <TableRow sx={{ borderBottom: 'none', color: '#474747' }}>
                            {Object.entries(statMapping).map(([statKey, label]) => (
                                <TableCell
                                    key={statKey}
                                    sortDirection={orderBy === statKey ? orderDirection : false}
                                    sx={{
                                        borderBottom: 'none',
                                        padding: '6px',
                                        fontSize: '13px',
                                        color: '#6B6B6B',
                                        '&:hover': { color: 'white' },
                                        position: statKey === 'Username' ? 'sticky' : undefined,
                                        left: statKey === 'Username' ? 0 : undefined,
                                        zIndex: statKey === 'Username' ? 999 : undefined,
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {statKey === 'Avatar' || statKey === 'Platform' ? null : (
                                        <TableSortLabel
                                            active={orderBy === statKey}
                                            direction={orderBy === statKey ? orderDirection : 'asc'}
                                            onClick={() => handleSortRequest(statKey)}
                                            sx={{
                                                color: 'white',
                                                '& .MuiTableSortLabel-icon': { color: 'white !important' },
                                                '&.Mui-active': { color: '#1976d2', '& .MuiTableSortLabel-icon': { color: '#1976d2 !important' } },
                                                '&:hover': { color: '#1976d2', '& .MuiTableSortLabel-icon': { color: '#1976d2 !important' } },
                                            }}
                                        >
                                            {label}
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPlayers.map((player, index) => (
                            <TableRow key={index} hover style={{ cursor: 'pointer', borderBottom: 'none' }} onClick={() => handleRowClick(player.Username, clubData.teamData.name, clubData.teamData.platform)}>
                                {Object.keys(statMapping).map((statKey) => (
                                    <TableCell key={statKey} sx={statKey === 'Username' ? { paddingLeft: '0px', borderBottom: 'none', fontSize: '10px', position: 'sticky', left: 0, zIndex: 998, color: 'white', backgroundColor: '#121212' } : { borderBottom: 'none', padding: '8px', fontSize: '14px', color: 'white', whiteSpace: 'nowrap' }}>
                                        {statKey === 'Avatar' ? null : statKey === 'Platform' ? null : statKey === 'Username' ? (createSvgElement(player.Platform, player.Username)) : statKey === 'Save %' ? ((parseFloat(player['Save %']) / 100).toFixed(3)) : statKey === 'Win %' ? ((parseInt(player['Wins']) / parseInt(player['Games Played'])) * 100).toFixed(1) : (
                                            calculateStat(player, statKey)
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default PlayerStatsTable;