import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, LinearProgress, styled } from '@mui/material';

// Styled progress bar
const RatingProgress = styled(LinearProgress)({
    height: '5px',
    borderRadius: '10px',
    '& .MuiLinearProgress-bar': {
        borderRadius: '10px',
        backgroundColor: '#2196f3', // Set your preferred filled color
        transition: 'transform 1s ease-in-out', // Animation for the fill
    },
    '&.MuiLinearProgress-root': {
        backgroundColor: '#B9BABA', // Background color for unfilled part
    },
});

const OverallRatingBar = ({ rating, category }) => {
    const [progress, setProgress] = useState(0); // State for animation
    const [isInView, setIsInView] = useState(false); // Track if bar is in view
    const progressRef = useRef(null); // Reference to the progress bar

    const validRating = isFinite(rating) ? rating : 0;

    const normalizedRating = (validRating / 99) * 100; // Normalizing the rating to a percentage

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting) {
                    setIsInView(true);
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the bar is in view
        );
        if (progressRef.current) {
            observer.observe(progressRef.current);
        }
        return () => {
            if (progressRef.current) {
                observer.unobserve(progressRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isInView) {
            setTimeout(() => {
                setProgress((normalizedRating / 100) * 100); // Update progress when in view
            }, 100); // Add a small delay to trigger the animation
        }
    }, [isInView, normalizedRating]);


    return (
        <Box sx={{ margin: 'auto', width: '90%' }} ref={progressRef}>
            <Box position="relative" width="100%" height="20px">
                <Typography variant="caption" sx={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                    {`${isFinite(rating) ? rating : 0} OVR`}
                </Typography>
                <RatingProgress variant="determinate" value={progress} />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pointerEvents: 'none' }}
                >

                </Box>
            </Box>
        </Box>
    );
};

export default OverallRatingBar;
