import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography, Card, CardContent, Grid, Divider, Table, TableContainer, TableBody, TableRow, TableCell, TableHead, Paper } from '@mui/material';
import PositionalProgressBar from './PositionalProgressBar'
import OverallRatingBar from './OverallRatingBar'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveIcon from '@mui/icons-material/Remove';
import { useStats } from '../../Contexts/StatsContext';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryContributionChart from './CategoryContributionChart'
import StatContributionAccordion from './StatContributionAccordion'
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            style={{ height: '100%', overflow: 'auto', minHeight: windowWidth < 600 ? '200px' : windowWidth < 900 ? '100%' : '100%' }} // You might merge this with `...other` if passing styles via props
        >
            {value === index && (
                <Box sx={{ width: '100%', marginTop: 2 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StatsIndicator = ({ recent, career, label }) => {
    // Replace NaN or Infinity with 0
    const validRecent = isFinite(recent) ? recent : 0;
    const validCareer = isFinite(career) ? career : 0;

    let goodPerformance
    let badPerformance
    let neutralPerformance

    if (label == 'Dekes per game') {
        return (
            <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                <RemoveIcon sx={{ color: 'white', fontSize: '22px' }} />
            </Typography>

        )
    } else if (label == 'Giveaways per game' || label == 'Offsides per game' || label == 'PIMs' || label == 'PIMs minus fights') {
        goodPerformance = validRecent < validCareer;
        badPerformance = validRecent > validCareer;
        neutralPerformance = validRecent === validCareer;

        return (
            <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                {goodPerformance ? (
                    <ArrowUpwardIcon sx={{ color: 'green', fontSize: '22px' }} />
                ) : badPerformance ? (
                    <ArrowDownwardIcon sx={{ color: 'red', fontSize: '22px' }} />
                ) : neutralPerformance ? ( // This checks specifically for equality
                    <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
                ) : ( // This checks specifically for equality
                    <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
                ) // Just in case, though it should never be reached with the current logic
                }
            </Typography>
        );

    } else if (label == 'Possession per game') {
        const minsRec = recent.substring(0,2)
        const secsRec = recent.substring(3,5)
        const minsCar = career.substring(0,2)
        const secsCar = career.substring(3,5)

        const totalRec = (parseInt(minsRec) * 60) + parseInt(secsRec)
        const totalCar = (parseInt(minsCar) * 60) + parseInt(secsCar)


        goodPerformance = totalRec > totalCar;
        badPerformance = totalRec < totalCar;
        neutralPerformance = totalRec === totalCar;

        return (
            <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                {goodPerformance ? (
                    <ArrowUpwardIcon sx={{ color: 'green', fontSize: '22px' }} />
                ) : badPerformance ? (
                    <ArrowDownwardIcon sx={{ color: 'red', fontSize: '22px' }} />
                ) : neutralPerformance ? ( // This checks specifically for equality
                    <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
                ) : ( // This checks specifically for equality
                    <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
                ) // Just in case, though it should never be reached with the current logic
                }
            </Typography>
        );

    } else {

        goodPerformance = validRecent > validCareer;
        badPerformance = validRecent < validCareer;
        neutralPerformance = validRecent === validCareer;

        return (
            <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                {goodPerformance ? (
                    <ArrowUpwardIcon sx={{ color: 'green', fontSize: '22px' }} />
                ) : badPerformance ? (
                    <ArrowDownwardIcon sx={{ color: 'red', fontSize: '22px' }} />
                ) : neutralPerformance ? ( // This checks specifically for equality
                    <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
                ) : ( // This checks specifically for equality
                    <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
                ) // Just in case, though it should never be reached with the current logic
                }
            </Typography>
        );
    }
};

function displayStat(label, stat) {
    const excludeList = ['Possession per game', 'Possession time']
    if (label == 'Possession per game') {
        return stat
    }
    else if (label == 'Possession time') {
        return stat
    }
    else if (label == 'Dekes per game') {
        return stat
    }
    else if (stat == '-') {
        return '-'
    }
    else if (!isFinite(stat)) {
        return 0
    }
    else {
        return stat
    }
}

function displayRecentStat(label, stat) {
    const excludeList = ['Possession per game', 'Possession time']
    if (label == 'Possession per game') {
        return stat
    }
    else if (label == 'Possession time') {
        return stat
    }
    else if (label == 'Dekes per game') {
        return stat
    }
    else if (label.includes('%')) {
        return '-'
    }
    else if (stat == '-') {
        return '-'
    }
    else if (!isFinite(stat)) {
        return 0
    }
    else {
        return stat
    }
}


const RecentStatsTable = ({ stats, label, rating }) => (
    <TableContainer component={Paper} sx={{ marginBottom: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
        <Typography variant="h6" sx={{ marginTop: 1, color: 'white', marginBottom: 1 }}>{label}</Typography>
        <OverallRatingBar rating={rating} />

        <Table sx={{ marginTop: 3, minWidth: 100 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stats</TableCell>
                    <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Career</TableCell>
                    <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Recent</TableCell>
                    <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {stats.map((stat, index) => (
                    <TableRow
                        key={stat.name}
                        sx={{
                            '&:last-of-type td, &:last-of-type th': {
                                borderBottom: 'none', // Remove border bottom for the last row
                            },
                        }}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 450, color: 'white', border: 0 }}>{stat.name}</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 450, color: 'white', border: 0 }}>{displayStat(stat.name, stat.career)}</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 450, color: 'white', border: 0 }}>{displayStat(stat.name, stat.recent)}</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}><StatsIndicator recent={stat.recent} career={stat.career} label={stat.name} /></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);



const HockeyDataComponent = ({ playerStats }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { state } = useStats();
    const { positionState } = state;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    function createData(name, total, per_game) {
        return { name, total, per_game };
    }

    const OffensiveStats = [
        createData('Goals', playerStats.Goals, playerStats['Goals per game']),
        createData('Assists', playerStats.Assists, playerStats['Assists per game']),
        createData('Points', playerStats.Points, ((parseInt(playerStats['Goals']) + parseInt(playerStats['Assists'])) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('GWGs', playerStats.GWGs, (parseInt(playerStats.GWGs) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Hattricks', playerStats.Hattricks, (parseInt(playerStats.Hattricks) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('+/-', playerStats['+/-'], playerStats['+/- per game']),
    ];

    const Shooting = [
        createData('Goals', playerStats.Goals, playerStats['Goals per game']),
        createData('EV Goals', (parseInt(playerStats.Goals) - (parseInt(playerStats['Power play goals']) + parseInt(playerStats.penaltyshotgoals))), ((parseInt(playerStats.Goals) - (parseInt(playerStats['Power play goals']) + parseInt(playerStats.penaltyshotgoals))) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('PP Goals', playerStats['Power play goals'], (parseInt(playerStats['Power play goals']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Shots', playerStats.Shots, playerStats['Shots per game']),
        createData('Shot %', playerStats['Shot %'], '-'),
        createData('Shots on net %', playerStats['Shots on net %'], '-'),
        createData('Breakaway goals', playerStats.skbrkgoals, (parseInt(playerStats.skbrkgoals) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Breakaway attempts', playerStats.skbreakaways, (parseInt(playerStats.skbreakaways) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Breakaway %', playerStats['Breakaway %'], '-'),
        createData('PS goals', playerStats.penaltyshotgoals, (parseInt(playerStats.penaltyshotgoals) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('PS attempts', playerStats.skpenaltyattempts, (parseInt(playerStats.skpenaltyattempts) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('PS %', playerStats['PS %'], '-'),
    ];

    const Passing = [
        createData('Assists', playerStats.Assists, playerStats['Assists per game']),
        createData('Passes', playerStats.Passes, playerStats['Passes per game']),
        createData('Pass attempts', playerStats['Pass attempts'], playerStats['Pass attempts per game']),
        createData('Pass %', playerStats['Pass %'], '-'),
        createData('Saucer passes', playerStats['Saucer passes'], playerStats['Sauce per game']),
        createData('Giveaways', playerStats.Giveaways, playerStats['Giveaways per game']),
    ];

    const Handeye = [
        createData('Dekes', playerStats.Dekes, playerStats['Dekes per game']),
        createData('Faceoff %', playerStats['FO %'], '-'),
        createData('Faceoff wins', playerStats['Faceoff wins'], (parseInt(playerStats['Faceoff wins']) / parseInt(playerStats['Faceoffs'])).toFixed(2)),
        createData('Faceoff losses', playerStats['Faceoff losses'], (parseInt(playerStats['Faceoff losses']) / parseInt(playerStats['Faceoffs'])).toFixed(2)),
        createData('Possession time', playerStats['Possession time'], (playerStats['Possession per game'])),
        createData('Offsides', playerStats.Offsides, playerStats['Offsides per game']),
        createData('Deflections', playerStats.Deflections, playerStats['Deflections per game']),
    ];

    const Physicality = [
        createData('Hits', playerStats.Hits, playerStats['Hits per game']),
        createData('Penalties drawn', playerStats['Penalties Drawn'], playerStats['Penalties drawn per game']),
        createData('PIM', playerStats['PIM'], playerStats['PIMS per game']),
        createData('PIM (Non-fight)', (parseInt(playerStats['PIM']) - (parseInt(playerStats['Fights']) * 5)), parseFloat(playerStats['PIMs per game minus fights']).toFixed(2)),

        createData('Fights', playerStats['Fights'], (parseInt(playerStats['Fights']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Fights won', playerStats['Fights Won'], (parseInt(playerStats['Fights Won']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Fights won %', (parseFloat(playerStats['Fights Won %'])), '-'),
    ];

    const DefensiveStats = [
        createData('Hits', playerStats.Hits, playerStats['Hits per game']),
        createData('Interceptions', playerStats.Interceptions, playerStats['Interceptions per game']),
        createData('Blocked shots', playerStats['Blocked shots'], playerStats['Blocked shots per game']),
        createData('Takeaways', playerStats.Takeaways, playerStats['Takeaways per game']),
        createData('PK zone clears', playerStats['PK zone clears'], (parseInt(playerStats['PK zone clears']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('GTIR', ((parseInt(playerStats.Interceptions) + parseInt(playerStats.Takeaways)) - parseInt(playerStats.Giveaways)), '-'),

    ];

    const ZoneAbility = [
        createData('Goals', playerStats['Xfactor zone goals'], (parseInt(playerStats['Xfactor zone goals']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Assists', playerStats['Xfactor zone assists'], (parseInt(playerStats['Xfactor zone assists']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Hits', playerStats['Xfactor zone hits'], (parseInt(playerStats['Xfactor zone hits']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Stick checks', playerStats['Xfactor zone stick checks'], (parseInt(playerStats['Xfactor zone stick checks']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Times used', playerStats['Xfactor zone times used'], (parseInt(playerStats['Xfactor zone times used']) / parseInt(playerStats['Games Played'])).toFixed(1)),
    ];

    const SuperstarAbility = [
        createData('Goals', playerStats['Xfactor super-star goals'], (parseInt(playerStats['Xfactor super-star goals']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Assists', playerStats['Xfactor super-star assists'], (parseInt(playerStats['Xfactor super-star assists']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Hits', playerStats['Xfactor super-star hits'], (parseInt(playerStats['Xfactor super-star hits']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Stick checks', playerStats['Xfactor super-star stick checks'], (parseInt(playerStats['Xfactor super-star stick checks']) / parseInt(playerStats['Games Played'])).toFixed(1)),

    ];

    let recentDefensiveStats
    let recentCheckingStats
    let recentShootingStats
    let recentPassingStats
    let recentHandeyeStats
    let recentGoalieStats
    let recentGoalieStats2
    let recentGoalieStats3
    if (playerStats.playerRecentData != "noDataFound: false") {

        recentDefensiveStats = [
            { name: "+/- per game", recent: parseFloat(playerStats.playerRecentData.averages['+/- per game'],).toFixed(2), career: parseFloat(playerStats['+/- per game']).toFixed(2), },
            { name: "Blocks per game", recent: parseFloat(playerStats.playerRecentData.averages['Blocked shots per game'],).toFixed(2), career: parseFloat(playerStats['Blocked shots per game']).toFixed(2), },
            { name: "Interceptions per game", recent: parseFloat(playerStats.playerRecentData.averages['Interceptions per game'],).toFixed(2), career: parseFloat(playerStats['Interceptions per game']).toFixed(2), },
            { name: "Takeaways per game", recent: parseFloat(playerStats.playerRecentData.averages['Takeaways per game'],).toFixed(2), career: parseFloat(playerStats['Takeaways per game']).toFixed(2), },
            { name: "PK zone clears per game", recent: parseFloat(playerStats.playerRecentData.averages['PK zone clears per game'],).toFixed(2), career: (parseInt(playerStats['PK zone clears']) / parseInt(playerStats['Games Played'])).toFixed(1) },
        ];

        recentCheckingStats = [
            { name: "Hits per game", recent: parseFloat(playerStats.playerRecentData.averages['Hits per game'],).toFixed(2), career: parseFloat(playerStats['Hits per game']).toFixed(2), },
            { name: "Fights won %", recent: parseFloat(playerStats.playerRecentData.averages['Fights Won %'],).toFixed(1), career: parseFloat(playerStats['Fights Won %']).toFixed(1), },
            { name: "Pens drawn", recent: parseFloat(playerStats.playerRecentData.averages['Penalties drawn per game'],).toFixed(2), career: parseFloat(playerStats['Penalties drawn per game']).toFixed(2), },
            { name: "PIMs", recent: parseFloat(playerStats.playerRecentData.averages['PIMS per game'],).toFixed(2), career: parseFloat(playerStats['PIMS per game']).toFixed(2), },
            { name: "PIMs minus fights", recent: parseFloat(playerStats.playerRecentData.averages['PIMs per game minus fights'],).toFixed(2), career: parseFloat(playerStats['PIMs per game minus fights']).toFixed(2), },

        ];

        recentShootingStats = [
            { name: "Goals per game", recent: parseFloat(playerStats.playerRecentData.averages['Goals per game'],).toFixed(2), career: parseFloat(playerStats['Goals per game']).toFixed(2), },
            { name: "Points per game", recent: parseFloat(playerStats.playerRecentData.averages['PPG'],).toFixed(2), career: ((parseInt(playerStats['Goals']) + parseInt(playerStats['Assists'])) / parseInt(playerStats['Games Played'])).toFixed(2), },
            { name: "GWGs per game", recent: (parseInt(playerStats.playerRecentData.averages['Game winning goals']) / parseInt(playerStats.playerRecentData.averages['Games Played'])).toFixed(1), career: (parseInt(playerStats.GWGs) / parseInt(playerStats['Games Played'])).toFixed(1) },
            { name: "Shots per game", recent: parseFloat(playerStats.playerRecentData.averages['Shots per game'],).toFixed(2), career: parseFloat(playerStats['Shots per game']).toFixed(2), },
            { name: "Shot %", recent: parseFloat(playerStats.playerRecentData.averages['Shot %'],).toFixed(1), career: parseFloat(playerStats['Shot %']).toFixed(1), },
            { name: "Shots on net %", recent: parseFloat(playerStats.playerRecentData.averages['Shots on net %'],).toFixed(1), career: parseFloat(playerStats['Shots on net %']).toFixed(1), },
            { name: "PS %", recent: parseFloat(playerStats.playerRecentData.averages['PS %'],).toFixed(1), career: parseFloat(playerStats['PS %']).toFixed(1), },
            { name: "Brkwy %", recent: parseFloat(playerStats.playerRecentData.averages['Breakaway %'],).toFixed(1), career: parseFloat(playerStats['Breakaway %']).toFixed(1), },
        ];

        recentPassingStats = [
            { name: "Assists per game", recent: parseFloat(playerStats.playerRecentData.averages['Assists per game'],).toFixed(2), career: parseFloat(playerStats['Assists per game']).toFixed(2), },
            { name: "Passes per game", recent: parseFloat(playerStats.playerRecentData.averages['Passes per game'],).toFixed(2), career: parseFloat(playerStats['Passes per game']).toFixed(2), },
            { name: "Pass %", recent: parseFloat(playerStats.playerRecentData.averages['Pass %'],).toFixed(1), career: parseFloat(playerStats['Pass %']).toFixed(1), },
            { name: "Saucer passes per game", recent: (parseInt(playerStats.playerRecentData.averages['Saucer passes']) / parseInt(playerStats.playerRecentData.averages['Games Played'])).toFixed(1), career: parseFloat(playerStats['Sauce per game']).toFixed(2), },
            { name: "Pass attempts per game", recent: parseFloat(playerStats.playerRecentData.averages['Pass attempts per game'],).toFixed(2), career: parseFloat(playerStats['Pass attempts per game']).toFixed(2), },
            { name: "Giveaways per game", recent: parseFloat(playerStats.playerRecentData.averages['Giveaways per game'],).toFixed(2), career: parseFloat(playerStats['Giveaways per game']).toFixed(2), },
        ];

        recentHandeyeStats = [
            { name: "Faceoff %", recent: parseFloat(playerStats.playerRecentData.averages['FO %'],).toFixed(1), career: parseFloat(playerStats['FO %']).toFixed(1), },
            { name: "Offsides per game", recent: parseFloat(playerStats.playerRecentData.averages['Offsides per game'],).toFixed(2), career: parseFloat(playerStats['Offsides per game']).toFixed(2), },
            { name: "Deflections per game", recent: parseFloat(playerStats.playerRecentData.averages['Deflections per game'],).toFixed(2), career: parseFloat(playerStats['Deflections per game']).toFixed(2), },
            { name: "Possession per game", recent: playerStats.playerRecentData.averages['Possession per game'], career: playerStats['Possession per game'] },
            { name: "Dekes per game", recent: 'N/A', career: parseFloat(playerStats['Dekes per game']).toFixed(2), },
        ];

        recentGoalieStats = [
            { name: "Saves per game", recent: parseFloat(playerStats.playerRecentData.averages['Saves per game'],).toFixed(2), career: parseFloat(playerStats['Saves per game']).toFixed(2), },
            { name: "Shots against", recent: (parseInt(playerStats.playerRecentData.averages['Shots against']) / parseInt(playerStats.playerRecentData.averages['G Gps'])).toFixed(2), career: (parseInt(playerStats['Goalie shots']) / parseInt(playerStats['Goalie games played'])).toFixed(0) },
            { name: "Pokechecks per game", recent: (parseInt(playerStats.playerRecentData.averages['Pokechecks']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Goalie pokechecks']) / parseInt(playerStats['Goalie games played'])).toFixed(0) },
            { name: "Diving saves per game", recent: (parseInt(playerStats.playerRecentData.averages['Pokechecks']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Goalie pokechecks']) / parseInt(playerStats['Goalie games played'])).toFixed(0) },

        ];

        recentGoalieStats2 = [
            { name: "Breakaway saves", recent: parseFloat(playerStats.playerRecentData.averages['Breakaway saves'],).toFixed(2), career: parseFloat(playerStats['Breakaway saves']).toFixed(2), },
            { name: "Breakaway saves per game", recent: (parseInt(playerStats.playerRecentData.averages['Breakaway saves']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Breakaway saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2) },
            { name: "Breakaway save %", recent: parseFloat(playerStats.playerRecentData.averages['Breakaway %'],).toFixed(2), career: parseFloat(playerStats['Breakaway save %']).toFixed(2), },
            { name: "Penalty shot saves", recent: parseFloat(playerStats.playerRecentData.averages['Penalty shot saves'],).toFixed(2), career: parseFloat(playerStats['Penalty shot saves']).toFixed(2), },
            { name: "Penalty shot saves per game", recent: (parseInt(playerStats.playerRecentData.averages['Penalty shot saves']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Penalty shot saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2) },
            { name: "Penalty shot save %", recent: parseFloat(playerStats.playerRecentData.averages['PS %'],).toFixed(2), career: parseFloat(playerStats['Penalty shot save %']).toFixed(2), },
            { name: "Shutout periods", recent: parseFloat(playerStats.playerRecentData.averages['Shutout periods'],).toFixed(2), career: parseFloat(playerStats['Shutout periods']).toFixed(2), },

        ];

        recentGoalieStats3 = [
            { name: "GAA", recent: parseFloat(playerStats.playerRecentData.averages['GAA'],).toFixed(2), career: parseFloat(playerStats['Goals allowed']).toFixed(2), },
            { name: "Save %", recent: parseFloat(playerStats.playerRecentData.averages['Save %'],).toFixed(2), career: parseFloat(playerStats['Save %']).toFixed(2), },

        ];

    }

    const GoalieStats = [
        createData('Saves', playerStats['Goalie saves'], playerStats['Saves per game']),
        createData('Shots against', playerStats['Goalie shots'], (parseInt(playerStats['Goalie shots']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Pokechecks', playerStats['Goalie pokechecks'], playerStats['Poke checks per game']),
        createData('Diving saves', playerStats['Diving saves'], playerStats['Diving saves per game']),
    ];

    const GoalieStats2 = [
        createData('Breakaway saves', playerStats['Breakaway saves'], (parseInt(playerStats['Breakaway saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Breakaway shots against', playerStats['Breakaway shots against'], (parseInt(playerStats['Breakaway shots against']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Breakaway save %', playerStats['Breakaway save %'], '-'),
        createData('Penalty shot saves', playerStats['Penalty shot saves'], (parseInt(playerStats['Penalty shot saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Penalty shots against', playerStats['Penalty shots against'], (parseInt(playerStats['Penalty shots against']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Penalty shot save %', playerStats['Penalty shot save %'], '-'),
        createData('Shutouts', playerStats['Shutouts'], (parseInt(playerStats['Shutouts']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Shutout periods', playerStats['Shutout periods'], (parseInt(playerStats['Shutout periods']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
    ];

    const GoalieStats3 = [
        createData('Goals allowed', playerStats['Goals allowed'], playerStats.GAA),
        createData('Save %', playerStats['Save %'], '-'),
    ];

    const GoalieXfactorAbility = [
        createData('Saves', playerStats['Xfactor saves'], (parseInt(playerStats['Xfactor saves']) / parseInt(playerStats['Goalie games played'])).toFixed(1)),
        createData('Zone ability saves', playerStats['Xfactor zone ability saves'], (parseInt(playerStats['Xfactor zone ability saves']) / parseInt(playerStats['Goalie games played'])).toFixed(1)),
        createData('Superstar ability saves', playerStats['Xfactor superstar ability saves'], (parseInt(playerStats['Xfactor superstar ability saves']) / parseInt(playerStats['Goalie games played'])).toFixed(1)),
    ];

    const gamesPlayedObj = { 'C': parseInt(playerStats['C Gps']), 'LW': parseInt(playerStats['LW Gps']), 'RW': parseInt(playerStats['RW Gps']), 'D': parseInt(playerStats['D Gps']), 'G': parseInt(playerStats['Goalie games played']) }

    let recentSkaterStats
    let recentGoalieStatsHUH
    if (playerStats.playerRecentData != "noDataFound: false") {
        recentSkaterStats = (
            <TabPanel value={tabIndex} index={1}>

                <Box sx={{ width: '95%', margin: 'auto' }}>
                    <Typography variant="h6" sx={{ marginTop: 1, color: 'white', marginBottom: 1 }}>Overall rating</Typography>

                    <OverallRatingBar label="Overall" rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Overall Rating'])} />
                </Box>
                <Box sx={{ marginBottom: 5 }} />
                <Grid sx={{ maxWidth: '100%', marginTop: 5, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <RecentStatsTable stats={recentShootingStats} label="Shooting" rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['SHT'])} />
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            {/* <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['PSS'])} category={'PSS'} /> */}
                            <RecentStatsTable stats={recentPassingStats} label="Passing" rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['PSS'])} />
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            {/* <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['HND'])} category={'HND'} /> */}
                            <RecentStatsTable stats={recentHandeyeStats} label="Handeye" rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['HND'])} />
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            {/* <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['DEF'])} category={'DEF'} /> */}
                            {/* <Box sx={{ marginBottom: 4 }}></Box> */}
                            {/* <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['PHS'])} category={'PHS'} /> */}
                            <RecentStatsTable stats={recentCheckingStats} label="Physicality" rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['PHS'])} />
                        </Card>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            {/* <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['DEF'])} category={'DEF'} /> */}
                            {/* <Box sx={{ marginBottom: 4 }}></Box> */}
                            {/* <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['PHS'])} category={'PHS'} /> */}
                            <RecentStatsTable stats={recentDefensiveStats} label="Defense" rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['DEF'])} />
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>
        )

        recentGoalieStatsHUH = (
            <TabPanel value={tabIndex} index={1}>
                <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography sx={{ color: 'white', marginBottom: 3 }}>Games played: {playerStats.playerRecentData.averages['Games Played']}</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Overall Rating'])} category={'OVR'} />
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 5 }}>Recent Consistency Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['goalieRatings']['Category Ratings']['CON'])} category={'CON'} />
                            <RecentStatsTable stats={recentGoalieStats3} />
                        </Card>
                    </Grid>
                    <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 5 }}>Recent Reflexes Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['goalieRatings']['Category Ratings']['REF'])} category={'REF'} />
                            <RecentStatsTable stats={recentGoalieStats} />
                        </Card>
                    </Grid>
                    <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 5 }}>Recent Clutchness Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['goalieRatings']['Category Ratings']['CLU'])} category={'CLU'} />
                            <RecentStatsTable stats={recentGoalieStats2} />
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>
        )
    } else {
        recentSkaterStats = (
            <TabPanel value={tabIndex} index={1}>
                <Typography sx={{ color: 'white', fontSize: '18px', marginTop: 5 }}>No recent game data found</Typography>
            </TabPanel>
        )

        recentGoalieStatsHUH = (
            <TabPanel value={tabIndex} index={1}>
                <Typography sx={{ color: 'white', fontSize: '18px', marginTop: 5 }}>No recent game data found</Typography>
            </TabPanel>
        )
    }

    if (positionState === 'skater') {
        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="Hockey stats tabs" variant="scrollable">
                        <Tab label="Basic Stats" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Recent Performance" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Shooting" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Passing" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Handeye" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Physicality" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Defense" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Zone ability" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Superstar ability" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6} >
                            <Typography sx={{ marginTop: 3, color: 'white' }}>Games played: {playerStats['Games Played']}</Typography>

                            <PositionalProgressBar totalGames={parseInt(playerStats['Games Played'])} gamesByPosition={gamesPlayedObj} />

                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>

                                <Box sx={{ marginTop: 3 }}></Box>
                                {/* <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Overall Rating'])} category={'OVR'} /> */}
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', }}>
                                    <Typography variant="h6" sx={{ marginTop: 1, color: 'white', marginBottom: 1 }}>Offense</Typography>

                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Career</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {OffensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>

                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Typography variant="h6" sx={{ marginTop: 1, color: 'white', marginBottom: 1 }}>Defense</Typography>

                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Career</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DefensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                {recentSkaterStats}
                <TabPanel value={tabIndex} index={2}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>
                                {/* <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Shooting</Typography> */}
                                {/* <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} /> */}

                                {/* <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['SHT'])} category={'SHT'} /> */}
                                <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                                    <CategoryContributionChart ratings={playerStats.overall_results.overall_results['Stat Contributions']['SHT']} label={"SHT"} />
                                </Box>
                                <TableContainer component={Paper} sx={{ backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Shooting.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>
                                {/* <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['PSS'])} category={'PSS'} /> */}
                                <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                                    <CategoryContributionChart ratings={playerStats.overall_results.overall_results['Stat Contributions']['PSS']} label={"PSS"} />
                                </Box>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Passing.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>
                                {/* <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['HND'])} category={'HND'} /> */}
                                <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                                    <CategoryContributionChart ratings={playerStats.overall_results.overall_results['Stat Contributions']['HND']} label={"HND"} />
                                </Box>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Handeye.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>

                                {/* <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['PHS'])} category={'PHS'} /> */}
                                <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                                    <CategoryContributionChart ratings={playerStats.overall_results.overall_results['Stat Contributions']['PHS']} label={"PHS"} />
                                </Box>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Physicality.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={6}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>

                                {/* <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['DEF'])} category={'DEF'} /> */}
                                <Box sx={{ marginTop: 2, marginBottom: 6 }}>
                                    <CategoryContributionChart ratings={playerStats.overall_results.overall_results['Stat Contributions']['DEF']} label={"DEF"} />
                                </Box>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DefensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={7}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>

                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ZoneAbility.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={8}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {SuperstarAbility.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        );
    } else if (positionState === 'goalie') {
        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="Hockey stats tabs" variant="scrollable">
                        <Tab label="Career Stats" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Recent Games" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                        <Tab label="Xfactor stats" sx={{ fontWeight: 450, color: 'white', border: 0 }} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>

                                <Typography sx={{ color: 'white', marginBottom: 3 }}>Games played: {playerStats['Goalie games played']}</Typography>
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Overall Rating'])} category={'OVR'} />
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Category Ratings']['CON'])} category={'CON'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats3.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 3 }} />
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Category Ratings']['CLU'])} category={'CLU'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats2.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 3 }} />
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Category Ratings']['REF'])} category={'REF'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                {recentGoalieStatsHUH}
                <TabPanel value={tabIndex} index={2}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent', paddingY: 1 }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Xfactor stats</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }}>Stat</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: '#B0B0B0', border: 0 }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieXfactorAbility.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 450, color: 'white', border: 0 }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        );
    }
};

export default HockeyDataComponent;
