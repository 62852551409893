import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useStats } from '../../Contexts/StatsContext';

// Styled component for each segment in the progress bar
const RatingSegment = styled('div')(({ width, bgcolor, isFirst, isLast }) => ({
    height: '20px',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: `${width}%`,
    backgroundColor: bgcolor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    fontSize: '14px',
    position: 'relative',
    borderTopLeftRadius: isFirst ? '20px' : '0px',   // Rounded corners on the first bar
    borderBottomLeftRadius: isFirst ? '20px' : '0px', // Rounded corners on the first bar
    borderTopRightRadius: isLast ? '20px' : '0px',    // Rounded corners on the last bar
    borderBottomRightRadius: isLast ? '20px' : '0px', // Rounded corners on the last bar
}));

const CategoryLabel = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '-25px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'white',
    fontSize: '12px',
    whiteSpace: 'nowrap',
}));

const VerticalLine = styled('div')(({ position }) => ({
    position: 'absolute',
    left: `${Math.trunc(position)}%`,
    top: '-10px', // Let the line extend a bit outside the bar
    bottom: '-10px', // Let the line extend a bit outside the bar
    width: '2px',
    backgroundColor: '#2196f3',
    zIndex: 1,
}));

const AverageLabel = styled('div')(({ position }) => ({
    position: 'absolute',
    top: '-35px',
    left: `${Math.trunc(position)}%`,
    transform: 'translateX(-50%)',
    color: '#2196f3',
    fontSize: '13px',
    fontWeight: 'bold',
    zIndex: 2, // Ensure the label is above the bar and other elements
}));

const StackedBarChart = ({ totalMax = 99, playerData }) => {
    const { state } = useStats();
    const { positionState } = state;

    // Variables declared here so that they can be accessed within the conditions
    let totalSum = 0;
    let averageRating = 0;
    let overall = 0
    let remainder = 0;
    let SHT, PSS, HND, PHS, DEF, REF, CLU, CON;

    if (positionState === 'skater') {
        // Assign values for skater positions
        SHT = Math.round(playerData.overall_results.overall_results['Category Ratings'].SHT);
        PSS = Math.round(playerData.overall_results.overall_results['Category Ratings'].PSS);
        HND = Math.round(playerData.overall_results.overall_results['Category Ratings'].HND);
        PHS = Math.round(playerData.overall_results.overall_results['Category Ratings'].PHS);
        DEF = Math.round(playerData.overall_results.overall_results['Category Ratings'].DEF);

        totalSum = SHT + PSS + HND + PHS + DEF;
        averageRating = totalSum / 5;
        overall = averageRating
        averageRating = Math.ceil(averageRating)
        remainder = totalMax - averageRating;
    } else if (positionState === 'goalie') {
        // Assign values for goalie positions
        REF = playerData.overall_results.goalie_overall_results['Category Ratings'].REF;
        CLU = playerData.overall_results.goalie_overall_results['Category Ratings'].CLU;
        CON = playerData.overall_results.goalie_overall_results['Category Ratings'].CON;

        totalSum = REF + CLU + CON;
        averageRating = totalSum / 3;
        overall = averageRating
        averageRating = Math.ceil(averageRating)
        remainder = totalMax - averageRating;
    }

    return (
        <Box sx={{ marginTop: 5, borderRadius: '20px', backgroundColor: '#e0e0e0', height: '20px', position: 'relative' }}>
            {/* Vertical line and label for the average */}
            <VerticalLine position={(averageRating / totalMax) * 100} />
            <AverageLabel position={(averageRating / totalMax) * 100}>
                {Math.round(overall)}
            </AverageLabel>

            <Box display="flex" width="100%" height="100%" position="relative">
                {positionState === 'skater' ? (
                    <>
                        <RatingSegment
                            width={(SHT / totalSum) * averageRating}
                            bgcolor="#ffc107"
                            isFirst={true}
                        >
                            {SHT}
                            <CategoryLabel>SHT</CategoryLabel>
                        </RatingSegment>
                        <RatingSegment
                            width={(PSS / totalSum) * averageRating}
                            bgcolor="#4caf50"
                        >
                            {PSS}
                            <CategoryLabel>PSS</CategoryLabel>
                        </RatingSegment>
                        <RatingSegment
                            width={(HND / totalSum) * averageRating}
                            bgcolor="#2196f3"
                        >
                            {HND}
                            <CategoryLabel>HND</CategoryLabel>
                        </RatingSegment>
                        <RatingSegment
                            width={(PHS / totalSum) * averageRating}
                            bgcolor="#d72121"
                        >
                            {PHS}
                            <CategoryLabel>PHS</CategoryLabel>
                        </RatingSegment>
                        <RatingSegment
                            width={(DEF / totalSum) * averageRating}
                            bgcolor="#9321d7"
                            
                        >
                            {DEF}
                            <CategoryLabel>DEF</CategoryLabel>
                        </RatingSegment>
                    </>
                ) : (
                    <>
                        <RatingSegment
                            width={(CON / totalSum) * averageRating}
                            bgcolor="#ffc107"
                            isFirst={true}
                        >
                            {CON}
                            <CategoryLabel>CON</CategoryLabel>
                        </RatingSegment>
                        <RatingSegment
                            width={(REF / totalSum) * averageRating}
                            bgcolor="#4caf50"
                        >
                            {REF}
                            <CategoryLabel>REF</CategoryLabel>
                        </RatingSegment>
                        <RatingSegment
                            width={(CLU / totalSum) * averageRating}
                            bgcolor="#2196f3"
                        >
                            {CLU}
                            <CategoryLabel>CLU</CategoryLabel>
                        </RatingSegment>
                    </>
                )}
                {/* Display the remainder as negative space in grey */}
                {remainder > 0 && (
                    <RatingSegment
                        width={Math.ceil((remainder / totalMax) * 100)}
                        bgcolor="#bdbdbd"
                        isLast={true}
                    />
                )}
            </Box>
        </Box>
    );
};

export default StackedBarChart;
